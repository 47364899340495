import { connect } from 'react-redux'
import { GoalTask } from './'

import { getGoalOrganizationUsersById } from '../../ducks/goalDetails'

const mapStateToProps = (state, { id }) => ({
  organizationUsersById: getGoalOrganizationUsersById(state),
})

const GoalTaskContainer = connect(
  mapStateToProps,
)(
  class GoalTaskContainer extends GoalTask {},
)

export { GoalTaskContainer }
