import firstBy from 'thenby'

const arraySortByArray = ({
  prop = 'id',
  sortOrder = [],
  items = [],
  sortFn = firstBy(({ sortIndex }) => sortIndex).thenBy(({ item }) => item.createdAt),
}) => items
  .map(item => {
    let sortIndex = sortOrder.indexOf(item[prop])

    if (sortIndex === -1)
      sortIndex = undefined

    return {
      item,
      sortIndex,
    }
  })
  .sort(sortFn)
  .map(({ item }) => item)

export { arraySortByArray }
