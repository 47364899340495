import React from 'react'
import { render } from 'react-dom'

import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { MuiThemeProvider } from 'material-ui/styles'

import { AppContainer } from './AppContainer'
import { FirebaseApi } from './api'
import defaultTheme, { muiTheme } from './themes/remente.js'
import store from './store.js'
import * as serviceWorker from './serviceWorker.js'

if (!window.initialized) {
  FirebaseApi.initialize()
  window.initialized = true
}

// eslint-disable-next-line no-use-before-define
const module = module || {}

const init = () => render((
  <Provider store={ store }>
    <ThemeProvider theme={ defaultTheme }>
      <MuiThemeProvider muiTheme={ muiTheme }>
        <AppContainer />
      </MuiThemeProvider>
    </ThemeProvider>
  </Provider>
), document.getElementById('root'))

if (module.hot)
  module.hot.accept('./App', init)

init()

serviceWorker.unregister()
