import styled from 'styled-components'

export const Track = styled.div`
  position: relative;
  background: #f4f4f6;
  border-radius: 40px;
  margin: 0 18%;
  transition: all 150ms ease-out;
  padding: 2px;
  overflow: hidden;
  ${ ({ value }) => value > 0 ? `
    background: #E0E0E4;
  ` : '' }
`

export const ProgressWrap = styled.div`
  border-radius: 40px;
  overflow: hidden;
`

export const Progress = styled.div`
  ${ ({ value, theme: { colorPrimary1 } }) => `
    background: ${ colorPrimary1 };
    background: #fff;
    height: 10px;
    transition: all 150ms ease-out;
    transform: scaleX(${ value });
    transform-origin: left;
    position: relative;
  ` }
`

export const LabelWrap = styled.div`
  position: relative;
  transition: all 150ms ease-out;
  ${ ({ value }) => `
    transform: translateX(${ value * 100 }%);
    ${ value <= 0 ? `
      opacity: 0;
    ` : '' }
  ` }
`

export const Label = styled.div`
  position: absolute;
  top: 0px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  left: 5px;
  color: #75757A;
`
