import React, { Component } from 'react'

import {
  ScrollViewMain,
  MainContainer,
  CoverPhotoHeader,
} from './'

import CourseOutline from './CourseOutline.js'
import RestrictedContentInfo from './RestrictedContentInfoContainer.js'

class CourseOutlineScene extends Component {

  render() {
    const { loading, language, imageUrl, canViewPremiumContent, userReadLessons } = this.props

    const { title, subTitle, isPublic } = this.props
    const showPaywall = !loading && !isPublic && !canViewPremiumContent

    const outline = {
      language,
      userReadLessons,
      ...this.props,
    }

    return (
      <ScrollViewMain>

        <CoverPhotoHeader image={ imageUrl } loading={ loading }>
          <h2>
            { title }
            <small>{ subTitle }</small>
          </h2>
        </CoverPhotoHeader>

        { showPaywall ? <RestrictedContentInfo contentName={ title } /> : undefined }

        <MainContainer horizontalPadding verticalPadding>
          <CourseOutline
            { ...outline }
            isBehindPaywall={ showPaywall }
          />
        </MainContainer>

      </ScrollViewMain>
    )
  }

}

export default CourseOutlineScene
