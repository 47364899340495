import React, { Component } from 'react'
import { SortableContainer } from 'react-sortable-hoc'
import { Link } from 'react-router-dom'
import IconCollapsed from 'material-ui/svg-icons/navigation/chevron-right'
import IconExpanded from 'material-ui/svg-icons/navigation/expand-more'
import { GoalListItemContainer as GoalListItem, ListItemsAnimator } from '../'
import { dndShouldCancelStart } from '../../utils/dnd'
import {
  GoalListWrap,
  ListHeader,
  ListTitle,
} from './styles.js'

const SortableList = SortableContainer(({ children, isDragging }) => (
  <ListItemsAnimator disableAllAnimations={ isDragging }>
    { children }
  </ListItemsAnimator>
))

const createGoalRootUrl = '/achieve/set-a-goal'

class GoalList extends Component {

  state = {
    isDragging: false,
  }

  render() {
    const { organizationId, organization, goals, isElectron, isCollapsed, onListTitleClick } = this.props
    const { isDragging } = this.state
    const listTitle = organizationId ? (organization && organization.name) : 'My Goals'
    const link = organizationId ? (createGoalRootUrl + `/${ organizationId }`) : createGoalRootUrl
    const listTitleCount = isCollapsed ? `${ listTitle } (${ goals.length })` : listTitle

    const Goals = !isCollapsed && (
      <SortableList
        shouldCancelStart={ dndShouldCancelStart }
        helperClass="dnd-dragging"
        lockAxis="y"
        lockToContainerEdges={ true }
        useDragHandle={ false }
        pressDelay={ 200 }
        isDragging={ isDragging }
        onSortEnd={ this.handleSortEnd }
        onSortStart={ this.handleSortStart }
      >
        { goals.map(this.renderGoal) }
      </SortableList>
    )

    const ToggleIcon = isCollapsed ? <IconCollapsed /> : <IconExpanded />

    return (
      <GoalListWrap>
        <ListHeader isElectron={ isElectron }>
          <ListTitle onClick={ onListTitleClick }>
            { ToggleIcon }
            { listTitleCount }
          </ListTitle>
          <Link to={ link }>
            +
          </Link>
        </ListHeader>
        { Goals }
      </GoalListWrap>
    )
  }

  renderGoal = (goalId, index) => (
    <GoalListItem
      index={ index }
      key={ goalId }
      goalId={ goalId }
    />
  )

  handleSortStart = () => {
    this.setState({ isDragging: true })
  }

  handleSortEnd = sortEvent => {
    setTimeout(() => this.setState({ isDragging: false }), 200)
    const { organizationId, onOrderChange } = this.props
    const { oldIndex, newIndex } = sortEvent
    if (newIndex !== oldIndex && onOrderChange)
      onOrderChange({
        organizationId,
        oldIndex,
        newIndex,
      })
  }

}

export { GoalList }
