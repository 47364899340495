import styled from 'styled-components'
import { truncate } from '../../utils/css'

export const TimelineCircle = styled.button`
  ${ ({ size = 7, isFilled, color = '#747478' }) => `
    width: ${size}px;
    height: ${size}px;
    padding: 0;
    border-radius: ${size}px;
    border: 1px solid ${color};
    background: none;
    ${ isFilled &&
      `background: ${color};`
}
  ` }
  z-index: 2;
`

export const TimelineVerticalLine = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 195px;
  width: 1px;
  z-index: 1;
  ${ ({ theme }) => `
    border-left: 1px dashed ${ theme.Timeline.c.verticalLine };
  ` }
`

export const DayCol = styled.div`
  padding-top: 1em;
`

export const MainCol = styled.div`
  flex: 1;
`

export const Section = styled.div`
  display: flex;
  ${ ({ isStatic, hasNotes, theme }) => isStatic && !hasNotes &&
    `color: ${ theme.Timeline.c.staticFg };`
}
`

export const EntryWrap = styled.div`
  display: flex;
  position: relative;
  align-items: flex-start;
  margin-bottom: 1em;
`

export const DayLabelWrap = styled.div`
  font-size: .8em;
  font-weight: 600;
  text-align: center;
  width: 50px;
  margin-left: 40px;
  position: sticky;
  top: 40px;
  transform: translate3d(0, 0, 0);
  ${ ({ theme }) => `
    color: ${ theme.Timeline.c.dayLabelFg }
  ` }
`

export const Month = styled.div`
  text-transform: uppercase;
`

export const Day = styled.div`
  font-size: 1.7em;
  margin-top: .2em;
`

export const TimeWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1.4em;
  width: 110px;
  color: #737379;
  font-size: .8em;
  span {
    margin-right: .5em;
  }
  ${ ({ isStatic, theme }) => isStatic && `
    color: ${ theme.Timeline.c.staticFg };
    div {
      border-color: ${ theme.Timeline.c.staticFg };
      background: ${ theme.Timeline.c.staticFg };
    }
  ` }
`

export const Content = styled.div`
  flex: 1;
  width: 0;
  position: relative;
  margin-left: 1em;
  margin-right: 40px;
  border-radius: 4px;
  ${ ({ imageUrl, notes }) =>
    (imageUrl || notes) && 'background: white;'
}
  &:after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: -18px;
    right: auto;
    top: 18px;
    bottom: auto;
    border: 10px solid;
    border-color: transparent white transparent transparent;
    ${ ({ imageUrl, notes }) =>
    (!(imageUrl || notes)) && 'display: none;'
}
  }
`

export const FeaturedImage = styled.img`
  width: 100%;
`

export const Title = styled.h4`
  margin: 0;
  font-size: 1em;
  font-weight: 600;
  padding: 1em;
  ${ truncate() };
`

export const EntryNotes = styled.div`
  display: flex;
  align-items: start;
  padding: .8em 1em;
  font-size: .8em;
`

export const AtTimeline = styled.div`
  display: flex;
  align-items: center;
  margin-left: 195px;
  position: relative;
  z-index: 2;
`

export const AtTimelineIcon = styled(AtTimeline)`
  margin-left: 184px;
  margin-top: 1em;
  margin-bottom: 2em;
  svg {
    margin-right: 1.5em;
  }
`

export const Tags = styled.div`
  padding: 0 1em .5em 1em;
  border: 1px solid #f4f4f6;
  border-style: solid none;
`

export const SmileyWrap = styled.div`
  width: 18px;
  height: 0;
  position: relative;
  z-index: 2;
  >svg {
    position: absolute;
    top: 0;
    left: 0px;
    transform: translateY(-50%);
    background: #f4f4f6;
  }
`
