import { combineReducers } from 'redux'
import { combineEpics, ofType } from 'redux-observable'
import { EMPTY } from 'rxjs'
import { map, mergeMap, startWith } from 'rxjs/operators'
import { has, get, values } from 'lodash'
import { createAction, handleActions } from 'redux-actions'
import { createSelector } from 'reselect'
import { FirebaseApi } from '../../api'
import { createEnsureAuthenticatedEpic } from '../../utils/epic'
import { getLanguage } from '../app'

export const contentFavIdProps = {
  boost: 'boostId',
  course: 'courseId',
  'goal-template': 'templateId',
}

const createSinglePropReducer = (actionName, prop, initialState = {}) => handleActions({
  [actionName]: (state, action) => action.payload[prop],
}, initialState)

const USER_CONTENT_CHANGED          = 'remente/userContent/USER_CONTENT_CHANGED'
const LESSON_MARK_READ              = 'remente/userContent/LESSON_MARK_READ'
const LESSON_MARK_READ_FULFILLED    = 'remente/userContent/LESSON_MARK_READ_FULFILLED'
const USER_HISTORY_UPDATE           = 'remente/userContent/USER_HISTORY_UPDATE'
const USER_HISTORY_UPDATE_FULFILLED = 'remente/userContent/USER_HISTORY_UPDATE_FULFILLED'

export const markLessonRead                     = createAction(LESSON_MARK_READ)
export const updateUserHistory                  = createAction(USER_HISTORY_UPDATE)
export const userContentChanged                 = createAction(USER_CONTENT_CHANGED)
export const markLessonReadFulfilled            = createAction(LESSON_MARK_READ_FULFILLED)
export const updateUserHistoryFulfilled         = createAction(USER_HISTORY_UPDATE_FULFILLED)
export const toggleUserContentFavorite          = createAction('remente/userContent/toggleUserContentFavorite')
export const toggleUserContentFavoriteFulfilled = createAction('remente/userContent/toggleUserContentFavoriteFulfilled')

const userProgressReducer               = createSinglePropReducer(USER_CONTENT_CHANGED, 'userProgress')
const userHistoryReducer                = createSinglePropReducer(USER_CONTENT_CHANGED, 'userHistory')
const userGoalsByIdReducer              = createSinglePropReducer(USER_CONTENT_CHANGED, 'userGoalsById')
const userJourneyEntriesByGoalIdReducer = createSinglePropReducer(USER_CONTENT_CHANGED, 'userJourneyEntriesByGoalId')
const userDailyTaskStateReducer         = createSinglePropReducer(USER_CONTENT_CHANGED, 'userDailyTaskState')
const userTodoTasksByIdReducer          = createSinglePropReducer(USER_CONTENT_CHANGED, 'userTodoTasksById')
const userGoalOrderReducer              = createSinglePropReducer(USER_CONTENT_CHANGED, 'userGoalOrder')
const userContentFavoritesByIdReducer   = createSinglePropReducer(USER_CONTENT_CHANGED, 'userContentFavorites')

export default combineReducers({
  userProgress:               userProgressReducer,
  userHistory:                userHistoryReducer,
  userGoalsById:              userGoalsByIdReducer,
  userJourneyEntriesByGoalId: userJourneyEntriesByGoalIdReducer,
  userDailyTaskState:         userDailyTaskStateReducer,
  userTodoTasksById:          userTodoTasksByIdReducer,
  userGoalOrder:              userGoalOrderReducer,
  userContentFavoritesById:   userContentFavoritesByIdReducer,
})

/**
 * Selectors
 */

const userProgressSelector               = ({ userContent }) => userContent.userProgress
const userHistorySelector                = ({ userContent }) => userContent.userHistory
const userGoalsByIdSelector              = ({ userContent }) => userContent.userGoalsById
const userJourneyEntriesByGoalIdSelector = ({ userContent }) => userContent.userJourneyEntriesByGoalId
const userDailyTaskStateSelector         = ({ userContent }) => userContent.userDailyTaskState
const userTodoTasksByIdSelector          = ({ userContent }) => userContent.userTodoTasksById
const userGoalOrderSelector              = ({ userContent }) => userContent.userGoalOrder
const getUserContentFavoritesById        = ({ userContent }) => userContent.userContentFavoritesById

export const getMyUserProgressByLanguage = createSelector(
  userProgressSelector,
  userProgress => userProgress,
)

export const getCourseUserProgress = (state, { language, courseId }) => {
  const userProgress = getMyUserProgressByLanguage(state)
  return get(userProgress, [language, 'lessons', courseId], {})
}

export const getMyUserHistoryByLanguage = createSelector(
  userHistorySelector,
  userHistory => userHistory,
)

export const getMyGoalsById = createSelector(
  userGoalsByIdSelector,
  goalsById => goalsById,
)

export const getMyGoalOrder = createSelector(
  userGoalOrderSelector,
  goalOrder => goalOrder || {},
)

export const getMyGoalOrderActive = createSelector(
  getMyGoalOrder,
  goalOrder => get(goalOrder, 'active', []),
)

export const getMyGoalOrderCompleted = createSelector(
  getMyGoalOrder,
  goalOrder => get(goalOrder, 'completed', []),
)

// TODO replace with getMyJourneyEntriesByGoalId
export const getUserJourneyEntriesByGoalId = createSelector(
  userJourneyEntriesByGoalIdSelector,
  userJourneyEntriesByGoalId => userJourneyEntriesByGoalId,
)

export const getMyJourneyEntriesByGoalId = createSelector(
  getUserJourneyEntriesByGoalId,
  userJourneyEntriesByGoalId => userJourneyEntriesByGoalId || {},
)

export const getMyDailyTaskState = createSelector(
  userDailyTaskStateSelector,
  userDailyTaskState => userDailyTaskState || {
    lastUpdatedAt: 1403479285216,
    orderUpcoming: [],
    orderToday: [],
  },
)

export const getMyTodoTasksById = createSelector(
  userTodoTasksByIdSelector,
  userTodoTasksById => userTodoTasksById || {},
)

export const getUserContentFavorites = createSelector(
  getUserContentFavoritesById,
  favs => values(favs),
)

export const getUserContentFavoritesByContentId = createSelector(
  getUserContentFavorites,
  favs => favs.reduce((acc, fav) => {
    const idProp = contentFavIdProps[fav.type]
    if (!idProp) return acc
    acc[fav[idProp]] = fav
    return acc
  }, {}),
)

/**
 * Epics
 */

const userContentChangedEpic = createEnsureAuthenticatedEpic({
  createObservables: ({ uid }) => ({
    userProgress: FirebaseApi.observableRef(`user-progress/${ uid }`).pipe(startWith({})),
    userHistory: FirebaseApi.observableRef(`user-history/${ uid }`).pipe(startWith({})),
    userGoalsById: FirebaseApi.observableRef(`goals/${ uid }`).pipe(startWith({})),
    userJourneyEntriesByGoalId: FirebaseApi.observableRef(`goal-journey-entries/${ uid }`).pipe(startWith({})),
    userDailyTaskState: FirebaseApi.observableRef(`daily-task-state/${ uid }`).pipe(startWith({})),
    userTodoTasksById: FirebaseApi.observableRef(`todo-tasks/${ uid }`).pipe(startWith({})),
    userGoalOrder: FirebaseApi.observableRef(`goal-order/${ uid }`).pipe(startWith({})),
    userContentFavorites: FirebaseApi.observableRef(`user-content-favorites/${ uid }`).pipe(startWith({})),
  }),
  actionFulfilled: userContentChanged,
})

const markLessonReadEpic = (action$, state$) => action$.pipe(
  ofType(LESSON_MARK_READ),
  mergeMap(action => {
    const { userContent } = state$.value
    const { userProgress } = userContent

    const { language, courseId, lessonId } = action.payload
    const markedAsRead = has(userProgress, [
      language, 'lessons', courseId, lessonId,
    ])

    if (markedAsRead) return EMPTY

    return FirebaseApi
      .markLessonRead({ language, courseId, lessonId })
      .pipe(map(markLessonReadFulfilled))
  }),
)

const updateUserHistoryEpic = action$ => action$.pipe(
  ofType(USER_HISTORY_UPDATE),
  mergeMap(({ payload }) => {
    const { language, type, id, title, imageUrl } = payload
    return FirebaseApi
      .updateUserHistory({ language, type, id, title, imageUrl }).pipe(
        map(updateUserHistoryFulfilled),
      )
  }),
)

const toggleUserContentFavoriteEpic = (action$, state$) => action$.pipe(
  ofType(toggleUserContentFavorite().type),
  map(({ payload }) => payload),
  mergeMap(({ id, type, bookmark }) => {
    const language = getLanguage(state$.value)
    const idProp = contentFavIdProps[type]
    const o = bookmark ?
      FirebaseApi.removeUserContentFavorite(bookmark.id) :
      FirebaseApi.createUserContentFavorite({
        type,
        languageId: language,
        [idProp]: id,
      })
    return o.pipe(map(toggleUserContentFavoriteFulfilled))
  }),
)

export const userContentEpics = combineEpics(
  userContentChangedEpic,
  markLessonReadEpic,
  updateUserHistoryEpic,
  toggleUserContentFavoriteEpic,
)
