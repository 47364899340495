import styled from 'styled-components'

export const Container = styled.div`
  position: fixed;
  width: 80%;
  max-width: 420px;
  right: 30px;
  top: 30px;
  z-index: 2;
`

export const CourseOutlineWrap = styled.div`
  max-height: 425px;
  padding: 15px;
  >div {
    margin-bottom: 0;
  }
`

