import { connect } from 'react-redux'
import { GoalPlan } from './'

import { editGoalTask } from '../../ducks/editGoalTask'

import {
  getDetailsGoalId,
  getDetailsGoalTasks,
  getDetailsGoalIsCompleted,
  changeTaskOrder,
  toggleTaskCompleted,
} from '../../ducks/goalDetails'

const mapStateToProps = state => ({
  goalId: getDetailsGoalId(state),
  tasks: getDetailsGoalTasks(state),
  isGoalCompleted: getDetailsGoalIsCompleted(state),
})

const mapDispatchToProps = dispatch => ({
  onTaskClick: id => dispatch(editGoalTask(id)),
  onTaskOrderChange: payload => dispatch(changeTaskOrder(payload)),
  onTaskCheckboxClick: id => dispatch(toggleTaskCompleted(id)),
})

class GoalPlanContainerClass extends GoalPlan {}

const GoalPlanContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(GoalPlanContainerClass)

export { GoalPlanContainer }
