import { combineReducers } from 'redux'
import { combineEpics, ofType } from 'redux-observable'
import { createAction, handleActions, combineActions } from 'redux-actions'
import { createSelector } from 'reselect'
import fuzzysearch from 'fuzzysearch'
import firstBy from 'thenby'
import { get, groupBy, keyBy } from 'lodash'
import { of } from 'rxjs'
import { map, mergeMap, catchError } from 'rxjs/operators'
import { FirebaseApi } from '../../api'
import { arrayMove } from '../../utils/array'

import {
  autoplanFilterFactory,
  taskDateFilterFactory,
} from '../../utils/dayplanner'

import {
  getIsPlanMode,
  getTaskInputValue,
  getSelectedDate,
  getTodoListItemIds,
  setTaskInputValue,
  setIsPlanMode,
  addGoalStepToPlan,
  createAndPlanTodoTask,
} from '../dayplanner'

import {
  getMyGoalsEnrichedActive,
  getMyGoalsEnrichedActiveTasks,
  getMyGoalsEnrichedCompleted,
  getActiveGoalsWithProgress,
  getCompletedGoalsWithProgress,
} from '../goalProgress'

import {
  getMyOrganizationIds,
} from '../organization'

const INITAL_STATE = {
  isShowCompleted: false,
  selectionIndex: -1,
}

const SHOW_COMPLETED_TOGGLE          = 'remente/goalNav/SHOW_COMPLETED_TOGGLE'
const SELECTION_INDEX_NEXT           = 'remente/goalNav/SELECTION_INDEX_NEXT'
const SELECTION_INDEX_PREVIOUS       = 'remente/goalNav/SELECTION_INDEX_PREVIOUS'
const SELECTION_INDEX_SET            = 'remente/goalNav/SELECTION_INDEX_SET'
const SELECTION_ADD_TO_PLAN          = 'remente/goalNav/SELECTION_ADD_TO_PLAN'
const SELECTION_ADD_TO_PLAN_REJECTED = 'remente/goalNav/SELECTION_ADD_TO_PLAN_REJECTED'
const GOAL_ORDER_CHANGE              = 'remente/goalNav/GOAL_ORDER_CHANGE'
const GOAL_ORDER_CHANGE_FULFILLED    = 'remente/goalNav/GOAL_ORDER_CHANGE_FULFILLED'
const GOAL_ORDER_CHANGE_REJECTED     = 'remente/goalNav/GOAL_ORDER_CHANGE_REJECTED'
const GOAL_LIST_TOGGLE_COLLAPSED     = 'remente/goalNav/GOAL_LIST_TOGGLE_COLLAPSED'

export const toggleShowCompleted     = createAction(SHOW_COMPLETED_TOGGLE)
export const selectNext              = createAction(SELECTION_INDEX_NEXT)
export const selectPrevious          = createAction(SELECTION_INDEX_PREVIOUS)
const setSelectionIndex              = createAction(SELECTION_INDEX_SET)
export const addSelectionToPlan      = createAction(SELECTION_ADD_TO_PLAN)
const addSelectionToPlanRejected     = createAction(SELECTION_ADD_TO_PLAN_REJECTED)
export const changeGoalOrder         = createAction(GOAL_ORDER_CHANGE)
const changeGoalOrderFulfilled       = createAction(GOAL_ORDER_CHANGE_FULFILLED)
const changeGoalOrderRejected        = createAction(GOAL_ORDER_CHANGE_REJECTED)
export const toggleGoalListCollapsed = createAction(GOAL_LIST_TOGGLE_COLLAPSED)

/**
 * Reducers
 */

const isShowCompletedReducer = handleActions({
  [SHOW_COMPLETED_TOGGLE]: (state, { payload }) => payload !== undefined ? payload : !state,
}, INITAL_STATE.isShowCompleted)

const selectionIndexReducer = handleActions({
  [setSelectionIndex]: (state, { payload }) => payload,
  [setTaskInputValue]: (state, { payload }) => payload.length ? 0 : INITAL_STATE.selectionIndex,
  [setIsPlanMode]: (state, { payload }) => payload ? state : INITAL_STATE.selectionIndex,
  [combineActions(
    addGoalStepToPlan,
    createAndPlanTodoTask,
  )]: () => INITAL_STATE.selectionIndex,
}, INITAL_STATE.selectionIndex)

const collapsedGoalListsReducer = handleActions({
  [toggleGoalListCollapsed]: (state, { payload }) => ({
    ...state,
    [payload.organizationId]: !state[payload.organizationId],
  }),
}, {})

export default combineReducers({
  isShowCompleted: isShowCompletedReducer,
  selectionIndex: selectionIndexReducer,
  collapsedGoalLists: collapsedGoalListsReducer,
})

/**
 * Selectors
 */

const isShowCompletedSelector = ({ goalNav }) => goalNav.isShowCompleted
const selectionIndexSelector = ({ goalNav }) => goalNav.selectionIndex
export const getCollapsedGoalLists = ({ goalNav }) => goalNav.collapsedGoalLists

export const getIsShowCompleted = createSelector(
  isShowCompletedSelector,
  value => value,
)

// Goal tasks

const goalTasksNotPlannedSelector = createSelector(
  getMyGoalsEnrichedActiveTasks,
  getTodoListItemIds,
  (tasks, plannedTaskIds) => tasks.filter(({ id }) => plannedTaskIds.indexOf(id) < 0),
)

const goalTasksMatchingDatesSelector = createSelector(
  goalTasksNotPlannedSelector,
  getSelectedDate,
  (tasks, date) => tasks.filter(task =>
    (get(task, 'autoplan.isDisabled', true) && taskDateFilterFactory({ date })(task)) ||
    autoplanFilterFactory({ date })(task),
  ),
)

const goalTasksNotCompletedSelector = createSelector(
  goalTasksMatchingDatesSelector,
  tasks => tasks.filter(({ isCompleted }) => !isCompleted),
)

const goalTasksFuzzySearchTitleSelector = createSelector(
  goalTasksNotCompletedSelector,
  getTaskInputValue,
  (tasks, taskInputValue) => tasks
    .filter(({ title }) => fuzzysearch(
      taskInputValue.toLowerCase(),
      title.toLowerCase(),
    )),
)

export const getMatchedTasksById = createSelector(
  goalTasksFuzzySearchTitleSelector,
  tasks => keyBy(tasks, 'id'),
)

export const getSelectionIndex = createSelector(
  selectionIndexSelector,
  goalTasksFuzzySearchTitleSelector,
  (index, tasks) => Math.min(index, tasks.length),
)

const numTasksMatchedSelector = createSelector(
  goalTasksFuzzySearchTitleSelector,
  tasks => tasks.length,
)

const goalTasksSelectionHighlightSelector = createSelector(
  goalTasksFuzzySearchTitleSelector,
  getSelectionIndex,
  (tasks, selectionIndex) => tasks.map((task, i) => ({
    ...task,
    isSelected: selectionIndex === i,
  })),
)

const goalTasksGroupedByIdSelector = createSelector(
  goalTasksSelectionHighlightSelector,
  tasks => groupBy(tasks, 'goalId'),
)

const goalsEnrichedIncludingIceboxGoalSelector = createSelector(
  getMyGoalsEnrichedActive,
  goals => ([...goals, {
    id: 'icebox',
    title: 'TODO-Tasks',
  }]),
)

const goalsMatchedTasksSelector = createSelector(
  goalsEnrichedIncludingIceboxGoalSelector,
  goalTasksGroupedByIdSelector,
  getTaskInputValue,
  (goals, matchedTasks, taskInputValue) => goals
    .map(goal => ({
      ...goal,
      matchedTasks: matchedTasks[goal.id],
    }))
    .filter(({ id, matchedTasks }) =>
      (id !== 'icebox' && (taskInputValue.length === 0 || matchedTasks)) || (matchedTasks),
    ),
)

export const getNavItems = createSelector(
  getIsPlanMode,
  getIsShowCompleted,
  goalsMatchedTasksSelector,
  getMyGoalsEnrichedActive,
  getMyGoalsEnrichedCompleted,
  (isPlanMode, isShowCompleted, goalsMatched, activeGoals, completedGoals) =>
    isPlanMode ? goalsMatched : isShowCompleted ? completedGoals : activeGoals,
)

export const getSelectedTask = createSelector(
  goalTasksFuzzySearchTitleSelector,
  getSelectionIndex,
  (tasks, index) => tasks[index],
)

export const getSelectedTaskId = createSelector(
  getSelectedTask,
  task => task ? task.id : undefined,
)

export const getSelectedTaskTitle = createSelector(
  getSelectedTask,
  task => task ? task.title : undefined,
)

export const getGoalLists = createSelector(
  getIsShowCompleted,
  getMyOrganizationIds,
  getActiveGoalsWithProgress,
  getCompletedGoalsWithProgress,
  (isShowCompleted, organizationIds, activeGoals, completedGoals) => {
    const goals = isShowCompleted ? completedGoals : activeGoals
    const goalsByOrganizationId = groupBy(goals, 'organizationId')
    return [undefined, ...organizationIds]
      .map(organizationId => ({
        organizationId,
        goals: (goalsByOrganizationId[organizationId] || []).map(({ id }) => id),
      }))
      .sort(firstBy('organizationId'))
  },
)

/**
 * Epics
 */

const selectNextEpic = (action$, state$) => action$.pipe(
  ofType(SELECTION_INDEX_NEXT),
  map(() => {
    const selectionIndex = getSelectionIndex(state$.value) + 1
    const numTasks = numTasksMatchedSelector(state$.value)
    const newIndex = Math.min(numTasks - 1, selectionIndex)
    return setSelectionIndex(newIndex)
  }),
)

const selectPreviousEpic = (action$, state$) => action$.pipe(
  ofType(SELECTION_INDEX_PREVIOUS),
  map(() => {
    const selectionIndex = getSelectionIndex(state$.value) - 1
    const newIndex = Math.max(0, selectionIndex)
    return setSelectionIndex(newIndex)
  }),
)

const addSelectionToPlanEpic = (action$, state$) => action$.pipe(
  ofType(SELECTION_ADD_TO_PLAN),
  map(() => {
    const task = getSelectedTask(state$.value)
    if (!task) return addSelectionToPlanRejected()
    return addGoalStepToPlan(task)
  }),
)

const changeGoalOrderEpic = (action$, state$) => action$.pipe(
  ofType(changeGoalOrder().type),
  map(({ payload }) => payload),
  mergeMap(({ organizationId, oldIndex, newIndex }) => {
    const goalLists = getGoalLists(state$.value)

    const newOrder = goalLists.reduce((acc, list) => {
      let goals = list.goals
      if (organizationId === list.organizationId)
        goals = arrayMove(goals, oldIndex, newIndex)
      return acc.concat(goals)
    }, [])

    const prop = isShowCompletedSelector(state$.value) ? 'completed' : 'active'
    return FirebaseApi
      .updateGoalOrder({ [prop]: newOrder })
      .pipe(
        map(changeGoalOrderFulfilled),
        catchError(err => of(changeGoalOrderRejected(err))),
      )
  }),
)

export const goalNavEpics = combineEpics(
  selectNextEpic,
  selectPreviousEpic,
  addSelectionToPlanEpic,
  changeGoalOrderEpic,
)
