import { connect } from 'react-redux'
import { GoalListItem } from './'
import { getPathname } from '../../ducks/route'
import { getGoalsWithProgressById } from '../../ducks/goalProgress'
import { addGoalStepToPlan, getIsPlanMode } from '../../ducks/dayplanner'

const mapStateToProps = (state, { goalId }) => ({
  _forceRefresh: getPathname(state),
  goal: getGoalsWithProgressById(state)[goalId],
  isPlanMode: getIsPlanMode(state),
})

const mapDispatchToProps = dispatch => ({
  onAddToPlanClick: payload => dispatch(addGoalStepToPlan(payload)),
})

const GoalListItemContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  class GoalListItemContainer extends GoalListItem {},
)

export { GoalListItemContainer }
