import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withTheme } from 'styled-components'
import { map, includes } from 'lodash'
import { FormattedMessage } from 'react-intl'

import IconExercise from 'material-ui/svg-icons/editor/mode-edit'
import IconVideo from 'material-ui/svg-icons/action/description'
import IconText from 'material-ui/svg-icons/action/description'

import { MainContainer } from './'
import {
  Next,
  IconWrap,
} from './NextLesson.styles.js'

class NextLessonClass extends Component {

  render() {
    const { language, courseId, lessonId, title, content, theme } = this.props
    const { iconFg } = theme.NextLesson.c
    if (!title) return null

    const lessonLink = `/resources/courses/${ language }/${ courseId }/${ lessonId }`
    const lessonIcon = this.getLessonIcon(content, iconFg)

    return (
      <Link to={ lessonLink } replace>
        <Next>
          <MainContainer horizontalPadding>
            <h3>
              <FormattedMessage
                id="next_lesson_title"
                defaultMessage="Up Next"
              />
            </h3>
            <h2>{ title }</h2>
          </MainContainer>
          <IconWrap>
            { lessonIcon }
          </IconWrap>
        </Next>
      </Link>
    )
  }

  getLessonIcon(contentBlocks, color) {
    var outline = map(contentBlocks, 'contentType')
    const iconProps = {
      color,
      style: {
        width: 40,
        height: 40,
      },
    }
    if (includes(outline, 'exercise')) return <IconExercise { ...iconProps } />
    if (
      includes(outline, 'vimeo-private') ||
      includes(outline, 'vimeo-public') ||
      includes(outline, 'youtube-public')
    ) return <IconVideo { ...iconProps } />
    return <IconText { ...iconProps } />
  }

}

const NextLesson = withTheme(NextLessonClass)

export default NextLesson
