import { combineReducers } from 'redux'
import { combineEpics, ofType } from 'redux-observable'
import { createAction, handleActions } from 'redux-actions'
import { get } from 'lodash'
import { of } from 'rxjs'
import { map, mergeMap, catchError } from 'rxjs/operators'
import { FirebaseApi } from '../../api'
import { getLanguage } from '../app'
import { createGoalFromExercise } from '../goal'

/**
 * Actions
 */

export const fetchExercise           = createAction('remente/exercise/EXERCISE_FETCH')
export const fetchExerciseRejected   = createAction('remente/exercise/EXERCISE_FETCH_REJECTED')
export const setExercise             = createAction('remente/exercise/EXERCISE_SET')
export const acceptExercise          = createAction('remente/exercise/EXERCISE_ACCEPT')
export const acceptExerciseFulfilled = createAction('remente/exercise/EXERCISE_ACCEPT_FULFILLED')

/**
 * Reducers
 */

const exercisesByIdReducer = handleActions({
  [setExercise]: (state, { payload }) => !payload ? state : ({
    ...state,
    [payload.id]: {
      ...payload,
    },
  }),
}, {})

const myAcceptedExercisesByIdReducer = handleActions({
  [acceptExercise]: (state, { payload: { exerciseId } }) => ({ ...state, [exerciseId]: true }),
}, {})

export default combineReducers({
  exercisesById: exercisesByIdReducer,
  myAcceptedExercisesById: myAcceptedExercisesByIdReducer,
})

/**
 * Selectors
 */

export const getExercisesById = ({ exercise }) => exercise.exercisesById
export const getExerciseById = (state, { id }) => get(getExercisesById(state), id)
export const getMyAcceptedExercisesById = ({ exercise }) => exercise.myAcceptedExercisesById
export const getIsExerciseAccepted = (state, { id }) => !!get(getMyAcceptedExercisesById(state), id)

/**
 * Epics
 */

const fetchExerciseEpic = (action$, state$) => action$.pipe(
  ofType(fetchExercise().type),
  map(({ payload }) => payload),
  mergeMap(id => {
    const language = getLanguage(state$.value)
    return FirebaseApi
      .fetchExercise({ id, language })
      .pipe(
        map(setExercise),
        catchError(err => of(fetchExerciseRejected(err))),
      )
  }),
)

const acceptExerciseEpic = action$ => action$.pipe(
  ofType(acceptExercise().type),
  map(({ payload }) => payload),
  mergeMap(({ goal, exerciseId }) => of(
    createGoalFromExercise(goal),
    acceptExerciseFulfilled(exerciseId),
  )),
)

export const exerciseEpics = combineEpics(
  fetchExerciseEpic,
  acceptExerciseEpic,
)
