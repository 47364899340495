import styled from 'styled-components'

const CardSection = styled.div`
  padding: 40px 50px;
  border-top: 2px solid #eee;
  &:first-child {
    border: none;
  }
`

export { CardSection }
