import React, { Component } from 'react'
import { get } from 'lodash'

import {
  UserGoalNavTaskItem,
  SidebarNavItemContainer as SidebarNavItem,
  PieChart,
} from '../'

import { TaskList } from './styles.js'

const colorProgressFilled = '#7ADAC3'
const colorProgressTrack = '#f4f4f6'

class UserGoalNavItem extends Component {

  renderGoalTask = task => {
    const { onAddToPlanClick } = this.props
    return (
      <UserGoalNavTaskItem
        key={ task.id }
        onAddToPlanClick={ onAddToPlanClick }
        task={ task }
      />
    )
  }

  render() {
    const { goal, isPlanMode } = this.props
    const { id, title, images, percentCompleted, origin } = goal
    const goalDetailsUrl = `/achieve/${ id }`
    const isIcebox = id === 'icebox'
    const isCreateNew = id === 'set-a-goal'
    const href = !isIcebox && goalDetailsUrl

    const imageUrl = images ? get(images, 'featured.servingUrl') + '=s80-c' : undefined

    const slices = [
      { percent: percentCompleted, color: colorProgressFilled },
      { percent: 1 - percentCompleted, color: colorProgressTrack },
    ]

    const Pie = (
      <PieChart
        data={ slices }
        imageUrl={ imageUrl }
        innerFill="#fff"
        strokeColor="#fff"
        outerStrokeColor={ colorProgressTrack }
      />
    )

    const originGoalId = origin && origin.goalId

    const Tasks = isPlanMode && (
      <TaskList>
        { goal.tasks.map(this.renderGoalTask) }
      </TaskList>
    )

    return (
      <SidebarNavItem
        isCompact={ isPlanMode }
        isDiscrete={ isCreateNew }
        href={ href }
        title={ title }
        thumbnailUrl={ imageUrl }
        prepend={ Pie }
        originGoalId={ originGoalId }
      >
        { Tasks }
      </SidebarNavItem>
    )
  }

}

export { UserGoalNavItem }
