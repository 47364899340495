import moment from 'moment'
import { keyBy, get, pick } from 'lodash'

const REMINDER_TYPES = {
  single: 'single',
  recurring: 'recurring',
}

const REMINDER_PROPS = {
  single: ['id', 'notifyAt'],
  recurring: ['id', 'days', 'time'],
}

const TASK_TYPES = {
  single: 'single',
  interval: 'repeating',
  repeating: 'repeating',
}

const TARGET_UNITS = {
  daily: 'daily',
  weekly: 'weekly',
  monthly: 'monthly',
}

const DEFAULT_TARGET = 'weekly'

const WEEKDAYS = 'mon,tue,wed,thu,fri,sat,sun'.split(',')

const isNumber = o => typeof o === 'number'

const selectReminders = (reminders, t) => reminders
  .filter(({ type }) => type === t)
  .map(o => pick(o, REMINDER_PROPS[t]))

const cloneGoalTemplate = ({
  goalTemplate,
  startAtDay = moment(),
  taskIdGenerator,
  reminderIdGenerator,
  createdAt = moment().valueOf(),
}) => {
  const dayZero = moment(startAtDay).startOf('day')
  const daysFromZero = days => moment(dayZero).add(days, 'd').startOf('day').valueOf()
  const newTaskId = taskIdGenerator()
  const newReminderId = reminderIdGenerator()
  const tasks = {}

  goalTemplate.tasks.forEach((task, i) => {
    const { title, notes, type, target } = task
    let props = {
      title,
      type: TASK_TYPES[type],
      createdAt,
      description: notes,
    }

    if (isNumber(task.startDate))
      props.startDate = daysFromZero(task.startDate)

    if (isNumber(task.endDate))
      props.endDate = daysFromZero(task.endDate)

    if (target)
      props.target = targetMapper(target)

    if (task.reminders) {
      const reminders = task.reminders
        .map((task, i) => reminderMapper(dayZero, newReminderId(i), task))
        .filter(r => !!r)

      const singleReminders = selectReminders(reminders, REMINDER_TYPES.single)
      const recurringReminders = selectReminders(reminders, REMINDER_TYPES.recurring)

      props.reminders = {}

      if (singleReminders.length > 0)
        props.reminders[REMINDER_TYPES.single] = keyBy(singleReminders, 'id')
      if (recurringReminders.length > 0)
        props.reminders[REMINDER_TYPES.recurring] = keyBy(recurringReminders, 'id')
    }

    const id = newTaskId(i)
    props.id = id
    tasks[id] = { ...props }
  })

  const { images, description } = goalTemplate

  const result = {
    title: goalTemplate.title,
    images,
    description,
    createdAt,
    tasks,
  }

  return JSON.parse(JSON.stringify(result))

}

function reminderMapper(dayZero, id, { type, time, date = 0, weekdays }) {
  if (!REMINDER_TYPES[type]) return
  const hour = get(time, 'hour', 12)
  const minute = get(time, 'minute', 0)

  switch(type) {

  case REMINDER_TYPES.single: {
    const notifyAt = moment(dayZero)
      .add(date, 'd')
      .set({
        hour,
        minute,
      })
      .valueOf()

    return {
      type,
      id,
      notifyAt,
    }
  }

  case REMINDER_TYPES.recurring: {
    const days = {}
    WEEKDAYS.forEach(d => days[d] = !!get(weekdays, d, false))
    return {
      type,
      id,
      days,
      time: {
        hour,
        minute,
      },
    }
  }

  default: {
    return undefined
  }

  }
}

function targetMapper(target) {
  const frequency = target.frequency || 1
  const unit = TARGET_UNITS[target.unit] || DEFAULT_TARGET
  return {
    frequency,
    unit,
  }
}

export { cloneGoalTemplate }
