import { connect } from 'react-redux'
import { AddTaskInput } from './'

import {
  getTaskInputValue,
  getIsPlanMode,
  getIsPlanModeAllowed,
  getSelectedDateDateDiff,
  setTaskInputValue,
  togglePlanMode,
  createAndPlanTodoTask,
} from '../../ducks/dayplanner'

import {
  getSelectedTaskTitle,
  selectPrevious,
  selectNext,
  addSelectionToPlan,
} from '../../ducks/goalNav'

const mapStateToProps = state => ({
  taskInputValue: getTaskInputValue(state),
  isPlanMode: getIsPlanMode(state),
  isPlanModeAllowed: getIsPlanModeAllowed(state),
  hasSelectedTask: !!getSelectedTaskTitle(state),
  selectedTaskTitle: getSelectedTaskTitle(state),
  shouldFocusInput: getSelectedDateDateDiff(state),
})

const mapDispatchToProps = dispatch => ({
  onTaskInputValueChange: value => dispatch(setTaskInputValue(value)),
  onTogglePlanMode: isPlanMode => dispatch(togglePlanMode(isPlanMode)),
  onCreateTodoTask: title => dispatch(createAndPlanTodoTask(title)),
  onSelectPrevious: () => dispatch(selectPrevious()),
  onSelectNext: () => dispatch(selectNext()),
  onPlanSelected: () => dispatch(addSelectionToPlan()),
})

class AddTaskInputContainerClass extends AddTaskInput {}

const AddTaskInputContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddTaskInputContainerClass)

export { AddTaskInputContainer }
