import React from 'react'

import {
  Track,
  ProgressWrap,
  Progress,
  Label,
  LabelWrap,
} from './styles'

const LinearProgress = ({ value }) => {
  return (
    <Track value={ value }>

      <LabelWrap value={ value }>
        <Label value={ value }>
          { Math.round(value * 100) }%
        </Label>
      </LabelWrap>

      <ProgressWrap>
        <Progress
          value={ value }
        />

      </ProgressWrap>

    </Track>
  )
}

export { LinearProgress }
