import React from 'react'
import Flipmove from 'react-flip-move'
import firstBy from 'thenby'
import { FormattedMessage } from 'react-intl'
import {
  FormattedTime,
  FormattedDate,
} from 'react-intl'

import IconAdd from 'material-ui/svg-icons/content/add-circle'

import Markdown from '../Markdown.loadable.js'

import {
  MoodTag,
  IconMoodSmiley,
} from '../'

import {
  TimelineCircle,
  TimelineVerticalLine,
  DayCol,
  MainCol,
  Section,
  EntryWrap,
  DayLabelWrap,
  Month,
  Day,
  TimeWrap,
  Content,
  FeaturedImage,
  Title,
  Tags,
  EntryNotes,
  AtTimelineIcon,
  SmileyWrap,
} from './styles'

export { TimelineCircle }
export { TimelineVerticalLine }

export const TimelineDaySectionEntries = ({ timestamp, entries, onEntryClick }) => {
  const renderEntry = entry => (
    <div key={ entry.id }>
      <Entry
        { ...entry }
        key={ entry.id }
        onClick={ () => onEntryClick(entry.id) }
      />
    </div>
  )

  return (
    <DaySection timestamp={ timestamp }>
      <Flipmove
        duration={ 150 }
        easing="ease-out"
        enterAnimation="fade"
        leaveAnimation="fade"
      >
        { entries.map(renderEntry) }
      </Flipmove>
    </DaySection>
  )
}

export const TimelineEntryStatic = ({ entryTitle, timestamp, notes }) => {
  return (
    <Section isStatic={ true } hasNotes={ !!notes }>
      <DayCol>
        <DayLabel timestamp={ timestamp } />
      </DayCol>
      <MainCol>
        <Entry
          entryTitle={ entryTitle }
          timestamp={ timestamp }
          isStatic={ true }
          notes={ notes }
        />
      </MainCol>
    </Section>
  )
}

export const NewJourneyEntryButton = ({ onClick }) => (
  <AtTimelineIcon onClick={ onClick }>
    <IconAdd />
    <FormattedMessage
      id="journey_add_entry"
      defaultMessage="Create Journal Entry"
    />
  </AtTimelineIcon>
)

const DayLabel = ({ timestamp }) => (
  <DayLabelWrap>
    <Month>
      <FormattedDate
        value={ timestamp }
        month="short"
      />
    </Month>
    <Day>
      <FormattedDate
        value={ timestamp }
        day="2-digit"
      />
    </Day>
  </DayLabelWrap>
)

const TimeLabel = ({ timestamp, isStatic, rating }) => {
  const Circle = rating ? (
    <SmileyWrap>
      <IconMoodSmiley
        rating={ rating }
        width={ 29 }
        height={ 29 }
      />
    </SmileyWrap>
  ) : (
    <TimelineCircle isFilled={ true } />
  )
  return (
    <TimeWrap isStatic={ isStatic }>
      <FormattedTime value={ timestamp } />
      { Circle }
    </TimeWrap>
  )
}

const EntryContent = props => {
  const { entryTitle, imageUrl, notes, titleIcon, moodTags } = props
  const Image = imageUrl && <FeaturedImage src={ imageUrl } alt={ entryTitle } />
  const Notes = notes && (
    <EntryNotes>
      <Markdown>
        { notes }
      </Markdown>
    </EntryNotes>
  )

  const renderMoodTag = ({ id, ...rest }) => (
    <MoodTag
      key={ id }
      { ...rest }
    />
  )

  const MoodTags = moodTags && (
    <Tags>
      { moodTags.sort(firstBy('isPositive', -1).thenBy('title')).map(renderMoodTag) }
    </Tags>
  )

  const TheTitle = entryTitle && (
    <Title>
      { titleIcon } { entryTitle }
    </Title>
  )

  return (
    <Content { ...props }>
      { TheTitle }
      { Image }
      { Notes }
      { MoodTags }
    </Content>
  )
}

const Entry = ({
  entryTitle, timestamp, notes, imageUrl, isStatic, onClick,
  moodTags, rating,
}) => (
  <EntryWrap onClick={ onClick }>
    <TimeLabel
      timestamp={ timestamp }
      isStatic={ isStatic }
      rating={ rating }
    />
    <EntryContent
      entryTitle={ entryTitle }
      notes={ notes }
      imageUrl={ imageUrl }
      moodTags={ moodTags }
    />
  </EntryWrap>
)

const DaySection = ({ timestamp, children }) => (
  <Section>
    <DayCol>
      <DayLabel timestamp={ timestamp } />
    </DayCol>
    <MainCol>
      { children }
    </MainCol>
  </Section>
)

export const TimelineEntry = Entry
export const TimelineDaySection = DaySection
