import { connect } from 'react-redux'
import { ProfileMenu } from './'
import { getAuthenticatedUser, signOut } from '../../ducks/auth'

const mapStateToProps = state => ({
  user: getAuthenticatedUser(state),
})

const mapDispatchToProps = dispatch => ({
  onSignOutClick: () => dispatch(signOut()),
})

const ProfileMenuContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  class ProfileMenuContainer extends ProfileMenu {},
)

export { ProfileMenuContainer }
