import React, { Component } from 'react'
import RaisedButton from 'material-ui/RaisedButton'
import IconUpgrade from 'material-ui/svg-icons/toggle/star-border'

import { MainContainer } from './'

import {
  Wrap,
  CenterVertically,
  Info,
} from './RestrictedContentInfo.styles.js'

const iconStyle = {
  width: 120,
  height: 120,
}

class RestrictedContentInfo extends Component {

  render() {
    const { contentName, onShowPaywall } = this.props
    return (
      <Wrap>
        <MainContainer horizontalPadding verticalPadding>

          <CenterVertically>
            <IconUpgrade style={ iconStyle } color="#E9E8AD" />
            <Info>
              <p><strong>{ contentName }</strong> is part of Remente Premium. Enjoy this and more by upgrading your account today!</p>
              <RaisedButton
                label="Learn More"
                primary={ true }
                onClick={ onShowPaywall }
              />
            </Info>
          </CenterVertically>

        </MainContainer>
      </Wrap>
    )
  }

}

export default RestrictedContentInfo
