import firstBy from 'thenby'

const sortGoalTasks = ({
  tasks,
  manualSortOrder,
}) => tasks
  .map(task => {
    if (!manualSortOrder) return task
    const manualSortIndex = manualSortOrder.indexOf(task.id)
    if (manualSortIndex < 0) return task
    return { ...task, manualSortIndex }
  })
  .sort(
    firstBy(({ manualSortIndex }) => manualSortIndex)
      .thenBy(({ createdAt }) => createdAt),
  )

export { sortGoalTasks }
