import React, { Component } from 'react'
import { values } from 'lodash'
import { FormattedMessage } from 'react-intl'

import Markdown from '../Markdown.loadable.js'
import { GoalTask } from '../'
import { FirebaseApi } from '../../api'
import { cloneGoalTemplate } from '../../utils/goalTemplate'

import {
  Wrap,
  Header,
  Content,
  PlanOutline,
  AcceptButton,
} from './styles'

class Exercise extends Component {

  componentWillMount() {
    const { id, onFetchExercise } = this.props
    if (onFetchExercise) onFetchExercise(id)
  }

  render() {
    const { exercise, isExerciseAccepted, onAcceptButtonClick } = this.props
    if (!exercise) return null

    const goal = cloneGoalTemplate({
      goalTemplate: exercise,
      taskIdGenerator: FirebaseApi.newIdGenerator(),
      reminderIdGenerator: FirebaseApi.newIdGenerator(),
      createdAt: FirebaseApi.TIMESTAMP,
    })

    const { description } = exercise
    const { title } = goal

    const tasks = values(goal.tasks)

    const handleAcceptExercise = () => {
      onAcceptButtonClick({
        goal,
        exerciseId: exercise.id,
      })
    }

    const desc = description && (
      <Markdown>
        { description }
      </Markdown>
    )

    return (
      <Wrap>

        <Header>
          <h4>
            <FormattedMessage
              id="exercise_title"
              defaultMessage="Exercise"
            />
          </h4>
          <h5>{ title }</h5>

          <AcceptButton
            label={
              <FormattedMessage
                id="exercise_button_accept"
                defaultMessage="Let's do this!"
              />
            }
            primary={ true }
            onClick={ handleAcceptExercise }
            disabled={ isExerciseAccepted }
          />
        </Header>

        <Content>

          { desc }

          <PlanOutline>
            { tasks.map(task => <GoalTask key={ task.id } { ...task } />) }
          </PlanOutline>
        </Content>

      </Wrap>
    )
  }

}

export { Exercise }
