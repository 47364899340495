import React, { Component } from 'react'
import {
  defineMessages,
  injectIntl,
  FormattedMessage,
  FormattedRelative,
} from 'react-intl'
import RaisedButton from 'material-ui/RaisedButton'
import FlatButton from 'material-ui/FlatButton'

import {
  Modal,
  ImageDrop,
  ModalPadding,
  ModalHeader,
  ModalTitle,
  ModalSubtle,
  ModalFooter,
} from '../'

import MarkdownEditor from '../MarkdownEditor.loadable.js'

const translations = defineMessages({
  inputNotesPlaceholder: {
    id: 'edit_journey_entry_modal_input_notes_placeholder',
    defaultMessage: 'Add learnings, observations, reflections or comments...',
  },
})

class EditJourneyEntryModalClass extends Component {

  componentWillMount() {
    const { notes } = this.props
    this.setState({
      notes,
    })
  }

  handleOnNotesChanged(notes) {
    const { state } = this
    this.setState({
      ...state,
      notes,
    })
  }

  handleDone() {
    const { onDone } = this.props
    const { notes } = this.state
    onDone({ notes })
  }

  handleClose = ({ overlay }) => {
    const { notes, onCancel } = this.props
    const newNotes = this.state.notes
    const hasChanged = notes !== newNotes
    if (!overlay || !hasChanged) return onCancel()
  }

  render() {
    const { notes } = this.state
    const { taskTitle, loggedAt, uid, id, goalId, organizationId, onCancel, intl } = this.props
    const { formatMessage } = intl

    const imageDropParams = {
      rootStoragePath: `user-data/${ organizationId || uid }/goals/${ goalId }/journey-entries/${ id }`,
      rootDbPath: `goal-journey-entries/${ organizationId || uid }/${ goalId }/${ id }/attachments/images/featured`,
    }

    return (
      <Modal onClose={ this.handleClose }>
        <ModalPadding>

          <ModalHeader>
            <ModalTitle>{ taskTitle || '...' }</ModalTitle>
            <ModalSubtle>
              <FormattedRelative value={ loggedAt } />
            </ModalSubtle>
          </ModalHeader>

          <MarkdownEditor
            autofocus
            value={ notes }
            onChange={ this.handleOnNotesChanged.bind(this) }
            placeholder={ formatMessage(translations.inputNotesPlaceholder) }
          />

          <ModalFooter>

            <ImageDrop { ...imageDropParams } />

            <div>
              <FlatButton
                label={
                  <FormattedMessage
                    id="edit_journey_entry_modal_btn_cancel"
                    defaultMessage="Cancel"
                  />
                }
                onClick={ onCancel }
              />

              <RaisedButton
                label={
                  <FormattedMessage
                    id="edit_journey_entry_modal_btn_done"
                    defaultMessage="Done"
                  />
                }
                primary={ true }
                onClick={ this.handleDone.bind(this) }
              />
            </div>

          </ModalFooter>

        </ModalPadding>
      </Modal>
    )
  }
}

const EditJourneyEntryModal = injectIntl(EditJourneyEntryModalClass)

export { EditJourneyEntryModal }
