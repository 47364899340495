import React, { Component } from 'react'
import IconPositve from 'material-ui/svg-icons/action/thumb-up'
import IconNegative from 'material-ui/svg-icons/action/thumb-down'

import {
  Wrap,
  TrackWrap,
  Track,
  Bar,
  Middle,
  IconWrap,
} from './styles'

const IconLeft = <IconNegative color="#E7E7EB" />
const IconRight = <IconPositve color="#7ADAC3" />

class DualDistributionChart extends Component {
  render() {
    const { left = 0, right = 0, leftContent, rightContent } = this.props
    const total = left + right
    const leftRelative = left / total
    const rightRelative = right / total
    const leftTitle = `${ left } / ${ total }`
    const rightTitle = `${ right } / ${ total }`
    const contentLeft = leftContent || IconLeft
    const contentRight = rightContent || IconRight
    return (
      <Wrap>

        <IconWrap hasIcon={ !leftContent }>
          { contentLeft }
        </IconWrap>

        <TrackWrap>

          <Track isLeft title={ leftTitle }>
            <Bar width={ leftRelative } isLeft />
          </Track>

          <Track title={ rightTitle }>
            <Bar width={ rightRelative } />
          </Track>

          <Middle />

        </TrackWrap>

        <IconWrap positive={ !rightContent }>
          { contentRight }
        </IconWrap>

      </Wrap>
    )
  }
}

export { DualDistributionChart }
