import Loadable from 'react-loadable'
import LoadingIndicatorFull from './LoadingIndicatorFull.js'

const LoadableSelect = Loadable({
  loader: () => import('./Select.js'),
  loading: LoadingIndicatorFull,
})

export default LoadableSelect

