import styled from 'styled-components'
import { createScrollbar } from '../../utils/css'

export const ScrollWrap = styled.div`
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
  ${ ({ scrollbarTheme }) => createScrollbar({
    isMaster: true,
    theme: scrollbarTheme,
  }) }
  ${ ({ background }) => background ? `
    background: ${ background };
  ` : '' }

  > * {
    will-change: scroll-position;
    transform: translate3d(0, 0, 0);
  }

`
