import styled from 'styled-components'

export const Title = styled.div`
  -webkit-app-region: drag;
  small {
    display: block;
    font-size: .8em;
    color: #83CFBD;
    margin-top: .4em;
  }
`
