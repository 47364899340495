import styled from 'styled-components'

const Card = styled.div`
  margin: 4%;
  background: white;
  border-radius: ${ ({ theme }) => theme.radiusSmall }px;
  overflow: hidden;
  transition: all 150ms ease-out;

  box-shadow:
    0 2px 15px 0 rgba(50, 50, 95, .1),
    0 1px 4px 0 rgba(0, 0, 0, .08);

  > h2, > h3, > h4 {
    margin-top: 0;
  }
`

export { Card }
