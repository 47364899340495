import React from 'react'
import { FormattedMessage } from 'react-intl'
import IconButton from 'material-ui/IconButton'
import IconMenu from 'material-ui/IconMenu'
import MenuItem from 'material-ui/MenuItem'
import IconHamburger from 'material-ui/svg-icons/navigation/menu'
import IconDelete from 'material-ui/svg-icons/action/delete'
import IconRename from 'material-ui/svg-icons/image/edit'
import IconComplete from 'material-ui/svg-icons/navigation/check'
import IconActive from 'material-ui/svg-icons/navigation/refresh'
import IconCoverPhoto from 'material-ui/svg-icons/image/panorama'

const colorDelete = '#F48785'

const GoalActionMenu = ({
  isGoalCompleted,
  onDeleteGoalClick,
  onSetGoalCompletedClick,
  onSetGoalActiveClick,
  onChangeCoverPhotoClick,
  onRenameClick,
}) => {

  const Button = (
    <IconButton>
      <IconHamburger />
    </IconButton>
  )

  const GoalCompletedToggle = isGoalCompleted ? (
    <MenuItem
      primaryText={
        <FormattedMessage
          id="goal_details_action_menu_button_mark_active"
          defaultMessage="Mark as Active"
        />
      }
      leftIcon={ <IconActive /> }
      onClick={ onSetGoalActiveClick }
    />
  ) : (
    <MenuItem
      primaryText={
        <FormattedMessage
          id="goal_details_action_menu_button_mark_completed"
          defaultMessage="Mark as Completed"
        />
      }
      leftIcon={ <IconComplete /> }
      onClick={ onSetGoalCompletedClick }
    />
  )

  const ChangeCoverPhotoButton = !isGoalCompleted && (
    <MenuItem
      primaryText={
        <FormattedMessage
          id="goal_details_action_menu_button_set_photo"
          defaultMessage="Change Cover Photo"
        />
      }
      leftIcon={ <IconCoverPhoto /> }
      onClick={ onChangeCoverPhotoClick }
    />
  )

  const RenameButton = !isGoalCompleted && (
    <MenuItem
      primaryText={
        <FormattedMessage
          id="goal_details_action_menu_button_rename"
          defaultMessage="Rename"
        />
      }
      leftIcon={ <IconRename /> }
      onClick={ onRenameClick }
    />
  )

  return (
    <IconMenu
      iconButtonElement={ Button }
      targetOrigin={ { vertical: 'top', horizontal: 'right' } }
      anchorOrigin={ { vertical: 'center', horizontal: 'middle' } }
    >

      { RenameButton }

      { ChangeCoverPhotoButton }

      { GoalCompletedToggle }

      <MenuItem
        primaryText={
          <FormattedMessage
            id="goal_details_action_menu_button_delete"
            defaultMessage="Delete"
          />
        }
        leftIcon={ <IconDelete color={ colorDelete } /> }
        onClick={ onDeleteGoalClick }
        style={ { color: colorDelete } }
      />

    </IconMenu>
  )
}

export { GoalActionMenu }
