import Loadable from 'react-loadable'
import LoadingIndicatorFull from './LoadingIndicatorFull.js'

const LoadableMarkdown = Loadable({
  loader: () => import('./Markdown.js'),
  loading: LoadingIndicatorFull,
})

export default LoadableMarkdown

