import React from 'react'

import { getArc } from '../../utils/svg'

const WheelArc = ({
  x, y, radius, startDeg, finishDeg, width, margin, fillColor = '#000', ...rest
}) => {
  const
    startVal = startDeg * Math.PI / 180 + margin / (2 * radius),
    finishVal = finishDeg * Math.PI / 180 - margin / (2 * radius),
    innerRadius = radius,
    outerRadius = radius + width,

    sx = Math.cos(startVal),
    sy = Math.sin(startVal),
    fx = Math.cos(finishVal),
    fy = Math.sin(finishVal),

    sx1 = x + innerRadius * sx,
    sy1 = y + innerRadius * sy,
    sx2 = x + outerRadius * fx,
    sy2 = y + outerRadius * fy,

    fx1 = x + innerRadius * fx,
    fy1 = y + innerRadius * fy,
    fx2 = x + outerRadius * sx,
    fy2 = y + outerRadius * sy,

    arcInner = getArc({
      rx: innerRadius,
      ry: innerRadius,
      xAxisRotation: startVal,
      sweepFlag: 1,
      x: fx1,
      y: fy1,
    }),

    arcOuter = getArc({
      rx: outerRadius,
      ry: outerRadius,
      xAxisRotation: finishVal,
      x: fx2,
      y: fy2,
      complete: true,
    }),

    path = `
      M ${sx1} ${sy1}
      ${ arcInner }
      L ${sx2}, ${sy2}
      ${ arcOuter }
    `

  return (
    <path
      d={ path }
      fill={ fillColor }
      { ...rest }
    />
  )
}

export { WheelArc }
