import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { MultiToggle } from '../'
import {
  FilterWrap,
  Filter,
} from './styles.js'

class GoalListsFilter extends Component {
  render() {
    const { isShowCompleted, toggleShowCompleted } = this.props
    const labels = [
      <FormattedMessage
        id="goal_lists_filter_toggle_active"
        defaultMessage="Active"
      />,
      <FormattedMessage
        id="goal_lists_filter_toggle_completed"
        defaultMessage="Completed"
      />,
    ]

    return (
      <FilterWrap>
        <Filter>
          <MultiToggle
            options={ labels }
            selectedOption={ isShowCompleted ? 1 : 0 }
            onOptionSelected={ toggleShowCompleted }
          />
        </Filter>
      </FilterWrap>
    )
  }
}

export { GoalListsFilter }
