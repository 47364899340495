import React from 'react'
import { SMILEY_COLORS } from '../../constants'

const transition = {
  transition: 'none 300ms ease-out',
}

const getColors = ({ rating, color, isFilled }) => {
  const c = color || SMILEY_COLORS[rating]
  return {
    fillColor: isFilled ? c : 'transparent',
    strokeColor: isFilled ? '#fff' : c,
    fgColor: isFilled ? '#fff' : c,
  }
}

const getSvgContent = ({ rating, colors }) => {
  const { fillColor, strokeColor, fgColor } = colors
  const Circle = <circle cx={ 200 } cy={ 200 } r="138.055" fill={ fillColor } stroke={ strokeColor } strokeWidth={ 15 } strokeMiterlimit={ 10 } style={ transition } />

  if (rating === 1) return (
    <g>
      { Circle }
      <path d="M135.385 231.446c3.223 5.84 1.1 13.19-4.74 16.412-5.84 3.223-13.19 1.1-16.412-4.74-3.222-5.84 17.93-17.513 21.152-11.672zm150.373 11.707c-3.24 5.83-10.594 7.932-16.425 4.692-5.83-3.24-7.932-10.594-4.692-16.425 3.24-5.832 24.36 5.902 21.12 11.733z" fill={ fgColor } />
      <path d="M186.846 261.264c3.366-3.366 8.017-5.448 13.154-5.448 5.062 0 9.65 2.022 13.005 5.3" fill="none" stroke={ fgColor } strokeWidth={ 12 } strokeLinecap="round" strokeMiterlimit={ 10 } />
    </g>
  )

  if (rating === 2) return (
    <g>
      { Circle }
      <path d="M229.218 244.27c-8.338-6.283-18.713-10.008-29.958-10.008-11.242 0-21.614 3.723-29.95 10.003" fill="none" stroke={ fgColor } strokeWidth={ 15 } strokeLinecap="round" strokeMiterlimit={ 10 } />
      <circle cx="126.519" cy="209.583" r="12.079" fill={ fgColor } />
      <circle cx="273.481" cy="209.583" r="12.079" fill={ fgColor } />
    </g>
  )

  if (rating === 3) return (
    <g>
      { Circle }
      <circle cx="126.519" cy="189.583" r="12.079" fill={ fgColor } />
      <circle cx="273.481" cy="189.583" r="12.079" fill={ fgColor } />
      <path fill="none" stroke={ fgColor } strokeWidth="10.969" strokeLinecap="round" strokeMiterlimit={ 10 } d="M138.598 239.266h122.804" />
    </g>
  )

  if (rating === 4) return (
    <g>
      { Circle }
      <path d="M160.072 212.262c9.086 12.15 23.59 20.014 39.928 20.014 15.902 0 30.065-7.45 39.188-19.05" fill="none" stroke={ fgColor } strokeWidth={ 15 } strokeLinecap="round" strokeMiterlimit={ 10 } />
      <circle cx="126.519" cy="159.583" r="12.079" fill={ fgColor } />
      <circle cx="273.481" cy="159.583" r="12.079" fill={ fgColor } />
    </g>
  )

  if (rating === 5) return (
    <g>
      { Circle }
      <path d="M249.826 172.45c0-27.518-99.653-27.518-99.653 0S172.48 222.276 200 222.276s49.826-22.307 49.826-49.826z" fill={ fgColor } />
      <path d="M114.44 149.583c0-6.67 5.408-12.08 12.08-12.08 6.67 0 12.078 5.41 12.078 12.08m122.804 0c0-6.67 5.408-12.08 12.08-12.08s12.078 5.41 12.078 12.08" fill="none" stroke={ fgColor } strokeWidth={ 10 } strokeLinecap="round" strokeMiterlimit={ 10 } />
    </g>
  )
}

const IconMoodSmiley = ({ rating = 1, color, colors, isFilled, ...props }) => {
  const c = colors || getColors({ rating, color, isFilled })

  return (
    <svg className="mood-smiley" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400" width={ 400 } height={ 400 } { ...props }>
      { getSvgContent({ rating, colors: c }) }
    </svg>
  )

}

export { IconMoodSmiley }
