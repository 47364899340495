import React from 'react'

import {
  Wrap,
  ToggleOption,
} from './styles'

const MultiToggle = ({ options, selectedOption, onOptionSelected }) => {

  const renderOption = (label, i) => (
    <ToggleOption
      key={ i }
      isSelected={ i === selectedOption }
      onClick={ () => onOptionSelected(i) }
    >
      { label }
    </ToggleOption>
  )

  return (
    <Wrap>
      { options.map(renderOption) }
    </Wrap>
  )
}

export { MultiToggle }
