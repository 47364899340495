import React from 'react'
import IconButton from 'material-ui/IconButton'
import IconAdd from 'material-ui/svg-icons/content/add'

import {
  Wrap,
  IconWrap,
  TextField,
  InputOverlay,
  TextFieldWrap,
} from './styles'

const AddListItemInput = ({
  iconProps,
  buttonProps,
  inputOverlay,
  disabled,
  ...rest
}) => {
  const iconColor = rest.value && rest.value.length > 0 ?
    '#7ADAC3' : '#515151'

  return (
    <Wrap>

      <IconWrap>
        <IconButton
          disabled={ disabled }
          { ...buttonProps }
        >
          <IconAdd
            color={ iconColor }
            { ...iconProps }
          />
        </IconButton>
      </IconWrap>

      <TextFieldWrap>

        <TextField
          disabled={ disabled }
          { ...rest }
        />

        <InputOverlay>
          { inputOverlay }
        </InputOverlay>

      </TextFieldWrap>

    </Wrap>
  )
}

export { AddListItemInput }
