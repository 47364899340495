import moment from 'moment'

function parseDateKey(dateKey) {
  return parseInt(dateKey.replace(/year-|month-|day-/gi, ''), 10)
}

const getTodoTasksLastPlannedById = (todoTasksById, dayplans) => {
  const lastPlanned = {}
  for(let year in dayplans) {
    for(let month in dayplans[year]) {
      for(let day in dayplans[year][month]) {
        const d = dayplans[year][month][day]
        if (d && d.plannedTasks) {
          const postponed = d.postponedTasks || {}
          for(let id in d.plannedTasks) {
            if (todoTasksById[id] && !postponed[id]) {
              const d = moment({
                year: parseDateKey(year),
                month: parseDateKey(month) - 1,
                day: parseDateKey(day),
              }).startOf('day')
              lastPlanned[id] = lastPlanned[id] ? (d.isAfter(lastPlanned[id]) ? d : lastPlanned[id]) : d
            }
          }
        }
      }
    }
  }
  return lastPlanned
}

export default getTodoTasksLastPlannedById
