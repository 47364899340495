import Loadable from 'react-loadable'
import LoadingIndicatorFull from './LoadingIndicatorFull.js'

const LoadableHomeScene = Loadable({
  loader: () => import('./HomeScene.js'),
  loading: LoadingIndicatorFull,
})

export default LoadableHomeScene

