import React from 'react'
import chroma from 'chroma-js'
import { range } from 'lodash'
import { WheelArc, SvgIcon } from '../'

const WheelSlice = ({
  id, x, y, valueMax, innerRadius, arcWidth, startDeg, finishDeg,
  arcMargin, sliceMargin, color, value, previousValue, svgIconPath,
  highlightValue, wheelRadius, arcDegStepSize,
  onArcClick, onArcMouseEnter, onArcMouseLeave,
}) => {
  if (wheelRadius <= 0) return null

  const commonArcProps = {
    x, y, startDeg, finishDeg, width: arcWidth - arcMargin,
    margin: sliceMargin,
  }

  const emptyColor = chroma('#f4f4f6')
  const previousColor = chroma('#E0E0E4')
  const highlightColor = chroma(color).set('hsl.l', '/1.1')

  const getFillColor = i => {
    if (highlightValue !== undefined && i < highlightValue)
      return highlightValue === value ? color : highlightColor

    if (i < value && !highlightValue)
      return color

    if (previousValue !== undefined && i < previousValue)
      return previousColor

    return emptyColor.set('hsl.l', '+' + i / valueMax * .02)
  }

  const Arcs = range(valueMax).map(i => {
    const fillColor = getFillColor(i)

    const arcProps = {
      ...commonArcProps,
      radius: innerRadius + i * arcWidth,
      fillColor,
      onClick: () => onArcClick && onArcClick(i),
      onMouseEnter: () => onArcMouseEnter && onArcMouseEnter(i),
      onMouseLeave: () => onArcMouseLeave && onArcMouseLeave(i),
    }
    return (
      <WheelArc
        key={ i }
        { ...arcProps }
      />
    )
  })

  /**
   * Icon
   */

  const
    iconAngle = Math.PI * (startDeg + arcDegStepSize / 2) / 180,
    iconCoords = [Math.cos(iconAngle), Math.sin(iconAngle)],
    iconSize = wheelRadius * 0.18,
    offset = iconSize / 2,
    iconX = (x + (wheelRadius * 1.08) * iconCoords[0]) - offset,
    iconY = (y + (wheelRadius * 1.08) * iconCoords[1]) - offset,
    iconProps = {
      x: iconX,
      y: iconY,
      fill: color,
      width: iconSize,
      height: iconSize,
    }

  const st = {
    pointerEvents: 'all',
  }

  const Icon = svgIconPath && (
    <SvgIcon { ...iconProps } path={ svgIconPath } />
  )

  return (
    <g style={ st }>
      { Arcs }
      { Icon }
    </g>
  )
}

export { WheelSlice }
