import { connect } from 'react-redux'
import SignIn from './SignIn.js'

import {
  fieldChanged,
  authenticateUser,
  createResetPasswordRequest,
} from '../ducks/auth'

const mapStateToProps = ({ auth }) => {
  const { email, password } = auth
  return { email, password }
}

const mapDispatchToProps = dispatch => ({
  fieldChanged: options => dispatch(fieldChanged(options)),
  authenticateUser: auth => {
    delete localStorage.authUserDidSignUp
    return dispatch(authenticateUser(auth))
  },
  onResetPassword: email => dispatch(createResetPasswordRequest(email)),
})

const SignInContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignIn)

export default SignInContainer

