import styled from 'styled-components'

export const SliderWrap = styled.div`
  input[type=range] {
    -webkit-appearance: none;
    width: 100%;
    margin: -2px 0;
  }
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 20px;
    cursor: pointer;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.53), 0px 0px 0px rgba(13, 13, 13, 0.53);
    background: #f4f4f6;
    border-radius: 10px;
    border: 0px solid #010101;
    padding: 0 2px;
  }
  input[type=range]::-webkit-slider-thumb {
    border: 0px solid #000000;
    height: 16px;
    width: 15%;
    border-radius: 16px;
    background: #fff;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: 2px;
    border: 1px solid #E1E1E8;
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
  }
  input[type=range]::-moz-range-track {
    width: 100%;
    height: 16px;
    cursor: pointer;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.53), 0px 0px 0px rgba(13, 13, 13, 0.53);
    background: #f0f0f0;
    border-radius: 10px;
    border: 0px solid #010101;
  }
  input[type=range]::-moz-range-thumb {
    border: 0px solid #000000;
    height: 12px;
    width: 32px;
    border-radius: 16px;
    background: #ffffff;
    cursor: pointer;
  }
  input[type=range]::-ms-track {
    width: 100%;
    height: 16px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type=range]::-ms-fill-lower {
    background: #d9d9d9;
    border: 0px solid #010101;
    border-radius: 20px;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.53), 0px 0px 0px rgba(13, 13, 13, 0.53);
  }
  input[type=range]::-ms-fill-upper {
    background: #f0f0f0;
    border: 0px solid #010101;
    border-radius: 20px;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.53), 0px 0px 0px rgba(13, 13, 13, 0.53);
  }
  input[type=range]::-ms-thumb {
    border: 0px solid #000000;
    width: 32px;
    border-radius: 16px;
    background: #ffffff;
    cursor: pointer;
    height: 12px;
  }
  input[type=range]:focus::-ms-fill-lower {
    background: #f0f0f0;
  }
  input[type=range]:focus::-ms-fill-upper {
    background: #ffffff;
  }
`

