import React, { Component } from 'react'

import { ScrollWrap } from './styles'

class ScrollView extends Component {

  componentWillReceiveProps(nextProps) {
    const { scrollTopTrigger } = this.props
    if (nextProps.scrollTopTrigger !== scrollTopTrigger)
      this.scrollContainer.scrollTop = 0
  }

  render() {
    const { children, theme = 'light', ...rest } = this.props
    return (
      <ScrollWrap
        scrollbarTheme={ theme }
        innerRef={ scrollContainer => this.scrollContainer = scrollContainer }
        { ...rest }
      >
        { children }
      </ScrollWrap>
    )
  }

}

export { ScrollView }
