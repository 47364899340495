import en from './en.json'
import sv from './sv.json'
import de from './de.json'

const translations = {
  ...en,
  ...sv,
  ...de,
}

export const getTranslations = locale => translations[locale] || translations.en
