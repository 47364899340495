import React, { Component } from 'react'

import { SliderWrap } from './styles'

class Slider extends Component {

  handleChange = e => {
    try {
      const { onChange } = this.props
      const value = parseInt(e.target.value, 10)
      onChange && onChange(value, e)
    } catch(err) {
      // ignore
    }
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { onChange, ...rest } = this.props
    return (
      <SliderWrap>
        <input
          type="range"
          onChange={ this.handleChange }
          { ...rest }
        />
      </SliderWrap>
    )
  }

}

export { Slider }
