import React from 'react'
import { FormattedMessage } from 'react-intl'
import IconButton from 'material-ui/IconButton'
import IconMenu from 'material-ui/IconMenu'
import MenuItem from 'material-ui/MenuItem'
import IconEye from 'material-ui/svg-icons/action/visibility'
import IconShow from 'material-ui/svg-icons/action/visibility'
import IconHide from 'material-ui/svg-icons/action/visibility-off'

const GoalDisplayActionMenu = ({
  isCompletedTasksVisible,
  isTaskDescriptionsVisible,
  onToggleVisibilityCompleted,
  onToggleVisibilityDescriptions,
}) => {

  const Button = (
    <IconButton>
      <IconEye />
    </IconButton>
  )

  const ShowCompletedToggle = isCompletedTasksVisible ? (
    <MenuItem
      primaryText={
        <FormattedMessage
          id="goal_details_action_menu_button_hide_completed"
          defaultMessage="Hide Completed"
        />
      }
      leftIcon={ <IconHide /> }
      onClick={ onToggleVisibilityCompleted }
    />
  ) : (
    <MenuItem
      primaryText={
        <FormattedMessage
          id="goal_details_action_menu_button_show_completed"
          defaultMessage="Show Completed"
        />
      }
      leftIcon={ <IconShow /> }
      onClick={ onToggleVisibilityCompleted }
    />
  )

  const ShowDescriptionsToggle = isTaskDescriptionsVisible ? (
    <MenuItem
      primaryText={
        <FormattedMessage
          id="goal_details_action_menu_button_hide_descriptions"
          defaultMessage="Hide Descriptions"
        />
      }
      leftIcon={ <IconHide /> }
      onClick={ onToggleVisibilityDescriptions }
    />
  ) : (
    <MenuItem
      primaryText={
        <FormattedMessage
          id="goal_details_action_menu_button_show_descriptions"
          defaultMessage="Show Descriptions"
        />
      }
      leftIcon={ <IconShow /> }
      onClick={ onToggleVisibilityDescriptions }
    />
  )

  return (
    <IconMenu
      iconButtonElement={ Button }
      targetOrigin={ { vertical: 'top', horizontal: 'left' } }
      anchorOrigin={ { vertical: 'center', horizontal: 'middle' } }
    >

      { ShowDescriptionsToggle }

      { ShowCompletedToggle }

    </IconMenu>
  )
}

export { GoalDisplayActionMenu }
