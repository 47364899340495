import { createSelector } from 'reselect'
import { sum, keyBy } from 'lodash'

import { arraySortByArray } from '../../utils/array'

import {
  getTasksFromGoals,
  attachJourneyEntries,
} from '../../utils/goal'

import { getMyGoals } from '../goal'

import {
  getMyJourneyEntriesByGoalId,
  getMyGoalOrderActive,
  getMyGoalOrderCompleted,
} from '../userContent'

import {
  getOrganizationGoals,
  getOrganizationGoalJourneyEntriesByGoalId,
} from '../organization'

/**
 * Selectors
 */

export const getJourneyEntriesByGoalId = createSelector(
  getMyJourneyEntriesByGoalId,
  getOrganizationGoalJourneyEntriesByGoalId,
  (mine, orgs) => ({ ...mine, ...orgs }),
)

export const getMyGoalsEnriched = createSelector(
  getMyGoals,
  getMyJourneyEntriesByGoalId,
  (goals, journeyEntriesByGoalId) => attachJourneyEntries({
    goals,
    journeyEntriesByGoalId,
  }),
)

export const getOrganizationGoalsEnriched = createSelector(
  getOrganizationGoals,
  getOrganizationGoalJourneyEntriesByGoalId,
  (goals, journeyEntriesByGoalId) => attachJourneyEntries({
    goals,
    journeyEntriesByGoalId,
  }),
)

export const getGoalsWithProgress = createSelector(
  getMyGoalsEnriched,
  getOrganizationGoalsEnriched,
  (myGoals, orgGoals) => myGoals.concat(orgGoals),
)

export const getActiveGoalsWithProgress = createSelector(
  getGoalsWithProgress,
  getMyGoalOrderActive,
  (goals, sortOrder) => arraySortByArray({
    items: goals.filter(({ isCompleted }) => !isCompleted),
    sortOrder,
  }),
)

export const getNumGoalsActive = createSelector(
  getActiveGoalsWithProgress,
  goals => goals.length,
)

export const getCompletedGoalsWithProgress = createSelector(
  getGoalsWithProgress,
  getMyGoalOrderCompleted,
  (goals, sortOrder) => arraySortByArray({
    items: goals.filter(({ isCompleted }) => isCompleted),
    sortOrder,
  }),
)

export const getGoalsWithProgressById = createSelector(
  getGoalsWithProgress,
  goals => keyBy(goals, 'id'),
)

export const getMyGoalsEnrichedActive = createSelector(
  getMyGoalsEnriched,
  getMyGoalOrderActive,
  (goals, sortOrder) => arraySortByArray({
    items: goals.filter(({ isCompleted }) => !isCompleted),
    sortOrder,
  }),
)

export const getMyGoalsEnrichedCompleted = createSelector(
  getMyGoalsEnriched,
  getMyGoalOrderCompleted,
  (goals, sortOrder) => arraySortByArray({
    items: goals.filter(({ isCompleted }) => isCompleted),
    sortOrder,
  }),
)

export const getMyGoalsEnrichedActiveTasks = createSelector(
  getActiveGoalsWithProgress,
  goals => getTasksFromGoals(goals),
)

export const getMyGoalsEnrichedActiveTasksById = createSelector(
  getMyGoalsEnrichedActiveTasks,
  tasks => keyBy(tasks, 'id'),
)

export const getActiveGoalsTotalProgress = createSelector(
  getActiveGoalsWithProgress,
  goals => {
    const acc = goals.map(({ percentCompleted }) => percentCompleted)
    const total = sum(acc)
    return total / goals.length
  },
)
