import React, { Component } from 'react'

import {
  GoalTemplateTile,
} from '../'

import {
  TemplateList,
} from './styles'

class GoalTemplateCategory extends Component {
  render() {
    const { title, goalTemplates, onGoalTemplateClick } = this.props

    const renderTemplate = ({ id, ...rest }, i) => (
      <GoalTemplateTile
        key={ i }
        id={ id }
        onClick={ () => onGoalTemplateClick(id) }
        { ...rest }
      />
    )

    return (
      <div>
        <h4>{ title }</h4>
        <TemplateList>
          { goalTemplates.map(renderTemplate) }
        </TemplateList>
      </div>
    )
  }
}

export { GoalTemplateCategory }
