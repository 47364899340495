import styled from 'styled-components'
import chroma from 'chroma-js'

export const Wrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 100px;
  background: #f4f4f6;
  box-shadow: inset 0 1px 0 ${ chroma('#f4f4f6').darken(.4).hex() };

`

export const ToggleOption = styled.div`
  flex: 1;
  height: 100%,
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 100px;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
  padding: .4em 0;
  transition: background 150ms ease-out;
  margin: 3px 0;
  &:last-child {
    margin-right: 3px;
  }
  &:first-child {
    margin-left: 3px;
  }
  ${ ({ theme, isSelected }) => `
    color: ${ theme.colorFadedDark };
    &:hover,
    &:focus {
      color: ${ theme.colorPrimary1 };
    }
    ${ isSelected ? `
      background: #fff;
    ` : '' }
  ` }
`
