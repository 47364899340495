import React, { Component } from 'react'

import {
  map,
  includes,
  has,
} from 'lodash'

import IconExercise from 'material-ui/svg-icons/editor/mode-edit'
import IconVideo from 'material-ui/svg-icons/action/description'
import IconText from 'material-ui/svg-icons/action/description'

import {
  List,
  ListItem,
  ListItemIcon,
} from '../components'

class CourseOutline extends Component {

  render() {
    const { lessons } = this.props
    if (!lessons) return <div />
    return (
      <List>
        { lessons.map(this.renderLesson) }
      </List>
    )
  }

  renderLesson = lesson => {
    const { language, id, replace, userReadLessons, currentLesson, isBehindPaywall } = this.props
    const { lessonId, content } = lesson
    const isCurrentLesson = lessonId === currentLesson
    const lessonIcon = this.getLessonIcon(content, isCurrentLesson ? 'white' : undefined)
    const lessonLink = isBehindPaywall ? '/resources' : `/resources/courses/${ language }/${ id }/${ lessonId }`
    const isLessonRead = has(userReadLessons, lessonId)
    const listItemStyle = {
      borderLeft: '3px solid transparent',
      borderColor: isLessonRead ? '#7ADAC3' : '#f4f4f6',
      backgroundColor: isCurrentLesson ? '#7ADAC3' : 'white',
      color: isCurrentLesson ? 'white' : 'inherit',
      paddingRight: '2em',
      borderRadius: '0 4px 4px 0',
      cursor: isBehindPaywall ? 'not-allowed' : 'pointer',
    }
    return (
      <ListItem
        key={ lessonId }
        href={ lessonLink }
        replace={ replace }
        style={ listItemStyle }
        onClick={ this.handleLessonClick }
      >
        <ListItemIcon>
          { lessonIcon }
        </ListItemIcon>
        <div>{ lesson.title }</div>
      </ListItem>
    )
  }

  handleLessonClick = e => {
    const { isBehindPaywall, onPremiumContentClick } = this.props
    if (isBehindPaywall) {
      e.preventDefault()
      onPremiumContentClick()
    }
  }

  getLessonIcon(contentBlocks, color = '#7ADAC3') {
    var outline = map(contentBlocks, 'contentType')
    const iconProps = {
      color: color,
    }
    if (includes(outline, 'exercise')) return <IconExercise { ...iconProps } />
    if (
      includes(outline, 'vimeo-private') ||
      includes(outline, 'vimeo-public') ||
      includes(outline, 'youtube-public')
    ) return <IconVideo { ...iconProps } />
    return <IconText { ...iconProps } />
  }

}

export default CourseOutline
