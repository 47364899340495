import { combineEpics, ofType } from 'redux-observable'
import { createAction } from 'redux-actions'
import { createSelector } from 'reselect'
import { of } from 'rxjs'
import { map, mergeMap, catchError } from 'rxjs/operators'
import { values } from 'lodash'
import { FirebaseApi } from '../../api'
import getTodoTasksLastPlannedById from '../../utils/dayplanner/getTodoTasksLastPlannedById.js'

import {
  getMyTodoTasksById,
} from '../userContent'

import {
  getDayplansByYearMonthDay,
} from '../dayplan'

const TODO_TASK_CREATE           = 'remente/dayplan/TODO_TASK_CREATE'
const TODO_TASK_CREATE_FULFILLED = 'remente/dayplan/TODO_TASK_CREATE_FULFILLED'
const TODO_TASK_CREATE_REJECTED  = 'remente/dayplan/TODO_TASK_CREATE_REJECTED'
const TODO_TASK_DELETE           = 'remente/dayplan/TODO_TASK_DELETE'
const TODO_TASK_DELETE_FULFILLED = 'remente/dayplan/TODO_TASK_DELETE_FULFILLED'
const TODO_TASK_DELETE_REJECTED  = 'remente/dayplan/TODO_TASK_DELETE_REJECTED'

export const createTodoTask   = createAction(TODO_TASK_CREATE)
const createTodoTaskFulfilled = createAction(TODO_TASK_CREATE_FULFILLED)
const createTodoTaskRejected  = createAction(TODO_TASK_CREATE_REJECTED)
export const deleteTodoTask   = createAction(TODO_TASK_DELETE)
const deleteTodoTaskFulfilled = createAction(TODO_TASK_DELETE_FULFILLED)
const deleteTodoTaskRejected  = createAction(TODO_TASK_DELETE_REJECTED)

/**
 * Selectors
 */

export const getMyTodoTasks = createSelector(
  getMyTodoTasksById,
  tasks => values(tasks).filter(
    // https://github.com/remente/remente-docs/blob/master/app/specs/dayplanner.md#visibility-of-tasks
    ({ createdAt }) => createdAt > 1494343870000,
  ),
)

export const getMyTodoTasksActive = createSelector(
  getMyTodoTasks,
  tasks => tasks.filter(({ checkedInAt }) => !checkedInAt),
)

export const todoTasksLastPlannedHashMapSelector = createSelector(
  getMyTodoTasksById,
  getDayplansByYearMonthDay,
  getTodoTasksLastPlannedById,
)

/**
 * Epics
 */

export const createTodoTaskEpic = action$ => action$.pipe(
  ofType(TODO_TASK_CREATE),
  map(({ payload }) => payload),
  mergeMap(title => {
    return FirebaseApi
      .createTodoTask({ title })
      .pipe(
        map(createTodoTaskFulfilled),
        catchError(err => of(createTodoTaskRejected(err))),
      )
  }),
)

export const deleteTodoTaskEpic = action$ => action$.pipe(
  ofType(TODO_TASK_DELETE),
  map(({ payload }) => payload),
  mergeMap(id => {
    return FirebaseApi
      .deleteTodoTask({ id })
      .pipe(
        map(deleteTodoTaskFulfilled),
        catchError(err => of(deleteTodoTaskRejected(err))),
      )
  }),
)

export const todoTaskEpics = combineEpics(
  createTodoTaskEpic,
  deleteTodoTaskEpic,
)

