import styled from 'styled-components'

const VerticalAlign = styled.div`
  display: flex;
  align-items: ${ ({ alignItems }) => alignItems || 'center' };
  ${ ({ mt, mr, mb, ml, mx, my }) => `
    margin: ${ mt || my || 0 } ${ mr || mx || 0 } ${ mb || my || 0 } ${ ml || mx || 0 };
  ` }
  ${ ({ pt, pr, pb, pl, px, py }) => `
    padding: ${ pt || py || 0 } ${ pr || px || 0 } ${ pb || py || 0 } ${ pl || px || 0 };
  ` }
`

export { VerticalAlign }
