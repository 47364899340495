import { get } from 'lodash'

const includeJourneyEntriesInTasks = ({
  tasks,
  date,
  journeyEntriesByDate,
}) => tasks.map(task => {

  const { id, goalId } = task

  if (!goalId) return task

  const journeyEntry = get(
    journeyEntriesByDate, [goalId, id, date, 0],
  )

  const journeyEntryId = get(journeyEntry, 'id')
  const checkedInAt = get(journeyEntry, 'loggedAt')

  return {
    ...task,
    journeyEntryId,
    checkedInAt,
  }

})

export { includeJourneyEntriesInTasks }
