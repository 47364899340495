import styled, { css } from 'styled-components'

const restrictWidth = css`
  max-width: 740px;
`

const horizontalPadding = css`
  padding-left: 20px;
  padding-right: 20px;
`

const verticalPadding = css`
  padding-top: 3%;
  padding-bottom: 3%;
`

const MainContainer = styled.div`
  position: relative;
  margin: 0 auto;
  ${ props => props.fullWidth ? undefined : restrictWidth }
  ${ props => props.horizontalPadding ? horizontalPadding : undefined }
  ${ props => props.verticalPadding ? verticalPadding : undefined }
`

export { MainContainer }
