import React, { Component } from 'react'
import {
  defineMessages,
  injectIntl,
  FormattedMessage,
  FormattedRelative,
} from 'react-intl'
import RaisedButton from 'material-ui/RaisedButton'
import FlatButton from 'material-ui/FlatButton'
import IconFeelings from 'material-ui/svg-icons/action/favorite'
import IconNotes from 'material-ui/svg-icons/navigation/menu'

import {
  Modal,
  ModalPadding,
  ModalHeader,
  ModalTitle,
  ModalSubtle,
  ModalFooter,
  MoodTagPicker,
} from '../'

import MoodPicker from '../MoodPicker.loadable.js'
import MarkdownEditor from '../MarkdownEditor.loadable.js'

import {
  FormSection,
  InputHeader,
  InputHeaderContent,
} from '../InputHeader/InputHeader.js'

import {
  MoodAssessmentDetails,
} from './styles'

const translations = defineMessages({
  inputNotesPlaceholder: {
    id: 'edit_mood_assessment_modal_input_notes_placeholder',
    defaultMessage: 'Reflect on your current activity, what people you are with and take note of your thoughts...',
  },
})

class EditMoodAssessmentModalClass extends Component {

  state = {
    selectedRating: null,
    notes: '',
    selectedTags: {},
    moodTagTabsSelectedIndex: 0,
  }

  render() {
    const {
      createdAt,
      selectedRating,
      notes,
      selectedTags,
      moodTagTabsSelectedIndex,
    } = this.state
    const {
      positiveTags,
      negativeTags,
      onCancel,
      intl,
    } = this.props
    const { formatMessage } = intl

    const DateLabel = createdAt && (
      <ModalSubtle>
        <FormattedRelative value={ createdAt } />
      </ModalSubtle>
    )

    return (
      <Modal onClose={ this.handleClose }>
        <ModalPadding>
          <ModalHeader>
            <ModalTitle>
              <FormattedMessage
                id="edit_mood_assessment_modal_title"
                defaultMessage="How do you feel?"
              />
            </ModalTitle>
            { DateLabel }
          </ModalHeader>
          <MoodPicker
            selectedRating={ selectedRating }
            onMoodSelected={ this.handleMoodSelected }
          />
        </ModalPadding>

        <MoodAssessmentDetails isVisible={ selectedRating }>
          <FormSection>
            <InputHeader
              icon={ <IconFeelings /> }
            >
              <InputHeaderContent>
                <FormattedMessage
                  id="edit_mood_assessment_input_feelings_label"
                  defaultMessage="Feelings"
                />
              </InputHeaderContent>
            </InputHeader>
            <MoodTagPicker
              positiveTags={ positiveTags }
              negativeTags={ negativeTags }
              onTagToggle={ this.handleTagToggle }
              selectedTags={ selectedTags }
              tabsSelectedIndex={ moodTagTabsSelectedIndex }
              tabsOnSelect={ this.handleFeelingsTabSelected }
            />
          </FormSection>

          <FormSection>
            <InputHeader
              icon={ <IconNotes /> }
            >
              <InputHeaderContent>
                <FormattedMessage
                  id="edit_mood_assessment_input_notes_label"
                  defaultMessage="Notes"
                />
              </InputHeaderContent>
            </InputHeader>
            <MarkdownEditor
              value={ notes }
              onChange={ this.handleNotesChange }
              placeholder={ formatMessage(translations.inputNotesPlaceholder) }
            />
          </FormSection>
        </MoodAssessmentDetails>

        <ModalPadding>
          <ModalFooter>
            <div />

            <div>
              <FlatButton
                label={
                  <FormattedMessage
                    id="edit_journey_entry_modal_btn_cancel"
                  />
                }
                onClick={ onCancel }
              />

              <RaisedButton
                label={
                  <FormattedMessage
                    id="edit_journey_entry_modal_btn_done"
                  />
                }
                primary={ true }
                onClick={ this.handleDone }
                disabled={ !selectedRating }
              />
            </div>

          </ModalFooter>
        </ModalPadding>
      </Modal>
    )
  }

  componentWillMount() {
    const { notes } = this.props
    this.setState({
      notes,
    })
  }

  handleTagToggle = id => {
    const { state } = this
    const selectedTags = { ...state.selectedTags }

    if (selectedTags[id]) delete selectedTags[id]
    else selectedTags[id] = true

    this.setState({
      ...state,
      selectedTags,
    })
  }

  handleFeelingsTabSelected = moodTagTabsSelectedIndex => {
    const { state } = this
    this.setState({
      ...state,
      moodTagTabsSelectedIndex,
    })
  }

  handleMoodSelected = selectedRating => {
    const moodTagTabsSelectedIndex = selectedRating > 2 ? 1 : 0
    const { state } = this
    this.setState({
      ...state,
      selectedRating,
      moodTagTabsSelectedIndex,
    })
  }

  handleNotesChange = notes => {
    const { state } = this
    this.setState({
      ...state,
      notes,
    })
  }

  handleDone = () => {
    const { selectedRating, notes, selectedTags } = this.state
    const { onDone } = this.props
    const assessment = {}
    assessment.rating = selectedRating
    assessment.notes = notes.length > 0 ? notes : null
    assessment['mood-tags'] = selectedTags
    onDone(assessment)
  }

  handleClose = ({ overlay }) => {
    const { notes, onCancel } = this.props
    const newNotes = this.state.notes
    const hasChanged = notes !== newNotes
    if (!overlay || !hasChanged) return onCancel()
  }

}

const EditMoodAssessmentModal = injectIntl(EditMoodAssessmentModalClass)

export { EditMoodAssessmentModal }

