import React from 'react'
import { FormattedMessage } from 'react-intl'

import IconChevronRight from 'material-ui/svg-icons/navigation/chevron-right'

import {
  DayPicker,
  VerticalAlign,
} from '../'

const DateRangePicker = ({
  onStartChange,
  onEndChange,
  startDate,
  endDate,
  id,
}) => (
  <VerticalAlign>

    <DayPicker
      id={ `range-picker-${ id }-start` }
      value={ startDate }
      onChange={ onStartChange }
      placeholderText={
        <FormattedMessage
          id="date_range_picker_start_date_placeholder"
          defaultMessage="Select Start Date"
        />
      }
    />

    <IconChevronRight color="#ccc" />

    <DayPicker
      id={ `range-picker-${ id }-end` }
      value={ endDate }
      onChange={ onEndChange }
      placeholderText={
        <FormattedMessage
          id="date_range_picker_end_date_placeholder"
          defaultMessage="Select End Date"
        />
      }
    />

  </VerticalAlign>
)

export { DateRangePicker }

