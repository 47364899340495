import styled from 'styled-components'

export const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${ ({ isElectron }) => !isElectron ? `
    position: sticky;
    top: 0;
    z-index: 3;
  ` : '' }
  padding: 0 16px;
  padding-left: 10px;
  border-top: 1px solid #f4f4f6;
  border-bottom: 2px solid #f4f4f6;
  background: #fff;
  > :last-child {
  }
  svg {
    width: 15px !important;
    height: 15px !important;
    margin-right: 3px;
  }
`

export const GoalListWrap = styled.div`
  &:first-child {
    ${ ListHeader } {
      border-top: none;
    }
  }
`

export const ListTitle = styled.button`
  flex: 1;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  background: none;
  padding: .5em 0;
  font-weight: 600;
  font-size: 12px;
`
