import styled from 'styled-components'

export const NoPlanPlaceholderText = styled.div`
  opacity: .5;
  padding: 2em;
  text-align: center;
  margin-bottom: 1em;
  border: 2px dotted #ccc;
  border-radius: 4px;
`

export const GoalPlanContainer = styled.div`
  padding: 0 5%;
  @media (min-width: 900px) {
    padding: 0 10%;
  }
`

export const AddGoalTaskInputWrap = styled.div`
  padding-left: 19px;
`
