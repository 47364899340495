import React from 'react'
import { Switch, Route } from 'react-router'
import AuthScene from './AuthScene.js'
import { AppChromeAuthenticated } from './'

const AuthRoutes = ({ isAuthenticated }) => {
  return (
    <Switch>
      <Route path="/login" render={ () => <AuthScene isAuthenticated={ isAuthenticated } /> } />
      <Route path="/register" render={ () => <AuthScene isAuthenticated={ isAuthenticated } /> } />
      <Route render={ () => <AppChromeAuthenticated isAuthenticated={ isAuthenticated } /> } />
    </Switch>
  )
}

export default AuthRoutes
