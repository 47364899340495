import React, { Component } from 'react'
import {
  Label,
  Content,
} from './styles.js'

class ToggleButton extends Component {
  render() {
    const {
      label,
      children,
      outerProps,
      selectedColor = '#7b7b91',
      ...rest
    } = this.props

    return (
      <Label
        selectedColor={ selectedColor }
        { ...outerProps }
      >
        <input type="checkbox" { ...rest } />
        <Content>
          { label || children }
        </Content>
      </Label>
    )
  }
}

export { ToggleButton }
