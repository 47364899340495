import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import { FirebaseApi } from '../../api'
import { get } from 'lodash'

import IconButton from 'material-ui/IconButton'
import CircularProgress from 'material-ui/CircularProgress'
import IconRemove from 'material-ui/svg-icons/action/delete'
import IconPhoto from 'material-ui/svg-icons/image/add-a-photo'

import {
  Wrap,
  ImagePreview,
  ImageOverlay,
} from './styles'

class ImageDrop extends Component {

  state = {
    dbValue: null,
    isDropzoneActive: false,
    uploadPending: false,
  }

  componentDidMount() {
    const { rootDbPath } = this.props
    this.dbRef = FirebaseApi.dbRef(rootDbPath)
    this.dbRef.on('value', this.handleOnValue)
  }

  componentWillUnmount() {
    this.dbRef.off('value', this.handleOnValue)
  }

  handleOnValue = snap => {
    this.setState({
      dbValue: snap.val(),
    })
  }

  removeImage = e => {
    e.preventDefault()
    this.dbRef.child('storagePath').remove()
  }

  handleOnDrop(acceptedFiles) {
    const file = acceptedFiles[0]
    if (!file) return

    this.setState({
      ...this.state,
      isDropzoneActive: false,
      uploadPending: true,
    })

    const { rootStoragePath, rootDbPath } = this.props

    const done = () => this.setState({
      ...this.state,
      uploadPending: false,
    })

    FirebaseApi
      .setImageRef({
        file,
        rootStoragePath,
        rootDbPath,
      })
      .then(done)
      .catch(done)
  }

  handleDragEnter = () => {
    this.setState({
      ...this.state,
      isDropzoneActive: true,
    })
  }

  handleDragLeave = () => {
    this.setState({
      ...this.state,
      isDropzoneActive: false,
    })
  }

  render() {
    const { dbValue, isDropzoneActive, uploadPending } = this.state
    const storagePath = get(dbValue, 'storagePath')
    const servingUrl = get(dbValue, 'servingUrl')
    const bgImage = storagePath && !uploadPending ? servingUrl : undefined
    const hasImage = !!bgImage
    const iconColor = hasImage ? '#ffffff' : undefined

    const renderContent = () => {
      if (!storagePath || isDropzoneActive)
        return <IconPhoto color={ iconColor } />

      if (!servingUrl || uploadPending)
        return <CircularProgress color={ iconColor } size={ 30 } />

      return (
        <IconButton onClick={ this.removeImage }>
          <IconRemove color={ iconColor } />
        </IconButton>
      )
    }

    return (
      <Dropzone
        disableClick={ uploadPending }
        onDrop={ this.handleOnDrop.bind(this) }
        onDragEnter={ this.handleDragEnter }
        onDragLeave={ this.handleDragLeave }
        accept="image/jpeg, image/png, image/jpg"
        style={ {} }
      >
        <Wrap hasImage={ hasImage } isDropzoneActive={ isDropzoneActive }>
          <ImageOverlay>
            { renderContent() }
          </ImageOverlay>
          <ImagePreview bgImage={ bgImage } />
        </Wrap>
      </Dropzone>
    )
  }

}

export { ImageDrop }
