import styled, { css } from 'styled-components'

export const ChartTitleCss = css`
  font-size: .8em;
  text-transform: uppercase;
  color: ${ ({ theme: { colorFadedDark } }) => colorFadedDark };
`

const ChartTitle = styled.h3`
  ${ ({ isRelative }) => !isRelative ? `
    text-align: center;
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    z-index: 3;
  ` : '' }
  margin: 0;
  transition: all 150ms ease-out;
  ${ ChartTitleCss }
  ${ ({ isHidden }) => isHidden ? `
    opacity: 0;
    transform: translateY(-15px);
  ` : '' }
  ${ ({ bottom }) => bottom ? `
    top: auto;
    bottom: 0;
    transform: translateY(50%);
    text-shadow: 0 0 20px #fff;
  ` : '' }
  ${ ({ title }) => title ? `
    cursor: help;
  ` : '' }
`

export { ChartTitle }
