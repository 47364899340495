import React, { Fragment } from 'react'
import { Route } from 'react-router-dom'
import LoadableResourcesHome from './ResourcesHome.loadable.js'
import CourseOutlineSceneContainer from './CourseOutlineSceneContainer.js'
import CourseLessonSceneContainer from './CourseLessonSceneContainer.js'
import BoostSceneContainer from './BoostSceneContainer.js'

const RouteResources = () => {
  return (
    <Fragment>

      <Route
        exact path="/resources"
        component={ LoadableResourcesHome }
      />

      <Route
        exact path="/resources/courses/:language/:courseId"
        component={ CourseOutlineSceneContainer }
      />

      <Route
        exact path="/resources/courses/:language/:courseId/:lessonId"
        component={ CourseLessonSceneContainer }
      />

      <Route
        exact path="/resources/boosts/:language/:boostId"
        component={ BoostSceneContainer }
      />

    </Fragment>
  )
}

export default RouteResources
