import React, { Component } from 'react'

class ComponentSwitch extends Component {
  render() {
    const { components, selectedComponent, ...rest } = this.props
    const SelectedComponent = components[selectedComponent]
    if (!SelectedComponent) return null
    return <SelectedComponent { ...rest } />
  }
}

export { ComponentSwitch }
