import { autoplanFilterFactory } from './'

const getAutoPlannedTasks = ({
  date,
  goalTasks,
  postponedTasks = {},
}) => {
  return goalTasks
    .filter(autoplanFilterFactory({ date }))
    .filter(({ id }) => !postponedTasks[id])
}

export { getAutoPlannedTasks }
