import { updateMoodAssessment } from './ducks/editMoodAssessmentModal'
import { focusApp, blurApp } from './ducks/app'
import { startHeartbeat } from './ducks/timing'
import { updateTodoListOrder, incrementSelectedDate, decrementSelectedDate, setIsPlanMode, createAndPlanTodoTaskFulfilled, toggleTaskCompletedFulfilled, addGoalStepToPlan, postponeTask, deleteTodoTask } from './ducks/dayplanner'

export const eventMetrics = {

  /**
   * General
   */

  [startHeartbeat().type]: 'open_app',
  [focusApp().type]: 'focus_app',
  [blurApp().type]: 'blur_app',

  /**
   * Dayplanner / Plan Your Day
   */

  [incrementSelectedDate().type]: 'tap_day_plan_next_day_button',
  [decrementSelectedDate().type]: 'tap_day_plan_previous_day_button',
  [setIsPlanMode().type]: ({ payload }) => payload ? 'enter_plan_mode' : 'exit_plan_mode',
  [createAndPlanTodoTaskFulfilled().type]: ({ payload: { id } }) => ({
    name: 'create_todo_task',
    params: { task_id: id },
  }),
  [toggleTaskCompletedFulfilled().type]: ({ payload: { type, taskId } }) => type === 'todo' ? {
    name: 'check_in_todo_task',
    params: { task_id: taskId },
  } : {
    name: 'create_journey_entry',
    params: { source: 'day-plan' },
  },
  [addGoalStepToPlan().type]: ({ payload: { goalId, planMethod = 'hotkey' } }) => ({
    name: goalId === 'icebox' ? 'plan_todo_task' : 'plan_goal_task',
    params: { plan_method: planMethod },
  }),
  [postponeTask().type]: ({ payload: { goalId } }) => ({
    name: goalId ? 'unplan_goal_task' : 'unplan_todo_task',
  }),
  [deleteTodoTask().type]: 'delete_todo_task',
  [updateTodoListOrder().type]: 'reorder_dayplan',

  /**
   * Mood Assessments
   */

  [updateMoodAssessment().type]: 'create_mood_assessment',

}
