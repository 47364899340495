import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import Menu from 'material-ui/Menu'
import MenuItem from 'material-ui/MenuItem'
import Popover from 'material-ui/Popover'
import IconMood from 'material-ui/svg-icons/image/tag-faces'
import IconLife from 'material-ui/svg-icons/editor/pie-chart'
import IconPlan from 'material-ui/svg-icons/action/done-all'

import { IconTarget } from '../'

const anchorOrigin = { horizontal: 'middle', vertical: 'center' }
const targetOrigin = { horizontal: 'left', vertical: 'top' }

class CreateMenu extends Component {

  state = {
    isMenuOpen: false,
    anchorElement: null,
  }

  closeMenu = () => {
    this.setState({
      isMenuOpen: false,
    })
  }

  openMenu = e => {
    e.preventDefault()
    this.setState({
      isMenuOpen: true,
      anchorElement: e.target,
    })
  }

  render() {
    const { isMenuOpen, anchorElement } = this.state
    const {
      renderButton,
      onCreateMoodAssessmentClick,
      onCreateLifeAssessmentClick,
      onPlanMyDayClick,
    } = this.props

    if (!renderButton) return null

    const PopoverComponent = (
      <Popover
        open={ isMenuOpen }
        anchorEl={ anchorElement }
        onRequestClose={ this.closeMenu }
        anchorOrigin={ anchorOrigin }
        targetOrigin={ targetOrigin }
        useLayerForClickAway={ false }
      >
        <Menu onItemTouchTap={ this.closeMenu }>
          <MenuItem
            containerElement={ <Link to="/achieve/set-a-goal" /> }
            primaryText={
              <FormattedMessage
                id="create_menu_item_label_create_goal"
                defaultMessage="Set a Goal"
              />
            } leftIcon={ <IconTarget /> } />
          <MenuItem primaryText={
            <FormattedMessage
              id="create_menu_item_label_create_mood_assessment"
              defaultMessage="Check-in Mood"
            />
          } leftIcon={ <IconMood /> } onClick={ onCreateMoodAssessmentClick } />
          <MenuItem primaryText={
            <FormattedMessage
              id="create_menu_item_label_create_life_assessment"
              defaultMessage="Make a Life Assessment"
            />
          } leftIcon={ <IconLife /> } onClick={ onCreateLifeAssessmentClick } />
          <MenuItem primaryText={
            <FormattedMessage
              id="create_menu_item_label_plan_my_day"
              defaultMessage="Plan My Day"
            />
          } leftIcon={ <IconPlan /> } onClick={ onPlanMyDayClick }  />
        </Menu>
      </Popover>
    )

    return renderButton(PopoverComponent, this.openMenu)

  }

}

export { CreateMenu }
