import React, { Component } from 'react'
import {
  FormattedMessage,
  FormattedDate,
} from 'react-intl'

import { SidebarNavItem } from '../'

import { Title } from './styles.js'

const progressLabelDefault = `{numTodoListItemsCompleted, plural,
    =0 {{numTodoListItems, plural,
      =0 {Nothing planned}
      one {One task planned}
      other {# tasks planned}
    }}
    other {{isAllTasksCompleted, select,
      true {All tasks completed!}
      other {{numTodoListItemsCompleted} of {numTodoListItems} tasks completed}
    }}
}`

class AchieveIndexSidebarNavItem extends Component {
  render() {
    const {
      selectedDate,
      numTodoListItems,
      numTodoListItemsCompleted,
      isPlanMode,
    } = this.props

    const Content = (
      <Title>
        <FormattedDate
          value={ selectedDate }
          month="long"
          weekday="long"
          day="numeric"
        />
        <small>
          <FormattedMessage
            id="achieve_sidebar_day_progress_label"
            defaultMessage={ progressLabelDefault }
            values={ {
              numTodoListItemsCompleted: isPlanMode ? 0 : numTodoListItemsCompleted,
              numTodoListItems,
              isAllTasksCompleted: numTodoListItemsCompleted === numTodoListItems,
            } }
          />
        </small>
      </Title>
    )

    return (
      <SidebarNavItem
        href="/achieve"
        title={ Content }
        isMaster={ true }
        noScrollbar={ true }
      />
    )
  }
}

export { AchieveIndexSidebarNavItem }
