import { connect } from 'react-redux'

import { UserGoalNav } from './'
import { getGoalLists } from '../../ducks/goalNav'
import { getPathname } from '../../ducks/route'

const mapStateToProps = state => ({
  goalLists: getGoalLists(state),

  // hotfix(24/8-17): react-router link + redux won't update
  // "active" state.
  pathname: getPathname(state),
})

const UserGoalNavContainer = connect(
  mapStateToProps,
)(
  class UserGoalNavContainer extends UserGoalNav {},
)

export { UserGoalNavContainer }
