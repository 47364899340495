import React, { Component } from 'react'
import IconButton from 'material-ui/IconButton'
import RaisedButton from 'material-ui/RaisedButton'
import FlatButton from 'material-ui/FlatButton'
import FontIcon from 'material-ui/FontIcon'
import TextField from 'material-ui/TextField'

import config from '../config'

import {
  Fields,
  LoginProviders,
  Subtle,
  Buttons,
} from './AuthScene.styles.js'

class SignIn extends Component {

  render() {
    const { authProviders } = config.firebase

    return (
      <form onSubmit={ this.handleFormSubmit }>

        <Fields>
          <TextField
            hintText="E-mail"
            autoFocus
            type="email"
            value={ this.props.email }
            onChange={ this.handleInputChange('email').bind(this) }
          />
          <TextField
            hintText="Password"
            type="password"
            value={ this.props.password }
            onChange={ this.handleInputChange('password').bind(this) }
          />
        </Fields>

        <Buttons>
          <FlatButton label="Forgot?" onClick={ this.handleForgotPasswordClick } />
          <RaisedButton label="Log in" primary={ true } type="submit" />
        </Buttons>

        <Subtle>Log in using:</Subtle>

        <LoginProviders>
          { authProviders.map(this.renderAuthProvider.bind(this)) }
        </LoginProviders>

      </form>
    )
  }

  renderAuthProvider(provider) {
    return (
      <li key={ provider.name }>
        <IconButton
          onClick={ () => this.handleAuthProviderClick(provider) }
          tooltip={ 'Log in using ' + provider.name }
        >
          <FontIcon color="#515151" className={ provider.icon } />
        </IconButton>
      </li>
    )
  }

  handleInputChange = prop => e => this.props.fieldChanged({
    prop,
    value: e.target.value,
  })

  handleFormSubmit = e => {
    e.preventDefault()
    const { email, password, authenticateUser } = this.props
    authenticateUser({
      provider: 'EmailAuthProvider',
      email,
      password,
    })
  }

  handleForgotPasswordClick = () => {
    const { email, onResetPassword } = this.props
    if (!email) return alert('Please enter your e-mail')
    if (onResetPassword) onResetPassword(email)
  }

  handleAuthProviderClick = ({ provider }) => this.props.authenticateUser({ provider })

}

export default SignIn

