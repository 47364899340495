import styled from 'styled-components'

export const Wrap = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 40px;
  >div:last-child {
    font-size: 12px;
    text-align: right;
  }
  table {
    border-collapse: collapse;
    td {
      padding: .3em .5em;
    }
  }
`
