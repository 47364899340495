import { combineReducers } from 'redux'
import { combineEpics } from 'redux-observable'
import { createAction, handleActions } from 'redux-actions'
import { createSelector } from 'reselect'
import { get, values } from 'lodash'
import firstBy from 'thenby'

import { LIFE_WHEEL_ID } from '../../constants'
import { FirebaseApi } from '../../api'
import { createEnsureAuthenticatedEpic } from '../../utils/epic'

/**
 * Types & Actions
 */

const MY_WHEEL_ASSESSMENTS_CHANGE = 'remente/wheelAssessment/MY_WHEEL_ASSESSMENTS_CHANGE'

export const changeMyWheelAssessments = createAction(MY_WHEEL_ASSESSMENTS_CHANGE)

/**
 * Reducers
 */

const myWheelAssessmentsByWheelIdReducer = handleActions({
  [changeMyWheelAssessments]: (state, { payload }) => payload.myWheelAssessments,
}, {})

export default combineReducers({
  myWheelAssessmentsByWheelId: myWheelAssessmentsByWheelIdReducer,
})

/**
 * Selectors
 */

const myWheelAssessmentsByWheelIdSelector = ({ wheelAssessment }) => wheelAssessment.myWheelAssessmentsByWheelId

export const getMyWheelAssessmentsByWheelId = createSelector(
  myWheelAssessmentsByWheelIdSelector,
  myWheelAssessmentsByWheelId => myWheelAssessmentsByWheelId,
)

export const getMyLifeWheelAssessmentsById = createSelector(
  getMyWheelAssessmentsByWheelId,
  assessmentsByWheelId => get(assessmentsByWheelId, LIFE_WHEEL_ID),
)

export const getMyLifeWheelAssessments = createSelector(
  getMyLifeWheelAssessmentsById,
  assessmentsById => values(assessmentsById)
    .sort(firstBy(({ createdAt }) => createdAt)),
)

export const getNumMyLifeWheelAssessments = createSelector(
  getMyLifeWheelAssessments,
  assessments => assessments.length,
)

/**
 * Epics
 */

const myWheelAssessmentsEpic = createEnsureAuthenticatedEpic({
  createObservables: ({ uid }) => ({
    myWheelAssessments: FirebaseApi.observableRef(`wheel-assessments/${ uid }`),
  }),
  actionFulfilled: changeMyWheelAssessments,
})

export const wheelAssessmentEpics = combineEpics(
  myWheelAssessmentsEpic,
)
