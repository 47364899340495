import { connect } from 'react-redux'
import { SidebarNavItem } from './'
import { getOriginGoalTitle } from '../../ducks/organization'

const mapStateToProps = (state, { originGoalId }) => ({
  originGoalTitle: getOriginGoalTitle(state, originGoalId),
})

const SidebarNavItemContainer = connect(
  mapStateToProps,
)(
  class SidebarNavItemContainer extends SidebarNavItem {},
)

export { SidebarNavItemContainer }
