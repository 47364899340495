import React from 'react'
import { get } from 'lodash'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'

import {
  WeekdayPicker,
  TimePicker,
} from '../'

const getTimeStr = ({ hour, minute }) => moment({ hour, minute }).format('HH:mm')

const ReminderRecurring = ({
  time,
  days,
  onChange,
}) => {
  const handleTimeChange = ({ hour, minute }) => {
    onChange({
      time: {
        hour,
        minute,
      },
      days,
    })
  }

  const handleWeekdayToggle = ({ weekdayId }) => {
    const newDays = {
      ...days,
      [weekdayId]: !get(days, weekdayId, false),
    }
    onChange({
      time,
      days: newDays,
    })
  }

  const timeStr = getTimeStr(time)

  return (
    <div>

      <TimePicker
        isUtc={ true }
        value={ timeStr }
        onChange={ handleTimeChange }
      />

      <FormattedMessage
        id="edit_goal_step_input_reminders_recurring_at_separator"
        defaultMessage="on selected days each week"
      />

      <WeekdayPicker
        selectedDays={ days }
        onWeekdayToggle={ handleWeekdayToggle }
      />

    </div>
  )
}

export { ReminderRecurring }
