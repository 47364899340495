import styled from 'styled-components'

export const KeyBinding = styled.div`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 9px;
  font-weight: 600;
`

export const Key = styled.div`
  padding: .5em .8em;
  margin: 0 .5em;
  border-radius: 4px;
  color: ${ ({ theme }) => theme.colorPrimary1 };
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.2);
  &:last-child {
    margin-right: 0;
  }
  &:first-child {
    margin-left: 0;
  }
`

export const KeySeparator = styled.div`
  color: #777;
`
