import { connect } from 'react-redux'

import { EditGoalTaskModal } from './'

import { closeModal } from '../../ducks/app'

import { getDetailsGoalTitle } from '../../ducks/goalDetails'

import {
  getTaskBeforeEdit,
  updateGoalTask,
  deleteGoalTask,
} from '../../ducks/editGoalTask'

const mapStateToProps = state => ({
  task: getTaskBeforeEdit(state),
  goalTitle: getDetailsGoalTitle(state),
})

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(closeModal()),
  onCancel: () => dispatch(closeModal()),
  onDone: task => dispatch(updateGoalTask(task)),
  onDelete: () => dispatch(deleteGoalTask()),
})

class EditGoalTaskModalContainerClass extends EditGoalTaskModal {}

const EditGoalTaskModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditGoalTaskModalContainerClass)

export { EditGoalTaskModalContainer }
