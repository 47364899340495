import React, { Component } from 'react'
import { withTheme } from 'styled-components'
import CircularProgress from 'material-ui/CircularProgress'

import {
  Wrap,
  Content,
  Hero,
} from './styles'

class CoverPhotoHeaderClass extends Component {

  render() {
    const { children, image, loading, theme } = this.props
    const { loadingFg } = theme.CoverPhotoHeader.c
    var photoStyle

    if (image)
      photoStyle = {
        backgroundImage: `url(${ image })`,
      }

    return (
      <Wrap loading={ loading }>
        <Content>
          { loading ? <CircularProgress size={ 60 } thickness={ 3 } color={ loadingFg } /> : children }
        </Content>
        <Hero style={ photoStyle } />
      </Wrap>
    )
  }

}

const CoverPhotoHeader = withTheme(CoverPhotoHeaderClass)

export { CoverPhotoHeader }
