import styled, { css } from 'styled-components'
import { truncate } from '../../utils/css'

export const Title = styled.div`
  flex: 1;
  line-height: 1.4em;
`

export const Subtitle = styled.div`
  ${ truncate(0) }
  text-align: right;
  font-size: .8em;
  margin-top: .2em;
  display: flex;
  align-items: center;
  span {
    opacity: .5;
  }
  button {
    margin-right: .5em;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 0;
`

export const Toolbar = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 4px;
  bottom: 4px;
  padding: 0 23px 0 13px;
  transition: all 150ms ease-out;
  transform: translateX(10%);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition-duration: 150ms;
  background: #fff;
  ${ ({ isVisible }) => isVisible && `
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  ` }
`

export const Wrap = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 3px 30px;
  background: ${ ({ theme }) => theme.colorBodyBg };
  border: 1px solid #fff;
  border-style: solid none;
  border-bottom-color: #f4f4f6;
  min-height: 45px;
  &:hover {
    z-index: 3;
  }
  &:last-child {
    border-bottom-color: #fff;
    &:hover {
      z-index: 12;
    }
  }

  ${ ({ isPlanMode }) => isPlanMode && `
    &:hover {
      z-index: 5;
    }
    &:last-child {
      &:hover {
        z-index: 12;
      }
    }
  ` }

  ${ ({ isCompleted }) => isCompleted ? `
    box-shadow: none;
    ${ Title } {
      text-decoration: line-through;
      color: #ccc;
    }
  ` : '' }

`

const IconWrap = css`
  display: flex;
  align-items: center;
  width: 40px;
  transition: all 150ms ease-out;
`

export const CheckboxWrap = styled.div`
  ${ IconWrap }
`
