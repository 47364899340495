import styled from 'styled-components'

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
`

export const Body = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  background: ${ ({ theme }) => theme.colorMinibarBg };
`

export const Main = styled.div`
  display: flex;
  flex: 1;
  background: ${ ({ theme }) => theme.colorPrimaryContentBg };
  z-index: 1;
`
