import { connect } from 'react-redux'
import RestrictedContentInfo from './RestrictedContentInfo.js'
import { showPaywall } from '../ducks/app'

const mapDispatchToProps = dispatch => ({
  onShowPaywall: () => dispatch(showPaywall()),
})

const RestrictedContentInfoContainer = connect(
  null,
  mapDispatchToProps,
)(
  class RestrictedContentInfoContainer extends RestrictedContentInfo {},
)

export default RestrictedContentInfoContainer
