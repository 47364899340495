import React, { Component } from 'react'
import styled from 'styled-components'

import {
  BrowserRouter as Router,
  Redirect,
} from 'react-router-dom'

import {
  IntlProvider,
  addLocaleData,
} from 'react-intl'
import en from 'react-intl/locale-data/en'
import sv from 'react-intl/locale-data/sv'
import de from 'react-intl/locale-data/de'
import 'moment/locale/sv'

import { getTranslations } from './locales'

import './app.css'

import AuthRoutes from './components/AuthRoutes.js'

import {
  RouterRedux,
  AppLoading,
  ModalSwitchContainer as ModalSwitch,
  CoverSwitchContainer as CoverSwitch,
  UpdateAvailableBannerContainer as UpdateAvailableBanner,
} from './components'

addLocaleData([...en, ...sv, ...de])

const Body = styled(RouterRedux)`
  ${ ({ theme }) => `
    color: ${ theme.colorBodyFg };
    background: ${ theme.colorBodyBg };
  ` }
`

class App extends Component {

  render() {
    const { hasAuthState, language, isAuthenticated, redirectTo } = this.props

    const DoRedirect = redirectTo && (
      <Redirect push to={ { pathname: redirectTo } } />
    )

    const intlMessages = getTranslations(language)

    const MainRoutes = (
      <Router>
        <Body>

          { DoRedirect }

          <UpdateAvailableBanner />

          <AuthRoutes isAuthenticated={ isAuthenticated } />

          <ModalSwitch />

          <CoverSwitch />

        </Body>
      </Router>
    )

    return (
      <IntlProvider
        key={ language }
        locale={ language }
        messages={ intlMessages }
      >
        { hasAuthState ? MainRoutes : <AppLoading /> }
      </IntlProvider>
    )

  }

}

export default App
