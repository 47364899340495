import { combineReducers } from 'redux'
import { combineEpics, ofType } from 'redux-observable'
import { createAction, handleActions } from 'redux-actions'
import { createSelector } from 'reselect'
import { interval } from 'rxjs'
import { map, filter, switchMap } from 'rxjs/operators'
import moment from 'moment'

import {
  focusApp,
  blurApp,
} from '../app'

const INITAL_STATE = {
  currentTime: moment(),
  currentTimeMinute: moment().startOf('minute'),
}

const HEARTBEAT_START       = 'remente/timing/HEARTBEAT_START'
const HEARTBEAT_TICK        = 'remente/timing/HEARTBEAT_TICK'
const HEARTBEAT_MINUTE_TICK = 'remente/timing/HEARTBEAT_MINUTE_TICK'

export const startHeartbeat      = createAction(HEARTBEAT_START)
export const tickHeartbeat       = createAction(HEARTBEAT_TICK)
export const tickMinuteHeartbeat = createAction(HEARTBEAT_MINUTE_TICK)

/**
 * Reducers
 */

const currentTimeReducer = handleActions({
  [tickHeartbeat().type]: () => moment(),
}, INITAL_STATE.currentTime)

const currentTimeMinuteReducer = handleActions({
  [tickMinuteHeartbeat().type]: () => moment().startOf('minute'),
}, INITAL_STATE.currentTimeMinute)

export default combineReducers({
  currentTime: currentTimeReducer,
  currentTimeMinute: currentTimeMinuteReducer,
})

/**
 * Selectors
 */

const currentTimeSelector = ({ timing }) => timing.currentTime
const currentTimeMinuteSelector = ({ timing }) => timing.currentTimeMinute

export const getCurrentTime = createSelector(
  currentTimeSelector,
  time => time,
)

export const getCurrentTimeMinute = createSelector(
  currentTimeMinuteSelector,
  time => time,
)

/**
 * Epics
 */

const startHeartbeatEpic = action$ => action$.pipe(
  ofType(HEARTBEAT_START),
  switchMap(() => interval(1000).pipe(
    filter(() => new Date().getSeconds() === 0),
    map(() => tickMinuteHeartbeat()),
  )),
)

const focusAppEpic = action$ => action$.pipe(
  ofType(
    focusApp().type,
    blurApp().type,
  ),
  map(() => tickMinuteHeartbeat()),
)

export const timingEpics = combineEpics(
  startHeartbeatEpic,
  focusAppEpic,
)
