import { connect } from 'react-redux'

import {
  ComponentSwitch,
  EditGoalTaskModalContainer as EditGoalTaskModal,
  EditJourneyEntryModalContainer as EditJourneyEntryModal,
  GoalTemplateDetailsModalContainer as GoalTemplateDetailsModal,
  EditMoodAssessmentModalContainer as EditMoodAssessmentModal,
} from '../'

import PaywallModal from '../PaywallModalContainer.js'

import WelcomeModal from '../WelcomeModalContainer.js'

const components = {
  'goal/task/edit': EditGoalTaskModal,
  'goal/journey-entry/edit': EditJourneyEntryModal,
  'goal-template/details': GoalTemplateDetailsModal,
  'mood-assessment/edit': EditMoodAssessmentModal,
  'paywall': PaywallModal,
  'welcome': WelcomeModal,
}

const mapStateToProps = ({ app }) => ({
  components,
  selectedComponent: app.currentModal,
})

class ModalSwitchContainerClass extends ComponentSwitch {}

const ModalSwitchContainer = connect(
  mapStateToProps,
)(ModalSwitchContainerClass)

export { ModalSwitchContainer }
