import { connect } from 'react-redux'

import { AchieveIndexSidebarNavItem } from './'

import {
  getSelectedDate,
  getNumTodoListItems,
  getNumTodoListItemsCompleted,
  getIsPlanMode,
} from '../../ducks/dayplanner'

import {
  getPathname,
} from '../../ducks/route'

const mapStateToProps = state => ({
  selectedDate: getSelectedDate(state),
  numTodoListItems: getNumTodoListItems(state),
  numTodoListItemsCompleted: getNumTodoListItemsCompleted(state),
  isPlanMode: getIsPlanMode(state),

  // hotfix(24/8-17): react-router link + redux won't update
  // "active" state.
  pathname: getPathname(state),
})

class AchieveIndexSidebarNavItemContainerClass extends AchieveIndexSidebarNavItem {}

const AchieveIndexSidebarNavItemContainer = connect(
  mapStateToProps,
)(AchieveIndexSidebarNavItemContainerClass)

export { AchieveIndexSidebarNavItemContainer }
