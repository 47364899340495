import { createAction } from 'redux-actions'
import { combineEpics, ofType } from 'redux-observable'
import { EMPTY } from 'rxjs'
import { map, mergeMap, catchError } from 'rxjs/operators'
import { eventMetrics } from '../../metrics.js'

export const trackEvent = createAction('remente/metric/EVENT_TRACK')
export const noop = createAction('remente/metric/NOOP')

const eventTrackers = [

  {
    name: 'Google Tag Manager',
    trackEvent: ({ name, params }) => window.dataLayer && window.dataLayer.push({
      event: 'generic_event',
      eventName: name,
      params,
    }),
  },

]

/**
 * Epics
 */

const trackEventEpic = action$ => action$.pipe(
  ofType(trackEvent().type),
  map(({ payload }) => payload),
  mergeMap(event => {
    eventTrackers.forEach(
      tracker => tracker.trackEvent && tracker.trackEvent(event),
    )
    return EMPTY
  }),
)

const trackEventMetricEpic = action$ => action$.pipe(
  ofType.apply(action$, Object.keys(eventMetrics)),
  map(({ type, payload }) => {
    let eventMetric = eventMetrics[type]

    if (typeof eventMetric === 'function') {
      eventMetric = eventMetric({ type, payload })
      if (!eventMetric) return noop()
    }

    if (typeof eventMetric === 'string')
      return trackEvent({ name: eventMetric })

    if (eventMetric.name)
      return trackEvent(eventMetric)

    const { event, transformParams } = eventMetric
    const params = applyTransformParams(transformParams, payload)
    return trackEvent({ name: event, params })
  }),
  catchError(err => {
    console.error('error in trackEventMetricEpic', err)
    return EMPTY
  }),
)

function applyTransformParams(transformParams, payload) {
  if (!transformParams) return
  return transformParams(payload)
}

export const metricEpics = combineEpics(
  trackEventEpic,
  trackEventMetricEpic,
)
