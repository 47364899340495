import React, { Component } from 'react'

import { WheelSlice } from '../'

const
  width = 100,
  height = 100,
  svgStyle = { width: '100%' },
  viewBox = `0 0 ${ width } ${ height }`

class WheelChart extends Component {

  handleArcClick = e => {
    const { onArcClick } = this.props
    onArcClick && onArcClick(e)
  }

  handleArcMouseEnter = e => {
    const { onArcMouseEnter } = this.props
    onArcMouseEnter && onArcMouseEnter(e)
  }

  handleArcMouseLeave = e => {
    const { onArcMouseLeave } = this.props
    onArcMouseLeave && onArcMouseLeave(e)
  }

  render() {
    const
      { slices, valueMax = 10, onClick, children } = this.props,

      x = width / 2,
      y = height / 2,

      innerRadius = 0.15 * x,
      outerRadius = 0.7 * x,
      arcWidth = (outerRadius - innerRadius) / valueMax,
      arcDegStepSize = 360 / slices.length,
      arcMargin = .4,
      sliceMargin = .4,
      wheelRadius = outerRadius + arcWidth,

      Slices = slices.map((props, sliceIndex) => {
        const
          startDeg = -90 + sliceIndex * arcDegStepSize,
          finishDeg = startDeg + arcDegStepSize,
          sliceProps = {
            ...props,
            x, y, valueMax, innerRadius, arcWidth,
            startDeg, finishDeg, arcMargin, sliceMargin,
            wheelRadius,
            arcDegStepSize,
            onArcClick: arcIndex => this.handleArcClick({ sliceIndex, arcIndex }),
            onArcMouseEnter: arcIndex => this.handleArcMouseEnter({ sliceIndex, arcIndex }),
            onArcMouseLeave: arcIndex => this.handleArcMouseLeave({ sliceIndex, arcIndex }),
          }
        return (
          <WheelSlice
            key={ sliceIndex }
            { ...sliceProps }
          />
        )
      })

    return (
      <div onClick={ onClick }>
        <svg
          style={ svgStyle }
          viewBox={ viewBox }
        >
          { Slices }
        </svg>
        { children }
      </div>
    )
  }

}

export { WheelChart }
