import React, { Component } from 'react'
import moment from 'moment'
import $s from 'dot-prop-immutable'
import {
  get,
  values,
} from 'lodash'
import {
  defineMessages,
  injectIntl,
  FormattedMessage,
} from 'react-intl'

import TextField from 'material-ui/TextField'
import RaisedButton from 'material-ui/RaisedButton'
import FlatButton from 'material-ui/FlatButton'
import Toggle from 'material-ui/Toggle'

import IconTitle from 'material-ui/svg-icons/action/done'
import IconRepeat from 'material-ui/svg-icons/av/repeat'
import IconDates from 'material-ui/svg-icons/action/event'
import IconDayplanner from 'material-ui/svg-icons/action/assignment'
import IconReminders from 'material-ui/svg-icons/action/alarm'
import IconAssign from 'material-ui/svg-icons/social/group'
import IconNotes from 'material-ui/svg-icons/navigation/menu'
import IconTrash from 'material-ui/svg-icons/action/delete'

import AssignGoalTaskInput from '../AssignGoalTaskInputContainer.js'

import {
  Modal,
  ModalPadding,
  ModalHeader,
  ModalTitle,
  ModalSubtle,
  ModalFooter,
  WeekdayPicker,
  DayPicker,
  DateRangePicker,
  ReminderList,
  TargetPicker,
} from '../'

import MarkdownEditor from '../MarkdownEditor.loadable.js'

import { getTaskType } from '../../utils/goalTask'
import { FirebaseApi } from '../../api'

import {
  FormSection,
  FormSectionContent,
  InputHeader,
  InputHeaderContent,
} from '../InputHeader/InputHeader.js'

const isTaskRepeating = task => getTaskType(task) === 'repeating'

const DEFAULT_TARGET = {
  frequency: 3,
  unit: 'weekly',
}

const DEFAULT_AUTOPLAN = {
  isDisabled: false,
}

const validateAutoplan = task => {
  const { startDate, target } = task
  const autoplan = (!target && !startDate) ? null : task.autoplan
  return {
    ...task,
    autoplan,
  }
}

const translations = defineMessages({
  inputTitleLabel: {
    id: 'edit_goal_step_input_title_label',
    defaultMessage: 'Step to get you closer to the goal',
  },
  inputTitleHint: {
    id: 'edit_goal_step_input_title_hint',
    defaultMessage: 'Eg. "Buy running shoes" or "Go for a run"',
  },
  inputRepeatLabel: {
    id: 'edit_goal_step_input_repeat_label',
    defaultMessage: 'Take this step multiple times',
  },
  inputDatesLabel: {
    id: 'edit_goal_step_input_dates_label',
    defaultMessage: 'When to take this step',
  },
  inputAutoplanLabel: {
    id: 'edit_goal_step_input_autoplan_label',
    defaultMessage: 'Autoschedule in dayplanner',
  },
  inputAutoplanHintNoPlan: {
    id: 'edit_goal_step_input_autoplan_hint_no_plan',
    defaultMessage: 'This step will not show up in your dayplanner automatically.',
  },
  inputRemindersLabel: {
    id: 'edit_goal_step_input_reminders_label',
    defaultMessage: 'Reminders',
  },
  inputNotesLabel: {
    id: 'edit_goal_step_input_notes_label',
    defaultMessage: 'Notes',
  },
  inputNotesPlaceholder: {
    id: 'edit_goal_step_input_notes_placeholder',
    defaultMessage: 'How can you make this step more tangible for yourself?',
  },
  inputAssignLabel: {
    id: 'edit_goal_step_input_assign_label',
    defaultMessage: 'Assign task',
  },
  btnDeleteStep: {
    id: 'edit_goal_step_btn_delete_step',
    defaultMessage: 'Delete Step',
  },
  btnCancel: {
    id: 'edit_goal_step_btn_cancel',
    defaultMessage: 'Cancel',
  },
  btnDone: {
    id: 'edit_goal_step_btn_done',
    defaultMessage: 'Done',
  },
})

class EditGoalTaskModalClass extends Component {

  componentWillMount() {
    const { task } = this.props
    const autoplan = task.autoplan || DEFAULT_AUTOPLAN
    const assignedUsers = Object.keys(task.linkedTo || {}).map(value => ({ value }))
    this.setState({
      ...task,
      autoplan,
      assignedUsers,
    })
  }

  handleTitleChange = title => {
    this.setState({
      ...this.state,
      title,
    })
  }

  handleRepeatToggleChange = () => {
    const isRepeating = isTaskRepeating(this.state)
    const target = isRepeating ? undefined : DEFAULT_TARGET
    const type = target ? 'repeating' : 'single'
    let newState = $s.set(this.state, 'target', target)
    newState = $s.set(newState, 'type', type)
    this.setState(newState)
  }

  handleRepeatFrequencyChange = frequency => {
    this.setState($s.set(this.state, 'target.frequency', frequency))
  }

  handleStartDateChange = date => {
    const { endDate } = this.state
    const startDate = date ? date.valueOf() : undefined
    const newState = {
      ...this.state,
      startDate,
    }

    if (startDate && endDate && startDate > endDate)
      newState.endDate = startDate

    this.setState(newState)
  }

  handleEndDateChange = date => {
    const { startDate } = this.state
    const endDate = date ? date.valueOf() : undefined
    const newState = {
      ...this.state,
      endDate,
    }

    if (startDate && endDate && endDate < startDate)
      newState.startDate = endDate

    this.setState(newState)
  }

  handleAutoplanToggleChange = () => {
    const { autoplan } = this.state
    this.setState($s.set(this.state, 'autoplan.isDisabled',
      it => autoplan ? !it : true,
    ))
  }

  handleDayplannerWeekdayToggle = ({ weekdayId }) => {
    this.setState($s.toggle(this.state, `autoplan.days.${ weekdayId }`))
  }

  handleAddSingleReminder = () => {
    const id = FirebaseApi.newId()
    const path = `reminders.single.${ id }`
    const reminder = {
      id,
      notifyAt: moment().valueOf(),
    }
    this.setState($s.set(this.state, path, reminder))
  }

  handleAddRecurringReminder = () => {
    const id = FirebaseApi.newId()
    const path = `reminders.recurring.${ id }`
    const now = moment.utc()
    const reminder = {
      id,
      days: {},
      time: {
        hour: now.hour(),
        minute: now.minute(),
      },
    }
    this.setState($s.set(this.state, path, reminder))
  }

  handleSingleReminderChange = ({ id, notifyAt }) => {
    const path = `reminders.single.${ id }.notifyAt`
    this.setState($s.set(this.state, path, notifyAt))
  }

  handleRecurringReminderChange = ({ id, time, days }) => {
    const path = `reminders.recurring.${ id }`
    this.setState($s.set(this.state, path, {
      id,
      time,
      days,
    }))
  }

  handleDeleteReminder = (id) => {
    const single = `reminders.single.${ id }`
    const recurring = `reminders.recurring.${ id }`
    const newState = $s.delete($s.delete(this.state, single), recurring)
    this.setState(newState)
  }

  handleNotesChange = notes => {
    this.setState($s.set(this.state, 'description', notes))
  }

  handleAssignChange = assignedUsers => {
    this.setState({
      ...this.state,
      assignedUsers,
    })
  }

  handleDone = () => {
    const { onDone } = this.props
    const task = {
      ...this.state,
      assignedUsers: this.state.assignedUsers.map(({ value }) => value),
    }
    const cleanTask = validateAutoplan(JSON.parse(JSON.stringify(task)))
    onDone(cleanTask)
  }

  render() {
    const { task, goalTitle, onClose, onCancel, onDelete, intl } = this.props
    const { formatMessage } = intl
    const { title, target, startDate, endDate, description, reminders, assignedUsers } = this.state

    const startDateMoment = startDate && moment(startDate)
    const endDateMoment = endDate && moment(endDate)

    const isRepeating = isTaskRepeating(this.state)

    const isAutoplanPossible = isRepeating || (!isRepeating && startDate)
    const isAutoplanDisabled = get(this.state, 'autoplan.isDisabled', false)
    const autoplanDays = get(this.state, 'autoplan.days', {})

    const singleReminders = values(get(reminders, 'single'))
    const recurringReminders = values(get(reminders, 'recurring'))

    const isTaskValid = title.length > 0

    const TaskDetails = assignedUsers.length === 0 && (
      <div>
        <FormSection>
          <InputHeader
            icon={ <IconRepeat /> }
          >
            <InputHeaderContent>
              <Toggle
                label={ formatMessage(translations.inputRepeatLabel) }
                toggled={ isRepeating }
                onToggle={ this.handleRepeatToggleChange }
              />
            </InputHeaderContent>
          </InputHeader>
          <TargetPicker
            target={ target }
            onFrequencyChange={ this.handleRepeatFrequencyChange }
            isRepeating={ isRepeating }
          />
        </FormSection>

        <FormSection>
          <InputHeader
            icon={ <IconDates /> }
          >
            <InputHeaderContent>
              { formatMessage(translations.inputDatesLabel) }
            </InputHeaderContent>
          </InputHeader>

          <FormSectionContent>
            {
              isRepeating ? (
                <DateRangePicker
                  startDate={ startDateMoment }
                  endDate={ endDateMoment }
                  onStartChange={ this.handleStartDateChange }
                  onEndChange={ this.handleEndDateChange }
                />
              ) : (
                <DayPicker
                  id="goaltask-startDate"
                  value={ startDateMoment }
                  onChange={ this.handleStartDateChange }
                />
              )
            }
          </FormSectionContent>

        </FormSection>

        {
          isAutoplanPossible && (
            <FormSection>
              <InputHeader
                icon={ <IconDayplanner /> }
              >
                <InputHeaderContent>
                  <Toggle
                    label={ formatMessage(translations.inputAutoplanLabel) }
                    toggled={ !isAutoplanDisabled }
                    onToggle={ this.handleAutoplanToggleChange }
                  />
                </InputHeaderContent>
              </InputHeader>

              {
                isAutoplanDisabled ? (
                  <p>{ formatMessage(translations.inputAutoplanHintNoPlan) }</p>
                ) : isRepeating ? (
                  <WeekdayPicker
                    onWeekdayToggle={ this.handleDayplannerWeekdayToggle }
                    selectedDays={ autoplanDays }
                  />
                ) : (
                  <p>
                    <FormattedMessage
                      id="edit_goal_step_input_autoplan_hint_appears_once"
                      defaultMessage="This step will show up on {startDate, date, long}."
                      values={ { startDate } }
                    />
                  </p>
                )
              }

            </FormSection>
          )
        }

        <FormSection>
          <InputHeader
            icon={ <IconReminders /> }
          >
            <InputHeaderContent>
              { formatMessage(translations.inputRemindersLabel) }
            </InputHeaderContent>
          </InputHeader>
          <ReminderList
            singleReminders={ singleReminders }
            recurringReminders={ recurringReminders }
            onAddSingleReminder={ this.handleAddSingleReminder }
            onAddRecurringReminder={ this.handleAddRecurringReminder }
            onSingleReminderChange={ this.handleSingleReminderChange }
            onRecurringReminderChange={ this.handleRecurringReminderChange }
            onDeleteReminder={ this.handleDeleteReminder }
          />
        </FormSection>
      </div>
    )

    return (
      <Modal onClose={ onClose }>
        <ModalPadding>
          <ModalHeader>
            <ModalTitle>
              <FormattedMessage
                id="edit_goal_task_modal_title"
                defaultMessage="Edit Goal Task"
              />
            </ModalTitle>
            <ModalSubtle>
              { goalTitle }
            </ModalSubtle>
          </ModalHeader>
        </ModalPadding>

        <div>

          <FormSection>
            <InputHeader icon={ <IconTitle /> }>
              <InputHeaderContent>
                { formatMessage(translations.inputTitleLabel) }
              </InputHeaderContent>
            </InputHeader>
            <TextField
              fullWidth={ true }
              hintText={ formatMessage(translations.inputTitleHint) }
              underlineShow={ false }
              value={ title }
              onChange={ e => this.handleTitleChange(e.target.value) }
            />
          </FormSection>

          <FormSection>
            <InputHeader
              icon={ <IconNotes /> }
            >
              <InputHeaderContent>
                { formatMessage(translations.inputNotesLabel) }
              </InputHeaderContent>
            </InputHeader>
            <MarkdownEditor
              placeholder={ formatMessage(translations.inputNotesPlaceholder) }
              value={ description }
              onChange={ this.handleNotesChange }
            />
          </FormSection>

          <FormSection>
            <InputHeader
              icon={ <IconAssign /> }
            >
              <InputHeaderContent>
                { formatMessage(translations.inputAssignLabel) }
              </InputHeaderContent>
            </InputHeader>
            <AssignGoalTaskInput
              taskId={ task.id }
              onChange={ this.handleAssignChange }
              value={ assignedUsers }
            />
          </FormSection>

          { TaskDetails }

        </div>

        <ModalPadding>
          <ModalFooter>

            <FlatButton
              label={ formatMessage(translations.btnDeleteStep) }
              secondary={ true }
              icon={ <IconTrash /> }
              onClick={ onDelete }
            />

            <div>
              <FlatButton
                label={ formatMessage(translations.btnCancel) }
                onClick={ onCancel }
              />

              <RaisedButton
                label={ formatMessage(translations.btnDone) }
                primary={ true }
                disabled={ !isTaskValid }
                onClick={ this.handleDone }
              />
            </div>
          </ModalFooter>
        </ModalPadding>

      </Modal>
    )
  }
}

const EditGoalTaskModal = injectIntl(EditGoalTaskModalClass)

export { EditGoalTaskModal }
