import React, { Component } from 'react'
import { SortableHandle } from 'react-sortable-hoc'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'
import Checkbox from 'material-ui/Checkbox'
import DatePicker from 'material-ui/DatePicker'
import IconButton from 'material-ui/IconButton'
import IconDragHandle from 'material-ui/svg-icons/editor/drag-handle'
import IconJournal from 'material-ui/svg-icons/editor/insert-comment'
import IconPostpone from 'material-ui/svg-icons/action/update'
import IconTrash from 'material-ui/svg-icons/action/delete'
import IconUnchecked from 'material-ui/svg-icons/toggle/radio-button-unchecked'
import IconChecked from 'material-ui/svg-icons/action/check-circle'

import {
  Wrap,
  CheckboxWrap,
  Content,
  Toolbar,
  Title,
  Subtitle,
} from './styles'

const DragHandle = SortableHandle(() => <IconDragHandle />)

class TodoTask extends Component {

  render() {

    const focusModeButtons = []
    const planModeButtons = []
    const {
      id,
      title,
      subtitle,
      goalId,
      listIndex,
      isCompleted,
      isPlanMode,
      isCheckinDisabled,
      onCompletedToggle,
      onDeleteClick,
      onJourneyEntryEditClick,
    } = this.props

    if (goalId && isCompleted)
      focusModeButtons.push(
        <IconButton
          onClick={ onJourneyEntryEditClick }
          tooltip={
            <FormattedMessage
              id="todo_task_edit_journey_entry"
              defaultMessage="Journal"
            />
          }
          key="journal"
        >
          <IconJournal />
        </IconButton>,
      )

    if (!goalId)
      planModeButtons.push(
        <IconButton
          onClick={ onDeleteClick }
          tooltip={
            <FormattedMessage
              id="todo_task_delete"
              defaultMessage="Delete"
            />
          }
          key="delete"
        >
          <IconTrash />
        </IconButton>,
      )

    planModeButtons.push(
      <IconButton
        onClick={ this.handlePostponeClick }
        tooltip={
          <FormattedMessage
            id="todo_task_postpone"
            defaultMessage="Postpone"
          />
        }
        key="postpone"
      >
        <IconPostpone />
      </IconButton>,
    )

    const PlanModeToolbar = planModeButtons.length > 0 && (
      <Toolbar listIndex={ listIndex } isVisible={ isPlanMode }>
        { planModeButtons.map(button => button) }
      </Toolbar>
    )

    const FocusModeToolbar = focusModeButtons.length > 0 && (
      <Toolbar listIndex={ listIndex } isVisible={ !isPlanMode }>
        { focusModeButtons.map(button => button) }
      </Toolbar>
    )

    const renderDragHandle = () => <DragHandle />

    const handleOnCheck = () => {
      if ('activeElement' in document)
        document.activeElement.blur()
      onCompletedToggle()
    }

    const renderCheckbox = () => (
      <Checkbox
        checked={ isCompleted }
        disabled={ isPlanMode || isCheckinDisabled }
        onCheck={ handleOnCheck }
        checkedIcon={ <IconChecked /> }
        uncheckedIcon={ <IconUnchecked /> }
      />
    )

    const TaskSubtitle = subtitle && (
      <Subtitle>
        <span>
          { subtitle }
        </span>
      </Subtitle>
    )

    const toolbarSize = isPlanMode ?
      planModeButtons.length :
      focusModeButtons.length

    const tomorrow = moment().add(1, 'day').toDate()
    const datePickerId = `todotask-datepicker-${ id }`

    const PostponeDatePicker = isPlanMode && !goalId && (
      <DatePicker
        id={ datePickerId }
        style={ { display: 'none' } }
        mode="landscape"
        ref={ datePicker => this.datePicker = datePicker }
        onChange={ this.handlePostponeDateChanged }
        minDate={ tomorrow }
        value={ tomorrow }
      />
    )

    return (
      <Wrap
        isCompleted={ isCompleted }
        isPlanMode={ isPlanMode }
        toolbarSize={ toolbarSize }
      >

        { PostponeDatePicker }

        <CheckboxWrap>
          { isPlanMode ? renderDragHandle() : renderCheckbox() }
        </CheckboxWrap>

        <Content>

          <Title>
            { title }
          </Title>

        </Content>

        { TaskSubtitle }

        { PlanModeToolbar }

        { FocusModeToolbar }

      </Wrap>
    )

  }

  handlePostponeClick = () => {
    const { goalId, onPostpone } = this.props
    if (!goalId)
      return this.datePicker.openDialog()
    onPostpone()
  }

  handlePostponeDateChanged = (e, date) => {
    const { onPostpone } = this.props
    onPostpone(date)
  }

}

export { TodoTask }
