import { css } from 'styled-components'

export const flexCenter = css`
  display: flex;
  align-items: center;
`

export const truncate = width => `
  ${ width ? `width: ${ width }` : '' }
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const scrollbarThemes = {
  light: {
    track: 'rgb(199, 241, 229)',
    thumb: '#fff',
    thumbHover: '#fff',
    thumbActive: '#fff',
  },
  elevate: {
    track: '#7ADAC3',
    thumb: '#fff',
    thumbHover: '#fff',
    thumbActive: '#fff',
  },
  main: {
    track: '#7ADAC3',
    thumb: '#fff',
    thumbHover: '#fff',
    thumbActive: '#fff',
  },
}

export const createScrollbar = props => {
  props = props || {}
  const isMaster = props.isMaster
  const theme = props.theme || 'light'
  const { track, thumb, thumbHover, thumbActive } = scrollbarThemes[theme]

  // // no scrollbars for Mac users
  // if (window.navigator) {
  //   const userAgent = navigator.userAgent || ''
  //   if (userAgent.indexOf('Mac OS X') !== -1) return
  // }

  return `
    overflow-y: scroll;
    ::-webkit-scrollbar {
      width: 8px;
      background: ${ track };
      ${ isMaster ? `
        width: 7px;
      ` : '' }
    }
    ::-webkit-scrollbar-track { }
    ::-webkit-scrollbar-thumb {
      border: none;
      background: ${ thumb };
      background-clip: padding-box;
      border: 2px solid ${ track };
      border-style: none solid;
      ${ isMaster ? `
        border-right-style: none;
      ` : '' }
      border-radius: 0;
      &:hover {
        border-width: 1px;
        background: ${ thumbHover };
      }
      &:active {
        border-width: 2px;
        background: ${ thumbActive };
      }
    }
  `
}
