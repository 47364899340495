import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import Menu from 'material-ui/Menu'
import MenuItem from 'material-ui/MenuItem'
import Popover from 'material-ui/Popover'
import IconButton from 'material-ui/IconButton'
import Divider from 'material-ui/Divider'
import IconProfile from 'material-ui/svg-icons/social/person'
import IconLogout from 'material-ui/svg-icons/action/power-settings-new'
import IconHelp from 'material-ui/svg-icons/action/help-outline'
import IconSettings from 'material-ui/svg-icons/action/settings'
import { UserThumbnail } from './styles.js'

const anchorOrigin = { horizontal: 'middle', vertical: 'center' }
const targetOrigin = { horizontal: 'left', vertical: 'bottom' }

class ProfileMenu extends Component {

  state = {
    isMenuOpen: false,
    anchorElement: null,
  }

  closeMenu() {
    this.setState({
      isMenuOpen: false,
    })
  }

  openMenu = e => {
    e.preventDefault()
    this.setState({
      isMenuOpen: true,
      anchorElement: e.target,
    })
  }

  renderOrganization = ({ id, name }) => {
    const { activeOrganizationId, onOrganizationSelect } = this.props
    return (
      <MenuItem
        key={ id }
        checked={ activeOrganizationId === id }
        insetChildren={ true }
        primaryText={ name }
        onClick={ () => onOrganizationSelect(id) }
      />
    )
  }

  render() {
    const { user, renderButton, onSignOutClick } = this.props
    const { isMenuOpen, anchorElement } = this.state
    const displayName = user.displayName || user.email

    if (!renderButton) return null

    const profStyle = {
      fontSize: '12px',
      fontWeight: '500',
      textAlign: 'center',
      cursor: 'default',
    }

    const PopoverComponent = (
      <Popover
        open={ isMenuOpen }
        anchorEl={ anchorElement }
        onRequestClose={ this.closeMenu.bind(this) }
        anchorOrigin={ anchorOrigin }
        targetOrigin={ targetOrigin }
        useLayerForClickAway={ false }
      >
        <Menu onItemTouchTap={ this.closeMenu.bind(this) }>
          <MenuItem primaryText={ displayName } disabled={ true } style={ profStyle } />

          <MenuItem primaryText={
            <FormattedMessage
              id="profile_menu_item_label_account_settings"
              defaultMessage="Account Settings"
            />
          } leftIcon={ <IconProfile /> } containerElement={ <Link to="/profile" /> } />

          <MenuItem primaryText={
            <FormattedMessage
              id="profile_menu_item_label_help_center"
              defaultMessage="Help Center"
            />
          } leftIcon={ <IconHelp /> } href="http://help.remente.com" target="_blank" />

          <Divider />

          <MenuItem primaryText={
            <FormattedMessage
              id="tabs_settings"
              defaultMessage="Settings"
            />
          } leftIcon={ <IconSettings /> } containerElement={ <Link to="/settings" /> } />

          <Divider />

          <MenuItem primaryText={
            <FormattedMessage
              id="profile_menu_item_label_log_out"
              defaultMessage="Log Out"
            />
          } onClick={ onSignOutClick } leftIcon={ <IconLogout /> } />

        </Menu>
      </Popover>
    )

    return renderButton(PopoverComponent, this.openMenu)

  }

  renderIcon() {
    const { photoURL } = this.props.user
    const { isMenuOpen } = this.state

    if (!photoURL)
      return (
        <IconButton>
          <IconProfile color="#515151" />
        </IconButton>
      )

    const profilePic = {
      backgroundImage: `url(${ photoURL })`,
    }
    return (
      <UserThumbnail isMenuOpen={ isMenuOpen } style={ profilePic } />
    )
  }

}

export { ProfileMenu }
