import { combineReducers } from 'redux'
import { combineEpics, ofType } from 'redux-observable'
import { createAction, handleActions } from 'redux-actions'
import { createSelector } from 'reselect'
import { get } from 'lodash'
import { of } from 'rxjs'
import { map, flatMap, switchMap, catchError } from 'rxjs/operators'

import { FirebaseApi } from '../../api'
import { compileJourneyEntryLookupMap } from '../../utils/goal'

import {
  setCurrentModal,
  closeModal,
} from '../app'
import { getJourneyEntriesByGoalId, getGoalsWithProgressById } from '../goalProgress'

const INITAL_STATE = {
  editingJourneyEntry: null,
}

const JOURNEY_ENTRY_EDIT             = 'remente/journeyEntry/JOURNEY_ENTRY_EDIT'
const JOURNEY_ENTRY_UPDATE           = 'remente/journeyEntry/JOURNEY_ENTRY_UPDATE'
const JOURNEY_ENTRY_UPDATE_FULFILLED = 'remente/journeyEntry/JOURNEY_ENTRY_UPDATE_FULFILLED'
const JOURNEY_ENTRY_UPDATE_REJECTED  = 'remente/journeyEntry/JOURNEY_ENTRY_UPDATE_REJECTED'

export const editJourneyEntry     = createAction(JOURNEY_ENTRY_EDIT)
export const updateJourneyEntry   = createAction(JOURNEY_ENTRY_UPDATE)
const updateJourneyEntryFulfilled = createAction(JOURNEY_ENTRY_UPDATE_FULFILLED)
const updateJourneyEntryRejected  = createAction(JOURNEY_ENTRY_UPDATE_REJECTED)

/**
 * Reducers
 */

const editJourneyEntryReducer = handleActions({
  [editJourneyEntry]: (state, { payload }) => payload,
}, INITAL_STATE.editingJourneyEntry)

export default combineReducers({
  editJourneyEntry: editJourneyEntryReducer,
})

/**
 * Selectors
 */

const editingJourneyEntrySelector = ({ journeyEntry }) => journeyEntry.editJourneyEntry

export const getMyJourneyEntriesByDate = createSelector(
  getJourneyEntriesByGoalId,
  userJourneyEntriesByGoalId => compileJourneyEntryLookupMap({ userJourneyEntriesByGoalId }),
)

export const getEditingJourneyEntryId = createSelector(
  editingJourneyEntrySelector,
  ({ journeyEntryId }) => journeyEntryId,
)

export const getEditingJourneyEntryGoalId = createSelector(
  editingJourneyEntrySelector,
  ({ goalId }) => goalId,
)

export const getEditingJourneyEntry = createSelector(
  getJourneyEntriesByGoalId,
  getEditingJourneyEntryGoalId,
  getEditingJourneyEntryId,
  (entries, goalId, journeyEntryId) => get(entries, [goalId, journeyEntryId]),
)

export const getEditingJourneyEntryLoggedAt = createSelector(
  getEditingJourneyEntry,
  entry => get(entry, 'loggedAt'),
)

export const getEditingJourneyEntryNotes = createSelector(
  getEditingJourneyEntry,
  entry => get(entry, 'attachments.notes'),
)

const getEditingJourneyEntryGoalTaskId = createSelector(
  getEditingJourneyEntry,
  ({ taskId }) => taskId,
)

const getEditingJourneyEntryGoal = createSelector(
  getEditingJourneyEntryGoalId,
  getGoalsWithProgressById,
  (goalId, goals) => get(goals, goalId),
)

const getEditingJourneyEntryTask = createSelector(
  getEditingJourneyEntryGoal,
  getEditingJourneyEntryGoalTaskId,
  (goal, taskId) => get(goal, ['tasksById', taskId]),
)

export const getEditingJourneyEntryTaskTitle = createSelector(
  getEditingJourneyEntryTask,
  entry => get(entry, 'title'),
)

export const getEditingJourneyEntryOrganizationId = createSelector(
  getEditingJourneyEntryGoal,
  goal => get(goal, 'organizationId'),
)

/**
 * Epics
 */

const editJourneyEntryEpic = action$ => action$.pipe(
  ofType(JOURNEY_ENTRY_EDIT),
  map(() => setCurrentModal({
    currentModal: 'goal/journey-entry/edit',
  })),
)

const updateJourneyEntryEpic = (action$, state$) => action$.pipe(
  ofType(JOURNEY_ENTRY_UPDATE),
  map(({ payload }) => payload),
  switchMap(updates => {
    const state  = state$.value
    const id     = getEditingJourneyEntryId(state)
    const goal = getEditingJourneyEntryGoal(state)
    return FirebaseApi
      .updateJourneyEntry({ ...updates, id, goalId: goal.id, organizationId: goal.organizationId })
      .pipe(
        flatMap(res => [
          updateJourneyEntryFulfilled(res),
          closeModal(),
        ]),
        catchError(err => of(updateJourneyEntryRejected(err))),
      )
  }),
)

export const journeyEntryEpics = combineEpics(
  editJourneyEntryEpic,
  updateJourneyEntryEpic,
)

