import React, { Component } from 'react'
import {
  FormattedMessage,
  defineMessages,
  injectIntl,
} from 'react-intl'

import {
  AddListItemInput,
  KeyBindingHint,
  Hotkey,
} from '../'

import {
  Wrap,
} from './styles'

const truncate = str => str.length > 20 ? str.substr(0, 20) + '...' : str

const translations = defineMessages({
  placeholderText: {
    id: 'add_task_input_placeholder',
    defaultMessage: 'Add a task',
  },
})

class AddTaskInputClass extends Component {

  createTodoTask = () => {
    const { taskInputValue, onCreateTodoTask } = this.props
    if (taskInputValue.length)
      onCreateTodoTask(taskInputValue)
  }

  componentWillReceiveProps(nextProps) {
    const { isPlanMode, shouldFocusInput } = this.props
    if ((nextProps.shouldFocusInput !== shouldFocusInput) && (isPlanMode || nextProps.isPlanMode))
      this.input.focus()
  }

  handleTaskInputValueChange = e => this.props.onTaskInputValueChange(e.target.value)

  handleTaskInputValueFocus = () => {
    const { isPlanMode, onTogglePlanMode } = this.props
    if (!isPlanMode) onTogglePlanMode(true)
  }

  exitPlanMode = e => {
    e.preventDefault()
    this.props.onTogglePlanMode(false)
    if (e.target && e.target.blur) e.target.blur()
  }

  handleSubmit = () => {
    const { taskInputValue } = this.props
    if (taskInputValue.length > 0)
      this.createTodoTask()
    this.input.focus()
  }

  render() {
    const {
      taskInputValue,
      hasSelectedTask,
      selectedTaskTitle,
      isPlanModeAllowed,
      onSelectPrevious,
      onSelectNext,
      onPlanSelected,
      intl,
    } = this.props
    const buttonProps = {
      onClick: this.handleSubmit,
    }
    const setInputRef = input => this.input = input

    let hints = []

    if (hasSelectedTask)
      hints.push(
        <tr key="hint-task">
          <td>
            <FormattedMessage
              id="add_task_input_hint_goal_task"
              defaultMessage="Plan {taskTitle}"
              values={ {
                taskTitle: (
                  <strong>{ truncate(selectedTaskTitle) }</strong>
                ),
              } }
            />
          </td>
          <td>
            <KeyBindingHint binding="tab" />
          </td>
        </tr>,
      )

    if (taskInputValue)
      hints.push(
        <tr key="hint-todo">
          <td>
            <FormattedMessage
              id="add_task_input_hint_todo_task"
              defaultMessage="Create {taskTitle}"
              values={ {
                taskTitle: (
                  <strong>{ truncate(taskInputValue) }</strong>
                ),
              } }
            />
          </td>
          <td>
            <KeyBindingHint binding="enter" />
          </td>
        </tr>,
      )

    const Hints = hints.length > 0 && (
      <table>
        <tbody>
          { hints.map(hint => hint) }
        </tbody>
      </table>
    )

    const placeholderText = hasSelectedTask ? null : intl.formatMessage(translations.placeholderText)

    const hotkeyMap = {
      enter: this.createTodoTask,
      esc: this.exitPlanMode,
      'up|ctrl+k': onSelectPrevious,
      'down|ctrl+j': onSelectNext,
      'shift+enter|tab': onPlanSelected,
    }

    return (
      <Wrap>

        <AddListItemInput
          value={ taskInputValue }
          placeholder={ placeholderText }
          onChange={ this.handleTaskInputValueChange }
          onFocus={ this.handleTaskInputValueFocus }
          className="mousetrap"
          buttonProps={ buttonProps }
          innerRef={ setInputRef }
          inputOverlay={ selectedTaskTitle }
          disabled={ !isPlanModeAllowed }
        />

        <div>
          { Hints }
        </div>

        <Hotkey map={ hotkeyMap } />

      </Wrap>
    )
  }

}

const AddTaskInput = injectIntl(AddTaskInputClass)

export { AddTaskInput }
