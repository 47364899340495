import styled from 'styled-components'

export const Wrap = styled.div`
  padding-top: 1em;
`

export const AutosuggestWrap = styled.div`
  margin: 1em 0;

  > * {
    z-index: 5;
  }

`
