import React from 'react'
import Flipmove from 'react-flip-move'

const listEnterAnimation = {
  from: {
    opacity: 0,
    transform: 'scale(1) translateY(-20%)',
  },
  to: {
    opacity: 1,
    transform: 'scale(1, 1)',
  },
}

const ListItemsAnimator = ({ children, ...props }) => (
  <Flipmove
    duration={ 300 }
    easing="ease-out"
    enterAnimation={ listEnterAnimation }
    leaveAnimation={ false }
    { ...props }
  >
    { children }
  </Flipmove>
)

export { ListItemsAnimator }
