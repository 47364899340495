import styled, { css } from 'styled-components'

const absoluteFull = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

export const Wrap = styled.div`
  position: relative;
  width: 130px;
  height: 80px;
  border-radius: 4px;
  overflow: hidden;
  transition: all 150ms ease-out;
  &:hover,
  &:focus {
    svg { transform: scale(1.3); }
  }
  ${ ({ hasImage }) => hasImage ? `
    background: #313131;
  `:`
    cursor: pointer;
    background: #f4f4f6;
  `}
  ${ ({ isDropzoneActive }) => isDropzoneActive ? `
    transform: scale(1.2);
  `:''}
`

export const ImagePreview = styled.div`
  ${ absoluteFull }
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: .6;
  z-index: 0;
  ${ ({ bgImage }) => bgImage ? `
    background-image: url(${ bgImage });
  ` : '' }
`

export const ImageOverlay = styled.div`
  ${ absoluteFull }
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`
