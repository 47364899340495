import { createAction } from 'redux-actions'
import { combineEpics, ofType } from 'redux-observable'
import { FirebaseApi } from '../../api'
import { of, combineLatest } from 'rxjs'
import { map, switchMap, mergeMap, takeUntil, catchError } from 'rxjs/operators'
import { createSelector } from 'reselect'

import { getIsElectron } from '../electron'

export const USER_AUTHENTICATED          = 'remente/auth/USER_AUTHENTICATED'
export const USER_UNAUTHENTICATED        = 'remente/auth/USER_UNAUTHENTICATED'
const FIELD_CHANGED                      = 'remente/auth/FIELD_CHANGED'
const USER_AUTHENTICATE                  = 'remente/auth/USER_AUTHENTICATE'
const USER_AUTHENTICATE_FULFILLED        = 'remente/auth/USER_AUTHENTICATE_FULFILLED'
const USER_AUTHENTICATE_REJECTED         = 'remente/auth/USER_AUTHENTICATE_REJECTED'
const USER_REGISTER                      = 'remente/auth/USER_REGISTER'
const PASSWORD_RESET_REQUEST_CREATE      = 'remente/auth/PASSWORD_RESET_REQUEST_CREATE'
const AUTH_STATE_CHANGED                 = 'remente/auth/AUTH_STATE_CHANGED'
const AUTH_GET_REDIRECT_RESULT           = 'remente/auth/AUTH_GET_REDIRECT_RESULT'
const AUTH_GET_REDIRECT_RESULT_FULFILLED = 'remente/auth/AUTH_GET_REDIRECT_RESULT_FULFILLED'
const AUTH_OBSERVE_STATE_CHANGED         = 'remente/auth/AUTH_OBSERVE_STATE_CHANGED'
const USER_PROFILE_CHANGED               = 'remente/auth/USER_PROFILE_CHANGED'
const SIGN_OUT                           = 'remente/auth/SIGN_OUT'
const SIGN_OUT_FULFILLED                 = 'remente/auth/SIGN_OUT_FULFILLED'
const SIGN_OUT_REJECTED                  = 'remente/auth/SIGN_OUT_REJECTED'
const NOOP                               = 'remente/auth/NOOP'

const INITAL_STATE = {
  email: '',
  password: '',
  authedUser: null,
  isAuthenticated: false,
  hasAuthState: false,
}

function authReducer(state = INITAL_STATE, action) {
  const { authedUser } = state
  const { type, payload } = action

  switch (type) {

  case FIELD_CHANGED:
    return {
      ...state,
      [payload.prop]: payload.value,
    }

  case USER_AUTHENTICATE_FULFILLED:
    return INITAL_STATE

  case USER_AUTHENTICATE_REJECTED:
    return {
      ...state,
      password: '',
    }

  case AUTH_STATE_CHANGED:
    return {
      ...state,
      authedUser: payload.user ? payload.user.toJSON() : null,
      isAuthenticated: !!payload.user,
      hasAuthState: true,
    }

  case USER_PROFILE_CHANGED:
    return {
      ...state,
      authedUser: {
        ...authedUser,
        ...payload,
      },
    }

  default:
    return state

  }
}

export const fieldChanged                    = createAction(FIELD_CHANGED)
export const authenticateUser                = createAction(USER_AUTHENTICATE)
export const authenticateUserFulfilled       = createAction(USER_AUTHENTICATE_FULFILLED)
export const authenticateGetRedirectResult   = createAction(AUTH_GET_REDIRECT_RESULT)
export const registerUser                    = createAction(USER_REGISTER)
export const createResetPasswordRequest      = createAction(PASSWORD_RESET_REQUEST_CREATE)
export const observeAuthStateChanged         = createAction(AUTH_OBSERVE_STATE_CHANGED)
const authenticateUserRejected               = createAction(USER_AUTHENTICATE_REJECTED)
const authStateChanged                       = createAction(AUTH_STATE_CHANGED)
const authenticateGetRedirectResultFulfilled = createAction(AUTH_GET_REDIRECT_RESULT_FULFILLED)
const userProfileChanged                     = createAction(USER_PROFILE_CHANGED)
const userAuthenticated                      = createAction(USER_AUTHENTICATED)
const userUnauthenticated                    = createAction(USER_UNAUTHENTICATED)
export const signOut                         = createAction(SIGN_OUT)
const signOutFulfilled                       = createAction(SIGN_OUT_FULFILLED)
const signOutRejected                        = createAction(SIGN_OUT_REJECTED)
const noop                                   = createAction(NOOP)

/**
 * Selectors
 */

const authedUserSelector = ({ auth }) => auth.authedUser
const hasAuthStateSelector = ({ auth }) => auth.hasAuthState

export const getHasAuthState = createSelector(
  hasAuthStateSelector,
  hasAuthState => hasAuthState,
)

export const getAuthenticatedUser = createSelector(
  authedUserSelector,
  authedUser => authedUser,
)

export const getIsAuthenticated = createSelector(
  getAuthenticatedUser,
  user => !!user,
)

export const getAuthenticatedUserId = createSelector(
  getAuthenticatedUser,
  ({ uid }) => uid,
)

export const getIsAuthenticatedUserPremium = createSelector(
  getAuthenticatedUser,
  ({ isPremium }) => !!isPremium,
)

export const getIsAuthenticatedUserAllowedPremiumContent = createSelector(
  getAuthenticatedUser,
  ({ isPremium, isAdmin, isEditor }) => (isPremium || isAdmin || isEditor),
)

/**
 * Epics
 */

const authenticateUserEpic = action$ => action$.pipe(
  ofType(USER_AUTHENTICATE),
  map(({ payload }) => payload),
  mergeMap(({ provider, email, password }) => FirebaseApi
    .authenticate({ provider, email, password })
    .pipe(
      map(res => authenticateUserFulfilled(res)),
      catchError(err => {
        alert(err.message)
        return of(authenticateUserRejected(err))
      }),
    ),
  ),
)

const authenticateGetRedirectResultEpic = action$ => action$.pipe(
  ofType(AUTH_GET_REDIRECT_RESULT),
  mergeMap(() => FirebaseApi
    .getRedirectResult()
    .pipe(
      map(payload => authenticateGetRedirectResultFulfilled(payload)),
    ),
  ),
)

const createResetPasswordRequestEpic = action$ => action$.pipe(
  ofType(createResetPasswordRequest().type),
  map(({ payload }) => payload),
  mergeMap(email => FirebaseApi
    .sendPasswordResetEmail({ email })
    .pipe(map(noop)),
  ),
)

const registerUserEpic = action$ => action$.pipe(
  ofType(registerUser().type),
  map(({ payload }) => payload),
  mergeMap(({ email, password, provider }) => FirebaseApi
    .registerUser({ email, password, provider })
    .pipe(
      map(noop),
      catchError(({ message }) => {
        alert(message)
        return of(noop())
      }),
    ),
  ),
)

const observeAuthStateChangedEpic = action$ => action$.pipe(
  ofType(AUTH_OBSERVE_STATE_CHANGED),
  mergeMap(() => FirebaseApi
    .onAuthStateChanged()
    .pipe(map(user => authStateChanged({ user }))),
  ),
)

const observeAuthStateChangedSplitEpic = action$ => action$.pipe(
  ofType(AUTH_OBSERVE_STATE_CHANGED),
  mergeMap(() => FirebaseApi
    .onAuthStateChanged()
    .pipe(map(user => user ? userAuthenticated(user) : userUnauthenticated())),
  ),
)

const announcePresenceEpic = (action$, state$) => action$.pipe(
  ofType(userAuthenticated().type),
  map(({ payload }) => payload),
  mergeMap(firebaseUser => FirebaseApi
    .connectionStatusObservableRef()
    .pipe(
      map(isConnectedWithBackend => {
        if (!isConnectedWithBackend) return noop()

        const isElectron = getIsElectron(state$.value)
        const user = firebaseUser.toJSON()

        FirebaseApi.announcePresence({ isElectron, user })
        return noop()
      }),
      takeUntil(action$.pipe(ofType(USER_UNAUTHENTICATED))),
    ),
  ),
)

const unannouncePresenceEpic = action$ => action$.pipe(
  ofType(SIGN_OUT),
  map(() => {
    FirebaseApi.unannouncePresence()
    return noop()
  }),
)

const authStateChangedEpic = action$ => action$.pipe(
  ofType(AUTH_STATE_CHANGED),
  switchMap(({ payload }) => {
    const { user } = payload
    if (!user) return of({ type: 'remente/auth/UNAUTHORIZED' })
    return combineLatest(
      FirebaseApi.observableRef(`users/${ user.uid }`),
      FirebaseApi.observableRef(`roles/premium/${ user.uid }`),
      FirebaseApi.observableRef(`roles/lifetime-premium/${ user.uid }`),
      FirebaseApi.observableRef(`roles/admins/${ user.uid }`),
      FirebaseApi.observableRef(`roles/editors/${ user.uid }`),
    ).pipe(
      map(res => ({
        profile:           res[0],
        isPremium:         !!res[1] || !!res[2],
        isLifetimePremium: !!res[2],
        isAdmin:           !!res[3],
        isEditor:          !!res[4],
      })),
      map(userProfileChanged),
    )
  }),
)

const signOutEpic = action$ => action$.pipe(
  ofType(SIGN_OUT),
  switchMap(() => FirebaseApi
    .signOut()
    .pipe(
      map(signOutFulfilled),
      catchError(err => of(signOutRejected(err))),
    ),
  ),
)

export const authEpics = combineEpics(
  authenticateUserEpic,
  authenticateGetRedirectResultEpic,
  registerUserEpic,
  createResetPasswordRequestEpic,
  observeAuthStateChangedEpic,
  observeAuthStateChangedSplitEpic,
  announcePresenceEpic,
  unannouncePresenceEpic,
  authStateChangedEpic,
  signOutEpic,
)

export default authReducer
