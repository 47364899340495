import { connect } from 'react-redux'
import { ScrollView } from '../'
import { getPathname } from '../../ducks/route'

const mapStateToProps = state => ({
  scrollTopTrigger: getPathname(state),
})

class RouteScrollViewContainerClass extends ScrollView {}

const RouteScrollViewContainer = connect(
  mapStateToProps,
)(RouteScrollViewContainerClass)

export { RouteScrollViewContainer }
