import React, { Component } from 'react'
import { get } from 'lodash'

import {
  Wrap,
  AspectRatio,
  Content,
  Overlay,
} from './styles'

class GoalTemplateTile extends Component {
  render() {
    const { title, onClick } = this.props
    const imageUrl = get(this.props, 'images.featured.servingUrl')
    return (
      <Wrap src={ imageUrl } onClick={ onClick }>
        <Content>
          { title }
        </Content>
        <Overlay />
        <AspectRatio />
      </Wrap>
    )
  }
}

export { GoalTemplateTile }
