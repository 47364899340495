import { Component } from 'react'
import Mousetrap from 'mousetrap'

class Hotkey extends Component {

  componentDidMount() {
    const { keys, map } = this.props
    if (keys) Mousetrap.bind(keys, this.handleHotkey)
    if (!map) return
    Object.keys(map).forEach(keybindings => keybindings.split('|')
      .forEach(combo => Mousetrap.bind(combo, e => {
        e.preventDefault()
        map[keybindings](e)
      })),
    )
  }

  componentWillUnmount() {
    const { keys, map } = this.props
    if (keys) Mousetrap.unbind(keys)
    if (!map) return
    Object.keys(map).forEach(keybindings => keybindings.split('|')
      .forEach(combo => Mousetrap.unbind(combo)),
    )
  }

  handleHotkey = e => {
    const { handler } = this.props
    if (!handler) return
    e.preventDefault()
    handler(e)
  }

  render() {
    return null
  }

}

export { Hotkey }
