import { connect } from 'react-redux'
import { Minibar } from './'
import { getIsElectron } from '../../ducks/electron'
import { getPathname } from '../../ducks/route'
import { getMyPulseOrganizationId } from '../../ducks/pulse'

const mapStateToProps = state => ({
  _forceRefresh: getPathname(state),
  isElectron: getIsElectron(state),
  isPulseAvailable: !!getMyPulseOrganizationId(state),
})

const MinibarContainer = connect(
  mapStateToProps,
)(
  class MinibarContainer extends Minibar {},
)

export { MinibarContainer }
