import styled from 'styled-components'

export const UserThumbnail = styled.div`
  width: 32px;
  height: 32px;
  background-size: cover;
  background-position: center;
  border-radius: 32px;
  border: 1px solid white;
  box-shadow: 0 2px 5px 0 rgba(50, 50, 95, .1), 0 1px 2px 0 rgba(0, 0, 0, .08);
  margin-left: 16px;
  cursor: pointer;
  transition: all 150ms ease-out;
  ${
  ({ isMenuOpen }) => isMenuOpen ? `
      transform: translateY(1px);
      box-shadow: 0 0px 1px 0 rgba(50, 50, 95, .2), 0 -1px 2px 0 rgba(0, 0, 0, .10);
      border-color: transparent;
    ` : ''
}
`
