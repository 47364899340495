import React, { Component } from 'react'

import { Overlay } from './styles'

class Cover extends Component {
  render() {
    const { children } = this.props
    return (
      <Overlay>
        { children }
      </Overlay>
    )
  }
}

export { Cover }
