import React, { Component } from 'react'
import { withTheme } from 'styled-components'

class InlineEditTextClass extends Component {

  state = {
    ownValue: '',
  }

  handleSubmit = e => {
    const { onSubmit } = this.props
    e.preventDefault()
    if (!onSubmit) return
    onSubmit(this.state.ownValue)
  }

  handleBlur = e => {
    const { onBlur } = this.props
    e.preventDefault()
    if (!onBlur) return
    onBlur(this.state.ownValue)
  }

  handleChange = e => {
    const ownValue = e.target.value
    this.setState({ ownValue })
  }

  componentWillMount() {
    const ownValue = this.props.value
    this.setState({ ownValue })
  }

  componentWillReceiveProps() {
    const ownValue = this.props.value
    this.setState({ ownValue })
  }

  componentDidUpdate(prevProps) {
    const { isEditing } = this.props
    if (!isEditing || prevProps.isEditing === isEditing) return
    setTimeout(() => {
      this.input.select()
    }, 100)
  }

  render() {
    const { ownValue } = this.state
    const {
      value,
      isEditing,
      style = {},
      innerStyles = {},
      theme,
    } = this.props

    const formStyles = {
      display: isEditing ? 'block' : 'none',
    }

    const fieldStyles = {
      padding: 0,
      border: 'none',
      background: theme.colorMaskedInputBg || 'yellow',
      width: '100%',
      ...innerStyles,
    }

    return (
      <div style={ style }>

        { !isEditing && value }

        <form
          onSubmit={ this.handleSubmit }
          style={ formStyles }
        >
          <input
            type="text"
            value={ ownValue }
            ref={ input => this.input = input }
            style={ fieldStyles }
            onChange={ this.handleChange }
            onBlur={ this.handleBlur }
          />
        </form>

      </div>
    )

  }

}

const InlineEditText = withTheme(InlineEditTextClass)

export { InlineEditText }
