import { get } from 'lodash'

import { taskDateFilterFactory } from './'
import { isoWeekdayToDayId } from '../date'

const autoplanFilterFactory = ({
  date,
}) => ({ target, startDate, endDate, autoplan, linkedTo }) => {

  if (linkedTo)
    return false

  const isAutoplanDisabled = get(autoplan, 'isDisabled')

  if (isAutoplanDisabled)
    return false

  if (!target)
    return startDate && date.isSame(startDate, 'day')

  const autoplanOnDay = get(
    autoplan,
    ['days', isoWeekdayToDayId(date.isoWeekday())],
  )

  if (!autoplanOnDay)
    return false

  return taskDateFilterFactory({ date })({
    startDate,
    endDate,
  })

}

export { autoplanFilterFactory }
