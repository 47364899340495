import React from 'react'

const SvgIcon = ({
  path,
  ...rest
}) => (
  <svg viewBox="0 0 24 24" width="24" height="24" { ...rest }>
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d={ path } />
  </svg>
)

export { SvgIcon }
