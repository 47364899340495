import { createAction } from 'redux-actions'
import { createSelector } from 'reselect'
import { combineEpics, ofType } from 'redux-observable'
import { values, keyBy } from 'lodash'
import { of } from 'rxjs'
import { map, mergeMap, catchError, concat } from 'rxjs/operators'
import { FirebaseApi } from '../../api'

import {
  getActiveGoals,
  getCompletedGoals,
  getTasksFromGoals,
} from '../../utils/goal'

import { redirectToGoalDetails } from '../route'
import { getMyGoalsById } from '../userContent'

const GOAL_CREATE                  = 'remente/goal/GOAL_CREATE'
const GOAL_CREATE_EXERCISE         = 'remente/goal/GOAL_CREATE_EXERCISE'
export const GOAL_CREATE_FULFILLED = 'remente/goal/GOAL_CREATE_FULFILLED'
const GOAL_CREATE_REJECTED         = 'remente/goal/GOAL_CREATE_REJECTED'
const GOAL_DELETE                  = 'remente/goal/GOAL_DELETE'
const GOAL_DELETE_FULFILLED        = 'remente/goal/GOAL_DELETE_FULFILLED'
const GOAL_TASK_DELETE_REJECTED    = 'remente/goal/GOAL_TASK_DELETE_REJECTED'
const GOAL_COMPLETED_SET           = 'remente/goal/GOAL_COMPLETED_SET'
const GOAL_COMPLETED_SET_FULFILLED = 'remente/goal/GOAL_COMPLETED_SET_FULFILLED'
const GOAL_COMPLETED_SET_REJECTED  = 'remente/goal/GOAL_COMPLETED_SET_REJECTED'
const GOAL_ACTIVE_SET              = 'remente/goal/GOAL_ACTIVE_SET'
const GOAL_ACTIVE_SET_FULFILLED    = 'remente/goal/GOAL_ACTIVE_SET_FULFILLED'
const GOAL_ACTIVE_SET_REJECTED     = 'remente/goal/GOAL_ACTIVE_SET_REJECTED'

export const createGoal             = createAction(GOAL_CREATE)
export const createGoalFromExercise = createAction(GOAL_CREATE_EXERCISE)
export const createGoalFulfilled    = createAction(GOAL_CREATE_FULFILLED)
export const createGoalRejected     = createAction(GOAL_CREATE_REJECTED)
export const deleteGoal             = createAction(GOAL_DELETE)
const deleteGoalFulfilled           = createAction(GOAL_DELETE_FULFILLED)
const deleteGoalRejected            = createAction(GOAL_TASK_DELETE_REJECTED)
export const setGoalCompleted       = createAction(GOAL_COMPLETED_SET)
const setGoalCompletedFulfilled     = createAction(GOAL_COMPLETED_SET_FULFILLED)
const setGoalCompletedRejected      = createAction(GOAL_COMPLETED_SET_REJECTED)
export const setGoalActive          = createAction(GOAL_ACTIVE_SET)
const setGoalActiveFulfilled        = createAction(GOAL_ACTIVE_SET_FULFILLED)
const setGoalActiveRejected         = createAction(GOAL_ACTIVE_SET_REJECTED)

/**
 * Selectors
 */

export const getMyGoals = createSelector(
  getMyGoalsById,
  goalsById => values(goalsById),
)

export const getMyGoalsActive = createSelector(
  getMyGoals,
  goals => getActiveGoals(goals),
)

export const getMyGoalsCompleted = createSelector(
  getMyGoals,
  goals => getCompletedGoals(goals),
)

export const getNumMyGoalsActive = createSelector(
  getMyGoalsActive,
  goals => goals.length,
)

export const getNumMyGoalsCompleted = createSelector(
  getMyGoalsCompleted,
  goals => goals.length,
)

export const getMyGoalsActiveTasks = createSelector(
  getMyGoalsActive,
  goals => getTasksFromGoals(goals),
)

export const getMyGoalsActiveTasksById = createSelector(
  getMyGoalsActiveTasks,
  tasks => keyBy(tasks, 'id'),
)

/**
 * Epics
 */

const createGoalEpic = action$ => action$.pipe(
  ofType(createGoal().type),
  map(({ payload }) => payload),
  mergeMap(({ organizationId, ...goal }) => {
    const id = FirebaseApi.newId()
    return of(redirectToGoalDetails(id)).pipe(concat(
      FirebaseApi
        .createGoal({ goal: { id, ...goal }, organizationId })
        .pipe(
          map(createGoalFulfilled),
          catchError(err => of(createGoalRejected(err))),
        ),
    ))
  }),
)

const createGoalFromExerciseEpic = action$ => action$.pipe(
  ofType(createGoalFromExercise().type),
  map(({ payload }) => payload),
  mergeMap(goal => {
    const id = FirebaseApi.newId()
    return FirebaseApi
      .createGoal({ goal: { id, ...goal } })
      .pipe(
        map(createGoalFulfilled),
        catchError(err => of(createGoalRejected(err))),
      )
  }),
)

const deleteGoalEpic = action$ => action$.pipe(
  ofType(deleteGoal().type),
  mergeMap(({ payload }) => FirebaseApi
    .deleteGoal(payload)
    .pipe(
      map(deleteGoalFulfilled),
      catchError(err => of(deleteGoalRejected(err))),
    ),
  ),
)

const setGoalCompletedEpic = action$ => action$.pipe(
  ofType(setGoalCompleted().type),
  map(({ payload }) => payload),
  mergeMap(({ id, organizationId }) => FirebaseApi
    .setGoalCompleted({ id, organizationId })
    .pipe(
      map(setGoalCompletedFulfilled),
      catchError(err => of(setGoalCompletedRejected(err))),
    ),
  ),
)

const setGoalActiveEpic = action$ => action$.pipe(
  ofType(setGoalActive().type),
  map(({ payload }) => payload),
  mergeMap(({ id, organizationId }) => FirebaseApi
    .setGoalActive({ id, organizationId })
    .pipe(
      map(setGoalActiveFulfilled),
      catchError(err => of(setGoalActiveRejected(err))),
    ),
  ),
)

export const goalEpics = combineEpics(
  createGoalEpic,
  createGoalFromExerciseEpic,
  deleteGoalEpic,
  setGoalCompletedEpic,
  setGoalActiveEpic,
)
