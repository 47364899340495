import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import IconArrowRight from 'material-ui/svg-icons/hardware/keyboard-arrow-right'
import { truncate } from '../../utils/css'

export const Icon = styled(IconArrowRight)`
  pointer-events: none;
`

export const Link = styled(NavLink)`
  position: relative;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${ ({ theme }) => theme.colorSidebarItemActiveBg };
  padding: 8px 16px;
  color: ${ ({ theme }) => theme.colorSidebarItemFg } !important;
  background: ${ ({ theme }) => theme.colorSidebarItemBg };
  -webkit-font-smoothing: subpixel-antialiased;
  ${ Icon } {
    fill: ${ ({ theme }) => theme.colorSidebarItemFg } !important;
    transition: transform 150ms ease-out;
    transform: translateX(-5px) scale(.9);
    opacity: 0;
  }
  &:hover {
    ${ Icon } {
      opacity: .5;
    }
  }
  &.active {
    ${ Icon } {
      transform: translateX(0) scale(1);
      transition: all 300ms ease-in !important;
      opacity: 1;
    }
  }
`

export const IceboxTitleContent = styled.div`
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 22px;
  -webkit-font-smoothing: subpixel-antialiased;
  color: #AFB3BC;
  background: #36373a;
  svg:first-child {
    margin: .5em 13px .5em 0;
  }
  height: 43px;
  ${ Icon } {
    display: none !important;
  }
`

export const GoalTitle = styled.div`
  width: 0;
  ${ truncate() }
  span {
    display: block;
    width: 100%;
    ${ truncate() }
  }
  pointer-events: none;
  flex: 1;
  margin: 0 10px;
  transition: all 150ms ease-out;
`

export const Thumbnail = styled.div`
  display: none;
  pointer-events: none;
  width: 42px;
  height: 42px;
  background-size: cover;
  background-position: center;
  border-radius: 40px;
`

export const Prepend = styled.div`
  width: 36px;
  pointer-events: none;
  ${ ({ prepend }) => !prepend && `
    display: none;
  `}
`

export const Wrap = styled.div`

  background: ${ ({ theme }) => theme.colorMinibarBg };

  ${ GoalTitle } {
    ${ ({ isCompact }) => isCompact && `
      transition: none;
      transform: translateX(-8px);
    ` }
    ${ ({ isDiscrete }) => isDiscrete && `
      opacity: .3;
    ` }
  }

  ${ Prepend } {
    ${ ({ isCompact }) => isCompact && `
      display: none;
    ` }
  }

  ${ Icon } {
    ${ ({ isMaster, theme: { colorBodyFg } }) => isMaster && `
      fill: ${ colorBodyFg } !important;
    ` }
    ${ ({ noScrollbar }) => noScrollbar && `
      margin-right: 8px;
    ` }
  }

  ${ Link } {
    ${ ({ isCompact }) => isCompact && `
      padding-top: 0;
      padding-bottom: 0;
      background: #f4f4f6;
    ` }
    ${ ({ isMaster, theme: { colorAppAccent } }) => isMaster && `
      background: ${ colorAppAccent };
      color: #515151 !important;
      font-weight: 600;
      border-bottom: none;
      margin-left: 0;
      border-radius: 4px 0 0 0;
      div {
        margin: 0;
      }
    ` }
    &.active {
      ${ Thumbnail } {
        transform: scale(1);
        ${ ({ isCompact }) => isCompact && `
          transform: scale(.55) translateX(-5px);
        `}
      }
    }
  }

  ${ Thumbnail } {

    transition: transform 150ms ease-out;

    ${ ({ thumbnailUrl }) => thumbnailUrl && `
      background-image: url(${ thumbnailUrl });
    ` }

    ${ ({ isCompact }) => isCompact && `
      display: block;
      transition: none;
      transform: scale(.55) translateX(-5px);
      border: none;
    ` }

  }

`

export const AddIconWrap = styled.div`
  position: absolute;
  left: 28px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
`

export const OriginWrap = styled.small`
  display: flex;
  align-items: center;
  margin-top: .2em;
  font-size: 10px;
`
