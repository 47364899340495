import React, { Component } from 'react'

import {
  ListItemLink,
  ListItemDiv,
} from './styles'

class ListItem extends Component {

  render() {
    const { props } = this
    const { href, children } = props

    if (href) return (
      <ListItemLink to={ href } { ...props }>
        { children }
      </ListItemLink>
    )

    return (
      <ListItemDiv { ...props } />
    )

  }

}

export { ListItem }
