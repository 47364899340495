/* eslint-disable no-console */
import { combineReducers } from 'redux'
import { combineEpics, ofType } from 'redux-observable'
import { createAction, handleActions, combineActions } from 'redux-actions'
import { createSelector } from 'reselect'
import { of, fromEvent } from 'rxjs'
import { map, switchMap, mergeMap, throttleTime } from 'rxjs/operators'
import { get } from 'lodash'
import isElectronApp from 'is-electron'

import { FirebaseApi } from '../../api'
import { tickMinuteHeartbeat } from '../timing'
import { getAuthenticatedUserId } from '../auth'

const ROOT_DOWNLOAD_URL = 'https://remente-desktop-releases.s3.amazonaws.com'

const IPC                        = type => `remente/electron/ipc/${ type }`
const NOOP                       = 'remente/electron/NOOP'
const IS_ELECTRON_SET            = 'remente/electron/IS_ELECTRON_SET'
const IPC_SEND                   = 'remente/electron/ipc/IPC_SEND'
const IPC_SEND_FULFILLED         = 'remente/electron/ipc/IPC_SEND_FULFILLED'
const IS_UPDATE_AVAILABLE_SET    = 'remente/electron/IS_UPDATE_AVAILABLE_SET'
const IS_UPDATE_DOWNLOADED_SET   = 'remente/electron/IS_UPDATE_DOWNLOADED_SET'
const UPDATE_INFO_SET            = 'remente/electron/UPDATE_INFO_SET'
const UPDATE_CHECK               = 'remente/electron/UPDATE_CHECK'
const UPDATE_QUIT_AND_INSTALL    = 'remente/electron/UPDATE_QUIT_AND_INSTALL'
const RECEIPT_VALIDATE           = 'remente/electron/RECEIPT_VALIDATE'
const RECEIPT_VALIDATE_FULFILLED = 'remente/electron/RECEIPT_VALIDATE_FULFILLED'
const RECEIPT_VALIDATE_REJECTED  = 'remente/electron/RECEIPT_VALIDATE_REJECTED'

export const noop                     = createAction(NOOP)
export const setIsElectron            = createAction(IS_ELECTRON_SET)
export const sendIpcMessage           = createAction(IPC_SEND)
export const sendIpcMessageFulfilled  = createAction(IPC_SEND_FULFILLED)
export const setIsUpdateAvailable     = createAction(IS_UPDATE_AVAILABLE_SET)
export const setIsUpdateDownloaded    = createAction(IS_UPDATE_DOWNLOADED_SET)
export const setUpdateInfo            = createAction(UPDATE_INFO_SET)
export const checkForUpdates          = createAction(UPDATE_CHECK)
export const quitAndInstallUpdate     = createAction(UPDATE_QUIT_AND_INSTALL)
export const validateReceipt          = createAction(RECEIPT_VALIDATE)
export const validateReceiptFulfilled = createAction(RECEIPT_VALIDATE_FULFILLED)
export const validateReceiptRejected  = createAction(RECEIPT_VALIDATE_REJECTED)

/**
 * Reducers
 */

const isElectronReducer = handleActions({
  [setIsElectron]: (state, { payload }) => payload,
}, false)

const isUpdateAvailableReducer = handleActions({
  [setIsUpdateAvailable]: (state, { payload }) => payload,
  [setUpdateInfo]: () => true,
}, false)

const isUpdateDownloadedReducer = handleActions({
  [setIsUpdateDownloaded]: (state, { payload }) => payload,
}, false)

const isValidatingReceiptReducer = handleActions({
  [validateReceipt]: () => true,
  [combineActions(
    validateReceiptRejected,
    validateReceiptFulfilled,
  )]: () => false,
}, false)

const updateInfoReducer = handleActions({
  [setUpdateInfo]: (state, { payload }) => payload,
}, null)

export default combineReducers({
  isElectron: isElectronReducer,
  isUpdateAvailable: isUpdateAvailableReducer,
  isUpdateDownloaded: isUpdateDownloadedReducer,
  isValidatingReceipt: isValidatingReceiptReducer,
  updateInfo: updateInfoReducer,
})

/**
 * Selectors
 */

const isElectronSelector          = ({ electron }) => electron.isElectron
const isUpdateAvailableSelector   = ({ electron }) => electron.isUpdateAvailable
const isUpdateDownloadedSelector  = ({ electron }) => electron.isUpdateDownloaded
const isValidatingReceiptSelector = ({ electron }) => electron.isValidatingReceipt
const updateInfoSelector          = ({ electron }) => electron.updateInfo

export const getIsElectron          = createSelector(isElectronSelector, value => value)
export const getIsUpdateAvailable   = createSelector(isUpdateAvailableSelector, value => value)
export const getIsUpdateDownloaded  = createSelector(isUpdateDownloadedSelector, value => value)
export const getIsValidatingReceipt = createSelector(isValidatingReceiptSelector, value => value)
export const getUpdateInfo          = createSelector(updateInfoSelector, value => value)

export const getUpdateInfoVersion = createSelector(
  getUpdateInfo,
  info => get(info, 'version'),
)

export const getUpdateInfoDownloadUrl = createSelector(
  getUpdateInfo,
  info => info ? `${ ROOT_DOWNLOAD_URL }/${ info.path }` : undefined,
)

/**
 * Epics
 */

const ipcReceiveEpic = action$ => action$.pipe(
  ofType(IS_ELECTRON_SET),
  map(({ payload }) => payload),
  mergeMap(isElectron => {
    if (!isElectron) return of(noop())

    const { ipcRenderer } = window.require('electron')

    // custom selector to get rxjs to pass all arguments
    function selector() {
      return [].slice.call(arguments)
    }

    return fromEvent(ipcRenderer, 'message', selector)
      // eslint-disable-next-line no-unused-vars
      .pipe(map(([event, { eventName, eventData }]) => {
        console.info('IPC Received', eventName, eventData)
        return {
          type: IPC(eventName),
          payload: eventData,
        }
      }))
  }),
)

const ipcSendEpic = action$ => action$.pipe(
  ofType(IPC_SEND),
  map(({ payload: { eventName, data } }) => {
    const { ipcRenderer } = window.require('electron')
    ipcRenderer.send(eventName, data)
    return sendIpcMessageFulfilled()
  }),
)

const checkForUpdatesEpic = action$ => action$.pipe(
  ofType(UPDATE_CHECK),
  map(() => sendIpcMessage({ eventName: 'checkForUpdates' })),
)

const checkForUpdatesOnFocusEpic = action$ => action$.pipe(
  ofType(tickMinuteHeartbeat().type),
  throttleTime(30 * 1000 * 60),
  map(checkForUpdates),
)

const updateAvailableEpic = action$ => action$.pipe(
  ofType(IPC('AUTOUPDATE_UPDATE_AVAILABLE')),
  map(({ payload }) => setUpdateInfo(payload)),
)

const updateUnavailableEpic = action$ => action$.pipe(
  ofType(IPC('AUTOUPDATE_UPDATE_UNAVAILABLE')),
  map(() => setIsUpdateAvailable(false)),
)

const updateDownloadedEpic = action$ => action$.pipe(
  ofType(IPC('AUTOUPDATE_UPDATE_DOWNLOADED')),
  map(() => setIsUpdateDownloaded(true)),
)

const quitAndInstallEpic = action$ => action$.pipe(
  ofType(UPDATE_QUIT_AND_INSTALL),
  map(() => sendIpcMessage({ eventName: 'quitAndInstallUpdate' })),
)

const updateErrorEpic = action$ => action$.pipe(
  ofType(IPC('AUTOUPDATE_ERROR')),
  map(({ payload }) => {
    console.error(payload)
    return noop()
  }),
)

const validateReceiptEpic = (action$, state$) => action$.pipe(
  ofType(validateReceipt().type),
  switchMap(({ payload: { receipt, purchaseDetails } }) => FirebaseApi
    .getIdToken(true)
    .then(idToken => {
      const state = state$.value
      const uid = getAuthenticatedUserId(state)
      return FirebaseApi.createIapPendingReceipt({
        uid,
        idToken,
        receipt,
        purchaseDetails,
      })
    })
    .then(validateReceiptFulfilled)
    .catch(validateReceiptRejected)
  ),
)

const epics = isElectronApp() ? [
  ipcReceiveEpic,
  ipcSendEpic,
  checkForUpdatesEpic,
  checkForUpdatesOnFocusEpic,
  updateAvailableEpic,
  updateUnavailableEpic,
  updateDownloadedEpic,
  quitAndInstallEpic,
  updateErrorEpic,
  validateReceiptEpic,
] : []

export const electronEpics = combineEpics.apply(this, epics)
