import { combineEpics, ofType } from 'redux-observable'
import { createAction } from 'redux-actions'
import { of } from 'rxjs'
import { map, concat, mergeMap, catchError } from 'rxjs/operators'
import { FirebaseApi } from '../../api'
import { setCurrentModal, closeModal } from '../app'

const MODAL_SHOW = 'remente/editMoodAssessmentModal/MODAL_SHOW'
const ASSESSMENT_UPDATE = 'remente/editMoodAssessmentModal/ASSESSMENT_UPDATE'
const ASSESSMENT_UPDATE_FULFILLED = 'remente/editMoodAssessmentModal/ASSESSMENT_UPDATE_FULFILLED'
const ASSESSMENT_UPDATE_REJECTED = 'remente/editMoodAssessmentModal/ASSESSMENT_UPDATE_REJECTED'

export const showCreateMoodAssessmentModal = createAction(MODAL_SHOW)
export const updateMoodAssessment = createAction(ASSESSMENT_UPDATE)
const updateMoodAssessmentFulfilled = createAction(ASSESSMENT_UPDATE_FULFILLED)
const updateMoodAssessmentRejected = createAction(ASSESSMENT_UPDATE_REJECTED)

/**
 * Epics
 */

const showCreateMoodAssessmentModalEpic = action$ => action$.pipe(
  ofType(showCreateMoodAssessmentModal().type),
  map(() => setCurrentModal({ currentModal: 'mood-assessment/edit' })),
)

const updateMoodAssessmentEpic = action$ => action$.pipe(
  ofType(updateMoodAssessment().type),
  map(({ payload }) => payload),
  mergeMap(assessment => of(closeModal()).pipe(concat(
    FirebaseApi
      .createOrUpdateMoodAssessment(assessment)
      .pipe(
        map(updateMoodAssessmentFulfilled),
        catchError(err => of(updateMoodAssessmentRejected(err))),
      ),
  ))),
)

export const editMoodAssessmentModalEpics = combineEpics(
  showCreateMoodAssessmentModalEpic,
  updateMoodAssessmentEpic,
)
