import React from 'react'
import { withTheme } from 'styled-components'
import { FormattedMessage } from 'react-intl'

import IconAdd from 'material-ui/svg-icons/content/add-circle'
import IconDashboard from 'material-ui/svg-icons/action/account-circle'
import IconResources from 'material-ui/svg-icons/social/school'
import IconPulse from 'material-ui/svg-icons/social/poll'
import IconMore from 'material-ui/svg-icons/navigation/menu'

import {
  IconTarget,
  CreateMenuContainer as CreateMenu,
  ProfileMenuContainer as ProfileMenu,
} from '../'

import {
  MinibarButton,
} from './components'

import {
  Nav,
  Separator,
} from './styles'

const MinibarClass = props => {

  const { isElectron, isPulseAvailable, theme } = props
  const { colorMinibarFg } = theme

  return (
    <Nav isElectron={ isElectron }>
      <ul>

        { isElectron && <Separator /> }

        <CreateMenu renderButton={ (Popover, handleClick) => (
          <MinibarButton
            icon={ <IconAdd color={ colorMinibarFg } style={ buttonStyle } /> }
            link="/create"
            exact={ true }
            onClick={ handleClick }
          >
            <FormattedMessage id="tabs_create" defaultMessage="Create" />
            { Popover }
          </MinibarButton>
        ) } />

        <Separator />

        <MinibarButton icon={ <IconDashboard color={ colorMinibarFg } style={ buttonStyle } /> } link="/" exact={ true }>
          <FormattedMessage id="tabs_me" defaultMessage="Me" />
        </MinibarButton>

        <MinibarButton icon={ <IconTarget color={ colorMinibarFg } style={ buttonStyle } /> } link="/achieve">
          <FormattedMessage id="tabs_achieve" defaultMessage="Goals" />
        </MinibarButton>

        <MinibarButton icon={ <IconResources color={ colorMinibarFg } style={ buttonStyle } /> } link="/resources">
          <FormattedMessage id="tabs_improve" defaultMessage="Resources" />
        </MinibarButton>

        <Separator />

        { isPulseAvailable && (
          <MinibarButton icon={ <IconPulse color={ colorMinibarFg } style={ buttonStyle } /> } link="/pulse">
            <FormattedMessage id="tabs_pulse" defaultMessage="Pulse" />
          </MinibarButton>
        ) }

        { isPulseAvailable && <Separator /> }

      </ul>
      <ul>

        <Separator />

        <ProfileMenu renderButton={ (Popover, handleClick) => (
          <MinibarButton
            icon={ <IconMore color={ colorMinibarFg } style={ buttonStyle } /> }
            link="/settings"
            exact={ true }
            onClick={ handleClick }
          >
            <FormattedMessage id="tabs_menu" defaultMessage="Menu" />
            { Popover }
          </MinibarButton>
        ) } />

      </ul>
    </Nav>
  )

}

const buttonStyle = {
  width: 30,
  height: 30,
}

const Minibar = withTheme(MinibarClass)

export { Minibar }
