import React, { Component } from 'react'

import { IconMoodSmiley } from '../'

class ScatterChartSmileyDataPoint extends Component {
  render() {
    const { x, y, datum } = this.props

    const width = 25
    const height = 25

    const { _y } = datum

    const iconProps = {
      x: x - width / 2,
      y: y - height / 2,
      width,
      height,
      rating: _y >= 3 ? Math.round(_y) : Math.floor(_y),
      isFilled: true,
    }

    return (
      <IconMoodSmiley
        { ...iconProps }
      />
    )
  }
}

export { ScatterChartSmileyDataPoint }
