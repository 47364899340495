import React from 'react'

import {
  KeyBinding,
  Key,
  KeySeparator,
} from './styles'

const renderKeys = (keys, separator) => {
  const result = []
  const len = keys.length
  keys.forEach((key, i) => {
    result.push(
      <Key key={ i }>
        { key }
      </Key>,
    )
    if (i + 1 < len)
      result.push(
        <KeySeparator key={ i + 'sep' }>
          { separator }
        </KeySeparator>,
      )
  })
  return result
}

const KeyBindingHint = ({
  binding,
  separator = '+',
}) => {

  const keys = binding.split('+')

  return (
    <KeyBinding>
      { renderKeys(keys, separator) }
    </KeyBinding>
  )

}

export { KeyBindingHint }
