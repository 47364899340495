import React from 'react'
import styled from 'styled-components'
import IconEdit from 'material-ui/svg-icons/editor/mode-edit'
import IconDone from 'material-ui/svg-icons/action/done'

import { FloatingCircleButton } from '../'

const Button = styled(FloatingCircleButton)`
  position: absolute !important;
  z-index: 5 !important;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
`

const FloatingCircleEditButton = ({ onClick, isEditModeActive, disabled }) => {
  const Icon = isEditModeActive ? <IconDone color="#fff" /> : <IconEdit />
  const color = isEditModeActive ? '#4bd2ac' : '#fff'

  return (
    <Button
      onClick={ onClick }
      disabled={ disabled }
      color={ color }
    >
      { Icon }
    </Button>
  )
}

export { FloatingCircleEditButton }
