import { connect } from 'react-redux'
import { GoalList } from './'
import { getPathname } from '../../ducks/route'
import { getOrganizationsById } from '../../ducks/organization'
import {
  getCollapsedGoalLists,
  changeGoalOrder,
  toggleGoalListCollapsed,
} from '../../ducks/goalNav'
import { getIsElectron } from '../../ducks/electron'

const mapStateToProps = (state, { organizationId }) => ({
  _forceRefresh: getPathname(state),
  organization: getOrganizationsById(state)[organizationId],
  isElectron: getIsElectron(state),
  isCollapsed: getCollapsedGoalLists(state)[organizationId],
})

const mapDispatchToProps = (dispatch, { organizationId }) => ({
  onOrderChange: payload => dispatch(changeGoalOrder(payload)),
  onListTitleClick: () => dispatch(toggleGoalListCollapsed({ organizationId })),
})

const GoalListContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  class GoalListContainer extends GoalList {},
)

export { GoalListContainer }
