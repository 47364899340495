import React, { Component } from 'react'
import Flipmove from 'react-flip-move'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'

import { dndShouldCancelStart } from '../../utils/dnd'
import { TodoTask } from '../'

const SortableTodoTask = SortableElement(task =>
  <TodoTask { ...task } />,
)

const SortableList = SortableContainer(({ isDragging, children }) => (
  <Flipmove
    duration={ 300 }
    easing="ease-out"
    disableAllAnimations={ isDragging }
    enterAnimation="fade"
    leaveAnimation={ false }
  >
    { children }
  </Flipmove>
))

class TodoList extends Component {

  state = {
    isDragging: false,
  }

  handleSortEnd(sortEvent) {
    const { onTaskOrderChange } = this.props
    const { oldIndex, newIndex } = sortEvent
    if (newIndex !== oldIndex)
      onTaskOrderChange({
        oldIndex,
        newIndex,
      })
    this.setState({ isDragging: false })
  }

  render() {
    const { isDragging } = this.state
    const {
      todoItems,
      selectedDateDateDiff,
      isPlanMode,
      onTaskCompletedToggle,
      onTaskPostpone,
      onTodoTaskDelete,
      onJourneyEntryEdit,
    } = this.props

    const isFuture = selectedDateDateDiff > 0
    const isPast = selectedDateDateDiff < 0

    const renderItem = ({ id, journeyEntryId, goalId, isCompleted, ...rest }, index) => (
      <SortableTodoTask
        id={ id }
        goalId={ goalId }
        key={ id + selectedDateDateDiff }
        index={ index }
        listIndex={ index }
        disabled={ isCompleted || isPast }
        isPlanMode={ isPlanMode }
        isCompleted={ isCompleted }
        isCheckinDisabled={ isFuture }
        onCompletedToggle={ () => onTaskCompletedToggle(id) }
        onPostpone={ postponeDate => onTaskPostpone({ taskId: id, goalId, postponeDate }) }
        onDeleteClick={ () => onTodoTaskDelete(id) }
        onJourneyEntryEditClick={ () => onJourneyEntryEdit({ journeyEntryId, goalId }) }
        { ...rest }
      />
    )

    return (
      <SortableList
        helperClass="dnd-dragging"
        isDragging={ isDragging }
        lockAxis="y"
        lockToContainerEdges={ true }
        useDragHandle={ false }
        shouldCancelStart={ dndShouldCancelStart }
        onSortStart={ () => this.setState({ isDragging: true }) }
        onSortEnd={ this.handleSortEnd.bind(this) }
      >
        { todoItems.map(renderItem) }
      </SortableList>
    )
  }

}

export { TodoList }
