import { connect } from 'react-redux'
import { GoalListsFilter } from './'

import {
  getIsShowCompleted,
  toggleShowCompleted,
} from '../../ducks/goalNav'

const mapStateToProps = state => ({
  isShowCompleted: getIsShowCompleted(state),
})

const mapDispatchToProps = dispatch => ({
  toggleShowCompleted: () => dispatch(toggleShowCompleted()),
})

const GoalListsFilterContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  class GoalListsFilterContainer extends GoalListsFilter {},
)

export { GoalListsFilterContainer }
