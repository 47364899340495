import { combineReducers } from 'redux'
import { combineEpics, ofType } from 'redux-observable'
import { createAction, handleActions } from 'redux-actions'
import { createSelector } from 'reselect'
import { of } from 'rxjs'
import { map, concat, mapTo, switchMap, flatMap, catchError } from 'rxjs/operators'
import { get } from 'lodash'
import { FirebaseApi } from '../../api'

import { setCurrentModal, closeModal } from '../app'
import { getDetailsGoal } from '../goalDetails'

const INITAL_STATE = {
  taskId: null,
  taskBeforeEdit: null,
}

const GOAL_TASK_EDIT             = 'remente/goal/task/edit/GOAL_TASK_EDIT'
const GOAL_TASK_UPDATE           = 'remente/goal/task/edit/GOAL_TASK_UPDATE'
const GOAL_TASK_UPDATE_FULFILLED = 'remente/goal/task/edit/GOAL_TASK_UPDATE_FULFILLED'
const GOAL_TASK_UPDATE_REJECTED  = 'remente/goal/task/edit/GOAL_TASK_UPDATE_REJECTED'
const GOAL_TASK_DELETE           = 'remente/goal/task/edit/GOAL_TASK_DELETE'
const GOAL_TASK_DELETE_FULFILLED = 'remente/goal/task/edit/GOAL_TASK_DELETE_FULFILLED'
const GOAL_TASK_DELETE_REJECTED  = 'remente/goal/task/edit/GOAL_TASK_DELETE_REJECTED'
const TASK_BEFORE_EDIT_SET       = 'remente/goal/task/edit/TASK_BEFORE_EDIT_SET'
const EDIT_GOAL_TASK_MODAL_SHOW  = 'remente/goal/task/edit/EDIT_GOAL_TASK_MODAL_SHOW'

const setTaskBeforeEdit       = createAction(TASK_BEFORE_EDIT_SET)
export const editGoalTask     = createAction(GOAL_TASK_EDIT)
export const updateGoalTask   = createAction(GOAL_TASK_UPDATE)
const updateGoalTaskFulfilled = createAction(GOAL_TASK_UPDATE_FULFILLED)
const updateGoalTaskRejected  = createAction(GOAL_TASK_UPDATE_REJECTED)
const showEditGoalTaskModal   = createAction(EDIT_GOAL_TASK_MODAL_SHOW)
export const deleteGoalTask   = createAction(GOAL_TASK_DELETE)
const deleteGoalTaskFulfilled = createAction(GOAL_TASK_DELETE_FULFILLED)
const deleteGoalTaskRejected  = createAction(GOAL_TASK_DELETE_REJECTED)

/**
 * Reducers
 */

const taskIdReducer = handleActions({
  [GOAL_TASK_EDIT]: (state, { payload }) => payload,
}, INITAL_STATE.taskId)

const taskBeforeEditReducer = handleActions({
  [TASK_BEFORE_EDIT_SET]: (state, { payload }) => payload,
}, INITAL_STATE.taskBeforeEdit)

export default combineReducers({
  taskId: taskIdReducer,
  taskBeforeEdit: taskBeforeEditReducer,
})

/**
 * Selectors
 */

const taskIdSelector         = ({ editGoalTask }) => editGoalTask.taskId
const taskBeforeEditSelector = ({ editGoalTask }) => editGoalTask.taskBeforeEdit

const getGoalTaskId = createSelector(
  taskIdSelector,
  id => id,
)

const getGoalTask = createSelector(
  getDetailsGoal,
  getGoalTaskId,
  (goal, taskId) => get(goal, ['tasksById', taskId]),
)

export const getTaskBeforeEdit = createSelector(
  taskBeforeEditSelector,
  task => task,
)

/**
 * Epics
 */

const editGoalTaskEpic = (action$, state$) => action$.pipe(
  ofType(GOAL_TASK_EDIT),
  flatMap(() => {
    const task  = getGoalTask(state$.value)
    return [
      setTaskBeforeEdit(task),
      showEditGoalTaskModal(),
    ]
  }),
)

const showEditGoalTaskModalEpic = action$ => action$.pipe(
  ofType(EDIT_GOAL_TASK_MODAL_SHOW),
  mapTo(setCurrentModal({
    currentModal: 'goal/task/edit',
  })),
)

const updateGoalTaskEpic = (action$, state$) => action$.pipe(
  ofType(GOAL_TASK_UPDATE),
  map(({ payload }) => payload),
  switchMap(task => {
    const { id, organizationId } = getDetailsGoal(state$.value)
    return FirebaseApi
      .updateGoalTask({ organizationId, goalId: id, task })
      .pipe(
        flatMap(() => [
          updateGoalTaskFulfilled(),
          closeModal(),
        ]),
        catchError(updateGoalTaskRejected),
      )
  }),
)

const deleteGoalTaskEpic = (action$, state$) => action$.pipe(
  ofType(GOAL_TASK_DELETE),
  switchMap(() => {
    const { id, organizationId } = getDetailsGoal(state$.value)
    const taskId = getGoalTaskId(state$.value)
    return of(closeModal()).pipe(concat(
      FirebaseApi
        .deleteGoalTask({ organizationId, goalId: id, id: taskId })
        .pipe(
          map(deleteGoalTaskFulfilled),
          catchError(err => of(deleteGoalTaskRejected(err))),
        ),
    ))
  }),
)

export const editGoalTaskEpics = combineEpics(
  editGoalTaskEpic,
  showEditGoalTaskModalEpic,
  updateGoalTaskEpic,
  deleteGoalTaskEpic,
)
