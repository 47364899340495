import React from 'react'
import styled, { keyframes } from 'styled-components'

const animationWrap = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(20px);
  }
`

const animationDot = keyframes`
  from {
    opacity: .8;
    transform: scale(.6) translateY(10px);
  }
  to {
    opacity: 1;
    transform: scale(1.1) translateY(0);
  }
`

const Wrap = styled.div`
  width: 34px;
  min-height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  animation: ${ animationWrap } 900ms ease-in-out infinite alternate;
`

const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: #7b7b93;
  opacity: 0;
  animation: ${ animationDot } 300ms ease-out infinite alternate;
  animation-delay: ${ ({ delay }) => delay ? delay : 0 }ms;
`

const LoadingIndicator = () => (
  <Wrap>
    <Dot />
    <Dot delay={ 100 } />
    <Dot delay={ 200 } />
  </Wrap>
)

export default LoadingIndicator
