import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Cover = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${ props => props.theme.colorAuthBg };
  overflow-y: auto;
`

export const Box = styled.div`
  width: 380px;
  background: ${ props => props.theme.colorAuthCardBg };
  border-radius: 4px;
  padding: 80px 0 50px 0;
  box-shadow:
    0 2px 15px 0 rgba(50, 50, 95, .1),
    0 1px 4px 0 rgba(0, 0, 0, .08);
  text-align: center;
  margin: 0 2em;
`

export const Logo = styled.div`
  text-align: center;
  img {
    width: 150px;
  }
`

export const Fields = styled.div`
  margin-top: 60px;
  margin-bottom: 20px;
`

export const Terms = styled.div`
  margin: 0 auto;
  margin-top: 2em;
  font-size: .8em;
  input {
    margin-right: .5em;
  }
`

export const LoginProviders = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
  button:hover {
    > div > div > div {
      background: ${ props => props.theme.colorPrimary1 } !important;
    }
    > div > span {
      color: ${ props => props.theme.colorPrimary1 } !important;
    }
  }
  li {
    display: inline-block;
  }
`

export const Subtle = styled.p`
  font-size: .8em;
  color: ${ props => props.theme.colorBodySubtleFg };
  margin-top: 35px;
  margin-bottom: 5px;
`

export const Buttons = styled.div`
  > div {
    margin-left: 10px;
  }
`

export const BottomLink = styled(Link)`
  margin-top: 2em;
  color: ${ ({ theme }) => theme.colorFadedDark };
  font-size: .8em;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
`
