import Loadable from 'react-loadable'
import LoadingIndicatorFull from '../LoadingIndicatorFull.js'

const LoadableDashboardDemo = Loadable({
  loader: () => import('./DashboardDemo.js'),
  loading: LoadingIndicatorFull,
})

export default LoadableDashboardDemo

