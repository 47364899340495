import React from 'react'

const IconTarget = ({ color = '#000000', ...rest }) => (
  <svg { ...rest } viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>IconTarget</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="IconTarget" fillRule="nonzero" fill={ color }>
        <circle id="Oval" cx="12" cy="12" r="2"></circle>
        <path d="M12,6 C8.7,6 6,8.7 6,12 C6,15.3 8.7,18 12,18 C15.3,18 18,15.3 18,12 C18,8.7 15.3,6 12,6 Z M12,15.9 C9.8,15.9 8.1,14.1 8.1,12 C8.1,9.9 9.8,8.1 12,8.1 C14.2,8.1 15.9,9.9 15.9,12 C15.9,14.1 14.2,15.9 12,15.9 Z" id="Shape"></path>
        <path d="M12,2 C6.5,2 2,6.5 2,12 C2,17.5 6.5,22 12,22 C17.5,22 22,17.5 22,12 C22,6.5 17.5,2 12,2 Z M12,19.9 C7.6,19.9 4.1,16.3 4.1,12 C4.1,7.7 7.6,4.1 12,4.1 C16.4,4.1 19.9,7.7 19.9,12 C19.9,16.3 16.4,19.9 12,19.9 Z" id="Shape"></path>
      </g>
    </g>
  </svg>
)

export { IconTarget }
