import { connect } from 'react-redux'
import { get } from 'lodash'
import BoostScene from './BoostScene.js'
import { updateUserHistory } from '../ducks/userContent'

const mapStateToProps = ({ content }, { match }) => {
  const { boostsById } = content
  const { language, boostId } = match.params
  const boost = boostsById[boostId]
  const imageUrl = get(boost, 'images.featured.servingUrl')

  return {
    ...boost,
    imageUrl,
    language,
    loading: boost === undefined,
  }
}

const mapDispatchToProps = dispatch => ({
  updateUserHistory: options => dispatch(updateUserHistory(options)),
})

class BoostSceneContainerClass extends BoostScene {}

const BoostSceneContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BoostSceneContainerClass)

export default BoostSceneContainer
