import React from 'react'
import { connect } from 'react-redux'
import { GoalTask } from '../'
import { getMatchedTasksById, getSelectedTaskId } from '../../ducks/goalNav'

const UserGoalNavTaskItem = ({ task, onAddToPlanClick, isMatched, isSelected }) => isMatched ? (
  <div>
    <GoalTask
      onAddToPlanClick={ onAddToPlanClick }
      isCompact={ true }
      isSelected={ isSelected }
      { ...task }
    />
  </div>
) : <div />

const mapStateToProps = (state, { task }) => ({
  isMatched: getMatchedTasksById(state)[task.id],
  isSelected: getSelectedTaskId(state) === task.id,
})

const ConnectedUserGoalNavTaskItem = connect(mapStateToProps)(UserGoalNavTaskItem)

export { ConnectedUserGoalNavTaskItem as UserGoalNavTaskItem }
