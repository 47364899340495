import React, { Component } from 'react'
import IconTodoTasks from 'material-ui/svg-icons/action/done-all'
import IconCreateNew from 'material-ui/svg-icons/content/add'
import IconLink from 'material-ui/svg-icons/content/link'
import { FormattedMessage } from 'react-intl'

import {
  Wrap,
  Link,
  IceboxTitleContent,
  Thumbnail,
  GoalTitle,
  Icon,
  Prepend,
  AddIconWrap,
  OriginWrap,
} from './styles'

const smallIconStyle = {
  width: 16,
  height: 16,
  marginRight: 3,
}

class SidebarNavItem extends Component {

  render() {
    const {
      isMaster,
      isCompact,
      isDiscrete,
      noScrollbar,
      thumbnailUrl,
      href,
      title,
      prepend,
      children,
      originGoalTitle,
    } = this.props

    const origin = originGoalTitle && (
      <OriginWrap>
        <IconLink style={ smallIconStyle } /> { originGoalTitle }
      </OriginWrap>
    )

    const itemContent = [
      !isMaster && href && <Thumbnail key={ 3 } />,
      <Prepend prepend={ prepend } key={ 0 }>
        { prepend && prepend }
      </Prepend>,
      <GoalTitle key={ 1 }>
        { href ? (
          <div>
            { title }
            { origin }
          </div>
        ) : (
          <FormattedMessage
            id="dayplanner_label_todos_title"
            defaultMessage="To-Do"
          />
        ) }
      </GoalTitle>,
      <Icon key={ 2 }/>,
    ]

    const addIcon = isDiscrete && (
      <AddIconWrap>
        <IconCreateNew />
      </AddIconWrap>
    )

    const linkContent = href ? (
      <Link
        to={ href }
        replace
        exact
      >
        { itemContent }
        { addIcon }
      </Link>
    ) : (
      <IceboxTitleContent>
        <IconTodoTasks color="#afb3bc" />
        { itemContent }
      </IceboxTitleContent>
    )

    return (
      <Wrap
        isMaster={ isMaster }
        isCompact={ isCompact }
        isDiscrete={ isDiscrete }
        noScrollbar={ noScrollbar }
        thumbnailUrl={ thumbnailUrl }
      >

        { linkContent }

        { children }

      </Wrap>
    )
  }

}

export { SidebarNavItem }
