import styled from 'styled-components'

export const Wrap = styled.div`
  margin-top: .3em;
  display: flex;
  flex-wrap: wrap;
`

export const ProgressWrap = styled.div`
  width: 30px;
  margin-right: 4px;
  position: relative;
  cursor: help;
  transition: all 150ms ease-out;
  transform: scale(.8);
  &:hover {
    transform: scale(1.1);
  }
`

export const Initials = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  font-size: 8px;
  font-weight: 600;
  color: ${ ({ color }) => color };
`
