import React, { Component } from 'react'
import Mousetrap from 'mousetrap'
import { connect } from 'react-redux'
import {
  defineMessages,
  injectIntl,
} from 'react-intl'

import { AddListItemInput } from '../'

import {
  getTaskTitleInputValue,
  setTaskTitleInputValue,
  addGoalTask,
} from '../../ducks/goalDetails'

const mapStateToProps = state => ({
  value: getTaskTitleInputValue(state),
})

const mapDispatchToProps = dispatch => ({
  onTitleChange: title => dispatch(setTaskTitleInputValue(title)),
  onSubmit: title => dispatch(addGoalTask(title)),
})

const hotkeys = {
  enter: ['enter'],
}

const translations = defineMessages({
  placeholder: {
    id: 'goal_plan_add_step',
    defaultMessage: 'Add a step',
  },
})

class AddGoalTaskInputContainerClass extends Component {

  componentDidMount() {
    Mousetrap.bind(hotkeys.enter, this.handleOnSubmit)
  }

  componentWillUnmount() {
    Mousetrap.unbind(hotkeys.enter, this.handleOnSubmit)
  }

  handleOnSubmit = () => {
    const { onSubmit, value } = this.props
    if (value && value.length > 0)
      onSubmit(value)
    this.input.focus()
  }

  handleOnChange = e => {
    const { onTitleChange } = this.props
    onTitleChange(e.target.value)
  }

  render() {
    const { value, intl } = this.props
    const buttonProps = {
      onClick: this.handleOnSubmit,
    }
    const setInputRef = input => this.input = input

    const placeholderTranslation = intl.formatMessage(translations.placeholder)

    return (
      <AddListItemInput
        value={ value }
        placeholder={ placeholderTranslation }
        onChange={ this.handleOnChange }
        className="mousetrap"
        buttonProps={ buttonProps }
        innerRef={ setInputRef }
      />
    )
  }

}

const AddGoalTaskInputContainer = injectIntl(connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddGoalTaskInputContainerClass))

export { AddGoalTaskInputContainer }
