import styled from 'styled-components'

const TextContent = styled.div`
  font-family: "Book Antiqua", Palatino, "Palatino Linotype", "Palatino LT STD", Georgia, serif;
  line-height: 1.7em;
  font-size: 20px;

  > *:first-child {
    margin-top: 0;
  }

  > p:first-child {
    margin-top: 3%;
  }

  hr {
    border: 0;
    border-bottom: 1px solid #eee;
    background: #999;
    margin: 2em 1em;
  }

  blockquote,
  em,
  i {
      font-style: italic
  }
  *,
  :after,
  :before {
      box-sizing: inherit
  }
  figcaption,
  h3,
  h4 {
      font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
      line-height: 1.4em;
  }
  h3,
  h4,
  p {
      padding: 0
  }
  figure,
  ol,
  ul {
      margin: 1.5em 1em
  }
  h3,
  h4 {
      color: #313131;
      margin: 2em 0 1em 0
  }
  h3 {
      font-size: 1.5em
  }
  h4 {
      font-size: 1.2em
  }
  li {
      margin: 0 0 .5em
      padding-left: .5em
  }
  ul li {
      list-style: circle
  }
  ol li {
      list-style: decimal
  }
  blockquote {
      margin: 0;
      font-size: 1.3em;
      padding: 1em 1.5em;
      border: none;
      // text-indent: -.4em;
      color: rgba(0,0,0,.6);
  }
  b,
  strong {
      font-weight: 600
  }
  figcaption {
      text-align: center;
      color: #999;
      font-size: .8em;
      padding: 0 1em
  }
  img {
    display: block;
    margin: 2em auto;
  }
`

export { TextContent }
