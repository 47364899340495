import React from 'react'
import styled from 'styled-components'

const Wrap = styled.span`
  ${ ({ theme, isPositive }) => `
  display: inline-block;
  margin-right: .5em;
  font-size: 10px;
  text-transform: uppercase;
  color: ${ isPositive ? theme.colorDiffPositive : theme.colorDiffNegative };
  font-weight: 600;
  ` }
`

const MoodTag = ({
  title,
  ...rest
}) => (
  <Wrap { ...rest }>
    { title }
  </Wrap>
)

export { MoodTag }
