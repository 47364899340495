import styled, { css } from 'styled-components'

const fontFamilyRedacted = css`
  font-family: "Redacted";
  color: red !important;
`

const BlockFont = styled.div`
  ${ props => props.when ? fontFamilyRedacted : undefined }
`

export { BlockFont }
