import React from 'react'
import styled from 'styled-components'

const TextValueButtonWrap = styled.button`
  ${ ({ theme }) => `
    border: none;
    background: none;
    font-weight: 600;
    color: ${ theme.colorPrimary1 };
    cursor: pointer;
    &:hover,
    &:focus {
      color: ${ theme.colorBodyFg };
      background: ${ theme.colorMaskedInputBg };
    }
  ` }
`

const TextValueButton = ({ value, children, ...rest }) => (
  <TextValueButtonWrap { ...rest }>
    { children || value }
  </TextValueButtonWrap>
)

export { TextValueButton }
