import { combineReducers } from 'redux'
import { combineEpics, ofType } from 'redux-observable'
import { createAction, handleActions } from 'redux-actions'
import { createSelector } from 'reselect'
import { get, values, keyBy, flatten, flattenDeep, forOwn } from 'lodash'
import { of, EMPTY, combineLatest } from 'rxjs'
import { map, switchMap, catchError, startWith } from 'rxjs/operators'

import { FirebaseApi } from '../../api'
import { createEnsureAuthenticatedEpic } from '../../utils/epic'
import { getAuthenticatedUserId } from '../auth'

const setOrganizationsByUser                            = createAction('remente/organization/ORGANIZATIONS_BY_USER_SET')
const setOrganizationsByUserRejected                    = createAction('remente/organization/ORGANIZATIONS_BY_USER_SET_REJECTED')
export const setOrganizations                           = createAction('remente/organization/ORGANIZATIONS_SET')
const setOrganizationsRejected                          = createAction('remente/organization/ORGANIZATIONS_SET_REJECTED')
const setOrganizationGoalsById                          = createAction('remente/organization/GOALS_BY_ORGANIZATION_ID_SET')
const setGoalsByOrganizationIdRejected                  = createAction('remente/organization/GOALS_BY_ORGANIZATION_ID_SET_REJECTED')
const setUsersByOrganizationId                          = createAction('remente/organization/USERS_BY_ORGANIZATION_ID_SET')
const setUsersByOrganizationIdRejected                  = createAction('remente/organization/USERS_BY_ORGANIZATION_ID_SET_REJECTED')
const setOrganizationGoalJourneyEntriesByGoalId         = createAction('remente/organization/JOURNAL_ENTRIES_SET')
const setOrganizationGoalJourneyEntriesByGoalIdRejected = createAction('remente/organization/JOURNAL_ENTRIES_SET_REJECTED')
const setUserOrganizationGoalIds                        = createAction('remente/organization/USER_ORGANIZATION_GOAL_IDS_SET')
const setUserOrganizationGoalIdsRejected                = createAction('remente/organization/USER_ORGANIZATION_GOAL_IDS_SET_REJECTED')
const setOrganizationOriginGoalsById                    = createAction('remente/organization/ORIGIN_GOALS_BY_ID_SET')
const setOrganizationOriginGoalsByIdRejected            = createAction('remente/organization/ORIGIN_GOALS_BY_ID_SET_REJECTED')

// hotfix(12/9-18): unable to import from ../auth
const userUnauthenticated = createAction('remente/auth/USER_UNAUTHENTICATED')

/**
 * Reducers
 */

const organizationsByUserReducer = handleActions({
  [setOrganizationsByUser]: (state, { payload }) => payload.organizationsByUser,
  [userUnauthenticated]: () => ({}),
}, {})

const organizationsByIdReducer = handleActions({
  [setOrganizations]: (state, { payload }) => payload,
  [userUnauthenticated]: () => ({}),
}, {})

const organizationGoalsByIdReducer = handleActions({
  [setOrganizationGoalsById]: (state, { payload }) => payload,
  [userUnauthenticated]: () => ({}),
}, {})

const usersByOrganizationIdReducer = handleActions({
  [setUsersByOrganizationId]: (state, { payload }) => payload,
  [userUnauthenticated]: () => ({}),
}, {})

const userOrganizationGoalIdsReducer = handleActions({
  [setUserOrganizationGoalIds]: (state, { payload }) => payload,
  [userUnauthenticated]: () => ({}),
}, {})

const organizationGoalJourneyEntriesByGoalIdReducer = handleActions({
  [setOrganizationGoalJourneyEntriesByGoalId]: (state, { payload }) => payload,
  [userUnauthenticated]: () => ({}),
}, {})

const organizationOriginGoalsByIdReducer = handleActions({
  [setOrganizationOriginGoalsById]: (state, { payload }) => payload,
  [userUnauthenticated]: () => ({}),
}, {})

export default combineReducers({
  organizationsByUser: organizationsByUserReducer,
  organizationsById: organizationsByIdReducer,
  organizationGoalsById: organizationGoalsByIdReducer,
  usersByOrganizationId: usersByOrganizationIdReducer,
  userOrganizationGoalIds: userOrganizationGoalIdsReducer,
  organizationGoalJourneyEntriesByGoalId: organizationGoalJourneyEntriesByGoalIdReducer,
  organizationOriginGoalsById: organizationOriginGoalsByIdReducer,
})

/**
 * Selectors
 */

export const getOrganizationsById = ({ organization }) => organization.organizationsById
export const getOrganizationGoalsById = ({ organization }) => organization.organizationGoalsById
export const getUsersByOrganizationId = ({ organization }) => organization.usersByOrganizationId
export const getUserOrganizationGoalIds = ({ organization }) => organization.userOrganizationGoalIds
export const getOrganizationGoalJourneyEntriesByGoalId = ({ organization }) => organization.organizationGoalJourneyEntriesByGoalId
export const getOrganizationOriginGoalsById = ({ organization }) => organization.organizationOriginGoalsById
export const getMyOrganizations = createSelector(getOrganizationsById, orgs => values(orgs))
export const getMyOrganizationIds = createSelector(getMyOrganizations, orgs => orgs.map(({ id }) => id))
export const getHasOrganizations = createSelector(getMyOrganizations, orgs => orgs.length > 0)

export const getOrganizationGoals = createSelector(
  getOrganizationGoalsById,
  goals => values(goals),
)

export const getUsersArrByOrganizationId = createSelector(
  getUsersByOrganizationId,
  usersByOrganizationId => {
    const result = {}
    forOwn(usersByOrganizationId, (organizationUsers, id) => {
      const users = []
      forOwn(organizationUsers, (user, id) => users.push({ ...user, id }))
      result[id] = users
    })
    return result
  },
)

export const getOriginGoalTitle = (state, goalId) => get(getOrganizationOriginGoalsById(state), [goalId, 'title'])

/**
 * Epics
 */

const organizationsByUserEpic = createEnsureAuthenticatedEpic({
  createObservables: ({ uid }) => ({
    organizationsByUser: FirebaseApi.observableRef(`organizations-by-user/${ uid }`),
  }),
  actionFulfilled: setOrganizationsByUser,
  actionRejected: setOrganizationsByUserRejected,
})

const organizationsEpic = action$ => action$.pipe(
  ofType(setOrganizationsByUser().type),
  map(({ payload }) => payload.organizationsByUser),
  switchMap(organizationsByUser => {
    if (!organizationsByUser) return EMPTY
    const userOrganizationIds = Object.keys(organizationsByUser || {})
    if (userOrganizationIds.length === 0) return EMPTY

    const observables = userOrganizationIds.map(id =>
      FirebaseApi.observableRef(`organizations/${ id }`),
    )

    return combineLatest(observables).pipe(
      map(res => setOrganizations(keyBy(res, 'id'))),
      catchError(err => of(setOrganizationsRejected(err))),
    )
  }),
)

const usersByOrganizationIdEpic = action$ => action$.pipe(
  ofType(setOrganizationsByUser().type),
  map(({ payload }) => payload.organizationsByUser),
  switchMap(organizationsByUser => {
    if (!organizationsByUser) return EMPTY
    const userOrganizationIds = Object.keys(organizationsByUser)

    const observables = userOrganizationIds.map(id => FirebaseApi
      .observableRef(`organization-users/${ id }`)
      .pipe(startWith({})),
    )

    return combineLatest(observables).pipe(
      map(res => {
        const result = {}
        res.forEach((users, i) => result[userOrganizationIds[i]] = users)
        return setUsersByOrganizationId(result)
      }),
      catchError(err => of(setUsersByOrganizationIdRejected(err))),
    )
  }),
)

const userOrganizationGoalIdsEpic = (action$, state$) => action$.pipe(
  ofType(setOrganizationsByUser().type),
  map(({ payload }) => payload.organizationsByUser),
  switchMap(organizationsByUser => {
    if (!organizationsByUser) return EMPTY
    const userOrganizationIds = Object.keys(organizationsByUser)
    const uid = getAuthenticatedUserId(state$.value)

    const observables = userOrganizationIds.map(organizationId => FirebaseApi
      .observableRef(`organization-goals-by-user/${ organizationId }/${ uid }`)
      .pipe(map(goals => ({ organizationId, goals }))),
    )

    return combineLatest(observables).pipe(
      map(res => keyBy(flatten(res.filter(it => it)), 'organizationId')),
      map(setUserOrganizationGoalIds),
      catchError(err => of(setUserOrganizationGoalIdsRejected(err))),
    )
  }),
)

const goalsByOrganizationIdEpic = action$ => action$.pipe(
  ofType(setUserOrganizationGoalIds().type),
  map(({ payload }) => payload),
  switchMap(userOrganizationGoalIds => {
    if (!userOrganizationGoalIds) return EMPTY

    const observables = values(userOrganizationGoalIds)
      .filter(({ goals }) => goals)
      .map(({ organizationId, goals }) => {
        const goalObservables = Object.keys(goals).map(goalId => FirebaseApi
          .observableRef(`goals/${ organizationId }/${ goalId }`),
        )
        return combineLatest(goalObservables).pipe(
          map(goals => goals
            .filter(goal => goal)
            .map(goal => ({ ...goal, organizationId })),
          ),
        )
      })

    return combineLatest(observables).pipe(
      map(res => {
        const goals = flattenDeep(res.filter(it => it))
        const goalsById = keyBy(goals, 'id')
        return setOrganizationGoalsById(goalsById)
      }),
      catchError(err => of(setGoalsByOrganizationIdRejected(err))),
    )
  }),
)

const organizationGoalJourneyEntriesEpic = action$ => action$.pipe(
  ofType(setUserOrganizationGoalIds().type),
  map(({ payload }) => payload),
  switchMap(userOrganizationGoalIds => {
    if (!userOrganizationGoalIds) return EMPTY

    const observables = []
    const goalIds = []
    values(userOrganizationGoalIds)
      .filter(({ goals }) => goals)
      .forEach(({ organizationId, goals }) => {
        Object.keys(goals).forEach(goalId => {
          goalIds.push(goalId)
          observables.push(FirebaseApi
            .observableRef(`goal-journey-entries/${ organizationId }/${ goalId }`),
          )
        })
      })

    return combineLatest(observables).pipe(
      map(res => {
        const result = {}
        goalIds.forEach((goalId, i) => { result[goalId] = res[i] || {} })
        return setOrganizationGoalJourneyEntriesByGoalId(result)
      }),
      catchError(err => of(setOrganizationGoalJourneyEntriesByGoalIdRejected(err))),
    )
  }),
)

const originGoalsEpic = action$ => action$.pipe(
  ofType(setOrganizationGoalsById().type),
  map(({ payload }) => payload),
  switchMap(goalsById => {
    const observables = values(goalsById)
      .filter(({ origin }) => origin)
      .map(({ organizationId, origin }) => FirebaseApi
        .observableRef(`goals/${ organizationId }/${ origin.goalId }`)
        .pipe(startWith(null)),
      )

    return combineLatest(observables).pipe(
      map(res => {
        const goals = keyBy(res.filter(it => it), 'id')
        return setOrganizationOriginGoalsById(goals)
      }),
      catchError(err => of(setOrganizationOriginGoalsByIdRejected(err))),
    )
  }),
)

export const organizationEpics = combineEpics(
  organizationsByUserEpic,
  organizationsEpic,
  usersByOrganizationIdEpic,
  userOrganizationGoalIdsEpic,
  goalsByOrganizationIdEpic,
  organizationGoalJourneyEntriesEpic,
  originGoalsEpic,
)
