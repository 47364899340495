import { combineReducers } from 'redux'
import { combineEpics } from 'redux-observable'
import { createAction, handleActions } from 'redux-actions'
import { createSelector } from 'reselect'
import { values } from 'lodash'

import { FirebaseApi } from '../../api'
import { createEnsureAuthenticatedEpic } from '../../utils/epic'

const AVAILABLE_LANGUAGES_SET = 'remente/language/AVAILABLE_LANGUAGES_SET'
export const setAvailableLanguages = createAction(AVAILABLE_LANGUAGES_SET)

/**
 * Reducers
 */

const availableLanguagesReducer = handleActions({
  [setAvailableLanguages]: (state, { payload: { languages } }) => languages,
}, null)

export default combineReducers({
  availableLanguages: availableLanguagesReducer,
})

/**
 * Selectors
 */

const availableLanguagesSelector = ({ language }) => language.availableLanguages

export const getAvailableLanguages = createSelector(
  availableLanguagesSelector,
  languages => values(languages),
)

/**
 * Epics
 */

const availableLanguagesEpic = createEnsureAuthenticatedEpic({
  createObservables: () => ({
    languages: FirebaseApi.observableRef('languages'),
  }),
  actionFulfilled: setAvailableLanguages,
})

export const languageEpics = combineEpics(
  availableLanguagesEpic,
)
