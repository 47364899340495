import { combineReducers } from 'redux'
import { combineEpics, ofType } from 'redux-observable'
import { map, mergeMap } from 'rxjs/operators'
import { createAction, handleActions } from 'redux-actions'
import { createSelector } from 'reselect'
import { get, values } from 'lodash'

import { FirebaseApi } from '../../api'
import { LIFE_WHEEL_ID } from '../../constants'
import { changeLanguage } from '../app'

const INITAL_STATE = {
  lifeWheel: null,
}

const LIFE_WHEEL_CHANGE = 'remente/wheel/LIFE_WHEEL_CHANGE'

const changeLifeWheel = createAction(LIFE_WHEEL_CHANGE)

/**
 * Reducers
 */

const lifeWheelReducer = handleActions({
  [changeLifeWheel]: (state, { payload }) => payload,
}, INITAL_STATE.lifeWheel)

export default combineReducers({
  lifeWheel: lifeWheelReducer,
})

/**
 * Selectors
 */

const lifeWheelSelector = ({ wheel }) => wheel.lifeWheel

export const getLifeWheel = createSelector(
  lifeWheelSelector,
  lifeWheel => lifeWheel,
)

export const getLifeWheelCategoriesById = createSelector(
  getLifeWheel,
  lifeWheel => get(lifeWheel, 'wheelCategories'),
)

export const getLifeWheelCategories = createSelector(
  getLifeWheelCategoriesById,
  categoriesById => values(categoriesById),
)

/**
 * Epics
 */

const lifeWheelEpic = action$ => action$.pipe(
  ofType(changeLanguage().type),
  map(({ payload }) => payload.language),
  mergeMap(language => FirebaseApi
    .observableRef(`wheels/${ language }/${ LIFE_WHEEL_ID }`)
    .pipe(map(wheel => changeLifeWheel(wheel))),
  ),
)

export const wheelEpics = combineEpics(
  lifeWheelEpic,
)
