import React, { Component } from 'react'

import {
  Overlay,
  ModalBox,

  ModalPadding,
  ModalHeader,
  ModalTitle,
  ModalSubtle,
  ModalFooter,
} from './styles'

class Modal extends Component {

  handleClose = ({ e, overlay }) => {
    const { onClose } = this.props
    if (!onClose || e.currentTarget !== e.target) return
    onClose({ overlay })
  }

  render() {
    const { children, fullWidth } = this.props
    return (
      <Overlay onClick={ e => this.handleClose({ e, overlay: true }) }>
        <ModalBox fullWidth={ fullWidth }>
          { children }
        </ModalBox>
      </Overlay>
    )
  }

}

export {
  Modal,
  ModalPadding,
  ModalHeader,
  ModalTitle,
  ModalSubtle,
  ModalFooter,
}
