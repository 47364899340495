import { combineReducers } from 'redux'
import { combineEpics, ofType } from 'redux-observable'
import { createAction, handleActions } from 'redux-actions'
import { createSelector } from 'reselect'
import { get, set, values } from 'lodash'
import { of } from 'rxjs'
import { map, concat, switchMap, mergeMap, takeUntil, catchError, delay } from 'rxjs/operators'
import firstBy from 'thenby'
import moment from 'moment'
import { FirebaseApi } from '../../api'

import { LIFE_WHEEL_ID } from '../../constants'

import { redirectRoute, historyChanged } from '../route'
import { getLifeWheelCategories } from '../wheel'
import {
  getMyLifeWheelAssessments,
  getNumMyLifeWheelAssessments,
  changeMyWheelAssessments,
} from '../wheelAssessment'

const INITAL_STATE = {
  slectedAssessmentIndex: 0,
  sliceHighlight: {
    sliceIndex: -1,
    arcIndex: -1,
  },
  newRatings: {},
}

const NAVIGATE_AND_CREATE              = 'remente/lifeWheel/NAVIGATE_AND_CREATE'
const EDIT_MODE_TOGGLE                 = 'remente/lifeWheel/EDIT_MODE_TOGGLE'
const SELECTED_ASSESSMENT_INDEX_CHANGE = 'remente/lifeWheel/SELECTED_ASSESSMENT_INDEX_CHANGE'
const SELECTED_ASSESSMENT_INDEX_RESET  = 'remente/lifeWheel/SELECTED_ASSESSMENT_INDEX_RESET'
const ARC_FOCUS                        = 'remente/lifeWheel/ARC_FOCUS'
const ARC_BLUR                         = 'remente/lifeWheel/ARC_BLUR'
const WHEEL_CATEGORY_RATE              = 'remente/lifeWheel/WHEEL_CATEGORY_RATE'
const WHEEL_CATEGORY_RATE_REJECTED     = 'remente/lifeWheel/WHEEL_CATEGORY_RATE_REJECTED'
const HIGHLIGHT_RESET                  = 'remente/lifeWheel/HIGHLIGHT_RESET'
const RATINGS_SET                      = 'remente/lifeWheel/RATINGS_SET'
const RATINGS_SAVE                     = 'remente/lifeWheel/RATINGS_SAVE'
const RATINGS_SAVE_PENDING             = 'remente/lifeWheel/RATINGS_SAVE_PENDING'
const RATINGS_SAVE_FULFILLED           = 'remente/lifeWheel/RATINGS_SAVE_FULFILLED'
const RATINGS_SAVE_REJECTED            = 'remente/lifeWheel/RATINGS_SAVE_REJECTED'

export const navigateAndCreateLifeAssessment = createAction(NAVIGATE_AND_CREATE)
export const toggleEditMode                  = createAction(EDIT_MODE_TOGGLE)
export const changeSelectedAssessmentIndex   = createAction(SELECTED_ASSESSMENT_INDEX_CHANGE)
export const resetSelectedAssessmentIndex    = createAction(SELECTED_ASSESSMENT_INDEX_RESET)
export const focusArc                        = createAction(ARC_FOCUS)
export const blurArc                         = createAction(ARC_BLUR)
export const rateWheelCategory               = createAction(WHEEL_CATEGORY_RATE)
const rateWheelCategoryRejected              = createAction(WHEEL_CATEGORY_RATE_REJECTED)
const resetHighlight                         = createAction(HIGHLIGHT_RESET)
const setRatings                             = createAction(RATINGS_SET)
export const saveRatings                     = createAction(RATINGS_SAVE)
const saveRatingsPending                     = createAction(RATINGS_SAVE_PENDING)
const saveRatingsFulfilled                   = createAction(RATINGS_SAVE_FULFILLED)
const saveRatingsRejected                    = createAction(RATINGS_SAVE_REJECTED)

/**
 * Reducers
 */

const isEditModeReducer = handleActions({
  [toggleEditMode]: state => !state,
  [saveRatings]: () => false,
  [historyChanged]: () => false,
}, false)

const selectedAssessmentIndexReducer = handleActions({
  [changeSelectedAssessmentIndex]: (state, { payload }) => payload,
  [changeMyWheelAssessments]: (state, { payload }) => {
    const myLifeWheelAssessments = get(payload, ['myWheelAssessments', LIFE_WHEEL_ID], {})
    return Math.max(0, Object.keys(myLifeWheelAssessments).length - 1)
  },
}, INITAL_STATE.slectedAssessmentIndex)

const sliceHighlightReducer = handleActions({
  [focusArc]: (state, { payload }) => payload,
  [resetHighlight]: () => INITAL_STATE.sliceHighlight,
}, INITAL_STATE.sliceHighlight)

const newRatingsReducer = handleActions({
  [setRatings]: (state, { payload }) => payload,
  [saveRatingsPending]: () => INITAL_STATE.newRatings,
}, INITAL_STATE.newRatings)

export default combineReducers({
  isEditMode: isEditModeReducer,
  slectedAssessmentIndex: selectedAssessmentIndexReducer,
  sliceHighlight: sliceHighlightReducer,
  newRatings: newRatingsReducer,
})

/**
 * Selectors
 */

const isEditModeSelector              = ({ lifeWheel }) => lifeWheel.isEditMode
const selectedAssessmentIndexSelector = ({ lifeWheel }) => lifeWheel.slectedAssessmentIndex
const sliceHighlightSelector          = ({ lifeWheel }) => lifeWheel.sliceHighlight
const newRatingsSelector              = ({ lifeWheel }) => lifeWheel.newRatings

export const getIsEditMode              = createSelector(isEditModeSelector, value => value)
export const getSelectedAssessmentIndex = createSelector(selectedAssessmentIndexSelector, value => value)
export const getSliceHighlight          = createSelector(sliceHighlightSelector, value => value)
const getNewRatings                     = createSelector(newRatingsSelector, value => value)

const selectedLifeWheelAssessmentIndexSelector = createSelector(
  getMyLifeWheelAssessments,
  getIsEditMode,
  getSelectedAssessmentIndex,
  (assessments, isEditMode, value) => isEditMode ? assessments.length : value,
)

export const getSelectedLifeWheelAssessment = createSelector(
  selectedLifeWheelAssessmentIndexSelector,
  getMyLifeWheelAssessments,
  (index, assessments) => assessments[index],
)

export const getSelectedLifeWheelAssessmentCreatedAt = createSelector(
  getSelectedLifeWheelAssessment,
  assessment => get(assessment, 'createdAt'),
)

export const getFirstAssessmentTimestamp = createSelector(
  getMyLifeWheelAssessments,
  assessments => get(assessments, [0, 'createdAt']),
)

export const getSelectedLifeWheelAssessmentRatingsById = createSelector(
  getSelectedLifeWheelAssessment,
  assessment => get(assessment, 'ratings', {}),
)

export const getMostRecentLifeWheelAssessment = createSelector(
  getMyLifeWheelAssessments,
  assessments => assessments[assessments.length - 1],
)

export const getMostRecentLifeWheelAssessmentRatings = createSelector(
  getMostRecentLifeWheelAssessment,
  assessment => get(assessment, 'ratings', {}),
)

export const getTodaysLifeWheelAssessmentId = createSelector(
  getMostRecentLifeWheelAssessment,
  assessment => {
    if (!assessment) return
    if (moment().isSame(assessment.createdAt, 'day'))
      return assessment.id
  },
)

const lifeWheelCategoriesSortedWithRatingsSelector = createSelector(
  getLifeWheelCategories,
  getSelectedLifeWheelAssessmentRatingsById,
  (categories, ratingsById) => categories
    .sort(firstBy(({ sliceIndex }) => sliceIndex))
    .map(category => ({
      ...category,
      value: ratingsById[category.id],
    })),
)

export const getSliderMaxValue = createSelector(
  getNumMyLifeWheelAssessments,
  num => num,
)

const getHighlightedWheelCategory = createSelector(
  getSliceHighlight,
  getLifeWheelCategories,
  ({ sliceIndex }, categories) => get(categories, [sliceIndex]),
)

const getHighlightedWheelCategoryId = createSelector(
  getHighlightedWheelCategory,
  category => get(category, 'id'),
)

export const getHighlightedWheelCategoryTitle = createSelector(
  getHighlightedWheelCategory,
  category => get(category, 'title'),
)

export const getHighlightedWheelCategoryDescription = createSelector(
  getHighlightedWheelCategory,
  category => get(category, 'description'),
)

const getSliceHighlightRating = createSelector(
  getSliceHighlight,
  ({ arcIndex }) => arcIndex + 1,
)

export const getRatingDiff = createSelector(
  getMostRecentLifeWheelAssessmentRatings,
  getHighlightedWheelCategoryId,
  getSliceHighlightRating,
  (ratingsById, id, highlightValue) => highlightValue - get(ratingsById, id, 0),
)

export const getLifeWheel = createSelector(
  lifeWheelCategoriesSortedWithRatingsSelector,
  getIsEditMode,
  getMostRecentLifeWheelAssessmentRatings,
  getSliceHighlight,
  getNewRatings,
  (categories, isEditEnabled, lastRatingsById, { sliceIndex, arcIndex }, newRatings) =>
    !isEditEnabled ? categories : categories.map((category, i) => {
      const previousValue = lastRatingsById[category.id]
      const highlightValue = i === sliceIndex && arcIndex + 1
      const value = newRatings[category.id] || category.value
      return {
        ...category,
        previousValue,
        highlightValue,
        value,
      }
    }),
)

export const isSavePossible = createSelector(
  getIsEditMode,
  getNewRatings,
  (isEditEnabled, newRatings) => isEditEnabled && values(newRatings).length,
)

export const getChartData = createSelector(
  getMyLifeWheelAssessments,
  lifeWheelCategoriesSortedWithRatingsSelector,
  (assessments, wheelCategories) => {
    const offsets = {}
    return wheelCategories.map(
      ({ id, color }) => {
        const data = assessments.map(({ ratings }, i) => {
          const rating = ratings[id] || 0
          const offset = get(offsets, [i, rating], 0)
          set(offsets, [i, rating], offset + .1)
          const y = rating + offset
          return {
            x: i,
            y,
          }
        })

        return {
          color,
          data,
        }
      },
    )
  },
)

/**
 * Epics
 */

const navigateAndCreateLifeAssessmentEpic = action$ => action$.pipe(
  ofType(navigateAndCreateLifeAssessment().type),
  mergeMap(() => of(redirectRoute('/')).pipe(concat(
    of(toggleEditMode()).pipe(delay(200)),
  ))),
)

const blurArcEpic = action$ => action$.pipe(
  ofType(ARC_BLUR),
  switchMap(() => of(resetHighlight()).pipe(
    delay(500),
    takeUntil(action$.pipe(ofType(ARC_FOCUS))),
  )),
)

const rateWheelCategoryEpic = (action$, state$) => action$.pipe(
  ofType(WHEEL_CATEGORY_RATE),
  map(() => {
    const state = state$.value
    const categoryId = getHighlightedWheelCategoryId(state)
    if (!categoryId) return rateWheelCategoryRejected()
    const ratings = getNewRatings(state)
    const rating = getSliceHighlightRating(state)
    return setRatings({
      ...ratings,
      [categoryId]: rating,
    })
  }),
)

const saveRatingsEpic = (action$, state$) => action$.pipe(
  ofType(RATINGS_SAVE),
  switchMap(() => {
    const state = state$.value
    const id = getTodaysLifeWheelAssessmentId(state)
    const previousRatings = getMostRecentLifeWheelAssessmentRatings(state)
    const newRatings = getNewRatings(state)
    const ratings = {
      ...previousRatings,
      ...newRatings,
    }
    return of(saveRatingsPending()).pipe(concat(
      FirebaseApi
        .createOrUpdateWheelAssessment({ id, ratings })
        .pipe(
          map(saveRatingsFulfilled),
          catchError(err => of(saveRatingsRejected(err))),
        ),
    ))
  }),
)

const resetSelectedAssessmentIndexEpic = (action$, state$) => action$.pipe(
  ofType(SELECTED_ASSESSMENT_INDEX_RESET),
  map(() => {
    const numAssessments = getNumMyLifeWheelAssessments(state$.value)
    return changeSelectedAssessmentIndex(numAssessments - 1)
  }),
)

export const lifeWheelEpics = combineEpics(
  navigateAndCreateLifeAssessmentEpic,
  blurArcEpic,
  rateWheelCategoryEpic,
  saveRatingsEpic,
  resetSelectedAssessmentIndexEpic,
)
