import { values } from 'lodash'
import { createSelector } from 'reselect'

import { getGoalTemplateCategoriesById, getGoalTemplatesById } from '../content'

/**
 * Selectors
 */

const getGoalTemplateCategories = createSelector(
  getGoalTemplateCategoriesById,
  goalTemplateCategoriesById => values(goalTemplateCategoriesById),
)

export const getGoalTemplateCategoriesPopulated = createSelector(
  getGoalTemplateCategories,
  getGoalTemplatesById,
  (categories, goalTemplatesById) => categories.map(category => {
    const goalTemplates = (category.goalTemplates || [])
      .map(template => goalTemplatesById[template])
      .filter(template => template)
    return {
      ...category,
      goalTemplates,
    }
  }),
)
