import styled from 'styled-components'

export const MainTitle = styled.h2`
  text-align: center;
`

export const Features = styled.div`
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 1em 1em 1em;
`

export const Feature = styled.div`
  width: 33.333%;
  margin: 2em 0;
`

export const FeatureImage = styled.div`
  margin: 1.5em 0;
  img {
    width: 55%;
  }
`

export const FeatureTitle = styled.div`
  font-weight: 600;
  color: ${ ({ theme }) => theme.colorPrimary1 };
`

export const FeatureDescription = styled.div`
  font-size: .8em;
  line-height: 1.4em;
  padding: 0 1em;
`

export const Plans = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0 auto 1em auto;
  max-width: 800px;
`

export const Plan = styled.button`
  position: relative;
  flex: 1;
  margin: 1em 0;
  max-width: 240px;
  border-radius: 16px;
  padding: .5em 0 1em 0;
  transition: all .3s ease-out;
  will-change: transform;
  transform: translatez(0) scale(.9);
  color: #fff;
  background: rgba(0,0,0,0.05);
  cursor: pointer;
  border: none;
  ${ ({ isHighlighted }) => isHighlighted ? `
    transform: translatez(0) scale(1);
    color: #515151;
    background: #fff;
  ` : '' }
  &:hover {
    transform: translateY(-3px);
    color: #515151;
    background: #fff;
    box-shadow:
      0 4px 15px 0 rgba(50, 50, 95, .2),
      0 2px 12px 0 rgba(0, 0, 0, .12);
    ${ ({ isHighlighted }) => !isHighlighted ? `
      transform: translateY(-3px) scale(.9);
    ` : '' }
  }
  &:active {
    transition-duration: 5ms;
    transform: translateY(0);
    box-shadow:
      0 2px 8px 0 rgba(50, 50, 95, .1),
      0 1px 5px 0 rgba(0, 0, 0, .08);
    ${ ({ isHighlighted }) => !isHighlighted ? `
      transform: translateY(0) scale(.9);
    ` : '' }
  }
`

export const PlanDuration = styled.div`
  font-size: 4em;
  font-weight: 600;
  margin: .3em 0;
  small {
    display: block;
    font-size: 16px;
    text-transform: uppercase;
  }
`

export const PlanPrice = styled.div`
`

export const FinePrint = styled.p`
  max-width: 550px;
  font-size: .8em;
  margin: 0 auto;
  margin-bottom: 1em;
  color: #fff;
  line-height: 1.4em;
  a {
    color: #fff;
    text-decoration: underline;
  }
`

export const PlanHighlight = styled.div`
  position: absolute;
  top: 0;
  left: 20%;
  right: 20%;
  transform: translateY(-50%);
  background: ${ ({ theme }) => theme.colorPrimary2 };
  color: #fff;
  padding: .5em 1em;
  text-transform: uppercase;
  font-size: .8em;
  font-weight: 600;
  border-radius: 18px;
`

export const FeaturesHeader = styled.div`
  margin: 0 -70px;
  padding: 2em 0;
  text-align: center;
  h3 {
    margin: 0;
  }
`

export const BackgroundFill = styled.div`
  color: #fff;
  background: ${ ({ theme }) => theme.colorPrimary1 };
  box-shadow: 0 2px 15px -4px rgba(0,0,0,0.4);
  border-radius: 4px;
  text-align: center;
`

export const SlideDown = styled.div`
  opacity: 0;
  overflow: hidden;
  transition: all 450ms ease-out;
  transition-delay: 100ms;
  transform: scale(.9) translatey(5%) translatez(0);
  height: 0;
  will-change: transform;
  ${ ({ when }) => when && `
    opacity: 1;
    height: auto;
    transform: scale(1) translatey(0);
  ` }
`
