import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'

import { MinibarContainer as Minibar } from '../'

import LoadableHomeScene from '../HomeScene.loadable.js'
import LoadableAchieveScene from '../AchieveScene.loadable.js'
import RouteResources from '../RouteResources.js'
import LodableProfileScene from '../ProfileScene.loadable.js'
import LoadableSettingsScene from '../SettingsScene.loadable.js'
import LoadablePulseDashboard from '../pulse/LoadableDashboard.js'
import LoadablePulseDashboardDemo from '../pulse/LoadableDashboardDemo.js'

import {
  Wrap,
  Body,
  Main,
} from './styles'

class AppChromeAuthenticated extends Component {
  render() {
    const { isAuthenticated } = this.props

    if (!isAuthenticated) return (
      <Redirect push to={ { pathname: '/register' } } />
    )

    return (
      <Wrap>

        <Body>

          <Main>
            <Route exact path="/" component={ LoadableHomeScene } />
            <Route path="/achieve" component={ LoadableAchieveScene } />
            <Route path="/resources" component={ RouteResources } />
            <Route path="/profile" component={ LodableProfileScene } />
            <Route path="/settings" component={ LoadableSettingsScene } />
            <Route path="/pulse" component={ LoadablePulseDashboard } />
            <Route path="/pulsedemo" component={ LoadablePulseDashboardDemo } />
          </Main>

          <Minibar />

        </Body>
      </Wrap>
    )
  }
}

export { AppChromeAuthenticated }
