import styled from 'styled-components'

export const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: .5em;
`

export const Day = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  padding: 0;
  border-radius: 100px;
  background: #f4f4f6;
  position: relative;
  font-size: 12px;
  transform: scale(.8);
  opacity: .8;
  transition: all 150ms ease-out;
  border: 2px solid #fff;
  cursor: pointer;
  svg {
    position: absolute;
    fill: #f4f4f6 !important;
    transform: scale(.5);
  }
  ${ ({ isSelected, theme }) => isSelected ? `
    transform: scale(1);
    opacity: 1;
    color: #fff;
    background: ${ theme.colorPrimary1 };
    span {
      transform: translateY(-100%) scale(.8);
    }
    svg {
      fill: #fff !important;
      transform: translateY(30%);
    }
    &:hover {
      transform: scale(1.1);
    }
  ` : '' }
`

export const DayLabel = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-transform: uppercase;
  font-weight: 600;
  z-index: 2;
  transition: transform 250ms ease-out;
`
