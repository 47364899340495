const dndDisabledElements = [
  'input',
  'textarea',
  'select',
  'option',
  'button',
]

const dndShouldCancelStart = ({ target }) => {
  return checkTag(target, 10)
}

const checkTag = (tag, levels) => {
  const tagName = tag.tagName.toLowerCase()

  if (dndDisabledElements.indexOf(tagName) > -1)
    return true

  if (levels > 0 && tag.parentNode)
    return checkTag(tag.parentNode, --levels)
}

export { dndShouldCancelStart }
