import firebase from 'firebase/app'
import { combineReducers } from 'redux'
import { combineEpics, ofType } from 'redux-observable'
import { createAction, handleActions, combineActions } from 'redux-actions'
import { createSelector } from 'reselect'
import { of } from 'rxjs'
import { map, switchMap, catchError } from 'rxjs/operators'
import { get, union } from 'lodash'
import { FirebaseApi } from '../../api'

import {
  USER_AUTHENTICATED,
  signOut,
} from '../auth'

const PERMISSION_REQUEST           = 'remente/fcm/PERMISSION_REQUEST'
const PERMISSION_REQUEST_FULFILLED = 'remente/fcm/PERMISSION_REQUEST_FULFILLED'
const PERMISSION_REQUEST_REJECTED  = 'remente/fcm/PERMISSION_REQUEST_REJECTED'
const TOKEN_SET                    = 'remente/fcm/TOKEN_SET'
const TOKEN_ASSOCIATE_FULFILLED    = 'remente/fcm/TOKEN_ASSOCIATE_FULFILLED'
const TOKEN_ASSOCIATE_REJECTED     = 'remente/fcm/TOKEN_ASSOCIATE_REJECTED'
const TOKEN_DISASSOCIATE_FULFILLED = 'remente/fcm/TOKEN_DISASSOCIATE_FULFILLED'
const TOKEN_DISASSOCIATE_REJECTED  = 'remente/fcm/TOKEN_DISASSOCIATE_REJECTED'
const FCM_ENABLED_SET              = 'remente/fcm/FCM_ENABLED_SET'

export const requestFcmPermission   = createAction(PERMISSION_REQUEST)
const requestFcmPermissionFulfilled = createAction(PERMISSION_REQUEST_FULFILLED)
const requestFcmPermissionRejected  = createAction(PERMISSION_REQUEST_REJECTED)
const setToken                      = createAction(TOKEN_SET)
const associateTokenFulfilled       = createAction(TOKEN_ASSOCIATE_FULFILLED)
const associateTokenRejected        = createAction(TOKEN_ASSOCIATE_REJECTED)
const disassociateTokenFulfilled    = createAction(TOKEN_DISASSOCIATE_FULFILLED)
const disassociateTokenRejected     = createAction(TOKEN_DISASSOCIATE_REJECTED)
const setFcmEnabled                 = createAction(FCM_ENABLED_SET)

/**
 * Reducers
 */

const isFcmEnabledReducer = handleActions({
  [combineActions(setFcmEnabled, setToken)]:
    (state, { payload }) => !!payload,
}, false)

const tokenReducer = handleActions({
  [setToken]: (state, { payload }) => payload,
}, null)

const checkNotificationPermission = () => get(window, 'Notification.permission', null)

const notificationPermissionStateReducer = handleActions({
  [combineActions(
    requestFcmPermissionFulfilled,
    requestFcmPermissionRejected,
  )]: () => checkNotificationPermission(),
}, checkNotificationPermission())

export default combineReducers({
  isFcmEnabled: isFcmEnabledReducer,
  token: tokenReducer,
  notificationPermissionState: notificationPermissionStateReducer,
})

/**
 * Selectors
 */

const isFcmEnabledSelector = ({ fcm }) => fcm.isFcmEnabled
const tokenSelector = ({ fcm }) => fcm.token
const notificationPermissionStateSelector = ({ fcm }) => fcm.notificationPermissionState

export const getIsFcmEnabled = createSelector(isFcmEnabledSelector, value => value)
export const getFcmToken = createSelector(tokenSelector, value => value)
export const getNotificationPermissionState = createSelector(notificationPermissionStateSelector, value => value)

/**
 * Epics
 */

const requestFcmPermissionEpic = action$ => action$.pipe(
  ofType(requestFcmPermission().type),
  switchMap(() => FirebaseApi
    .fcmRequestPermission()
    .pipe(
      map(requestFcmPermissionFulfilled),
      catchError(err => of(requestFcmPermissionRejected(err))),
    ),
  ),
)

const getFcmTokenEpic = action$ => action$.pipe(
  ofType(
    USER_AUTHENTICATED,
    PERMISSION_REQUEST_FULFILLED,
    PERMISSION_REQUEST_REJECTED,
  ),
  switchMap(() => FirebaseApi
    .fcmGetToken()
    .pipe(
      map(token => token ? setToken(token) : setFcmEnabled(false)),
      catchError(() => of(setFcmEnabled(false))),
    ),
  ),
)

const associateFcmTokenEpic = action$ => action$.pipe(
  ofType(TOKEN_SET),
  map(({ payload }) => payload),
  switchMap(registrationId => FirebaseApi
    .fcmAssociateTokenWithUser({ registrationId })
    .pipe(
      map(associateTokenFulfilled),
      catchError(err => of(associateTokenRejected(err))),
    ),
  ),
)

const disassociateFcmTokenEpic = (action$, state$) => action$.pipe(
  ofType(signOut().type),
  switchMap(() => {
    const registrationId = getFcmToken(state$.value)
    if (!registrationId) return of(disassociateTokenFulfilled())
    return FirebaseApi
      .fcmDisassociateTokenWithUser({ registrationId })
      .pipe(
        map(disassociateTokenFulfilled),
        catchError(err => of(disassociateTokenRejected(err))),
      )
  }),
)

const developmentEpics = []

const productionEpics = [
  requestFcmPermissionEpic,
  getFcmTokenEpic,
  associateFcmTokenEpic,
  disassociateFcmTokenEpic,
]

const epics = (process.env.NODE_ENV === 'production' && firebase.messaging.isSupported()) ?
  union(developmentEpics, productionEpics) :
  developmentEpics

export const fcmEpics = combineEpics.apply(this, epics)
