import styled from 'styled-components'

const colorNegative = '#e7e7eb'

export const Wrap = styled.div`
  flex: 1;
  display: flex;
  position: relative;
  align-items: center;
`

export const TrackWrap = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  align-items: center;
  border-radius: 10px;
  border: 2px solid #f4f4f6;
  padding: 2px;
  margin: 0 10px;
`

export const Middle = styled.div`
  position: absolute;
  top: -8px;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  background: #515151;
  width: 5px;
  border: 2px solid #fff;
  border-radius: 4px;
`

export const Bar = styled.div`
  ${ ({ width, isLeft, rounded, neutral, theme, color }) => `
  width: ${ width * 100 }%;
  height: 8px;
  background: ${ color ? color : (isLeft && !neutral) ? colorNegative : theme.colorDiffPositive };
  border-radius: ${ rounded ? '5px' : isLeft ? '5px 0 0 5px' : '0 5px 5px 0' };
  transition: all 800ms ease-out;
  ${ rounded ? `
    margin-right: 4px;
    &:last-child {
      margin-right: 0;
    }
  `:'' }
  ` }
`

export const Track = styled.div`
  ${ ({ isLeft, title, full }) => `
    display: flex;
    width: ${ full ? 100 : 50 }%;
    align-items: center;
    min-height: 8px;
    ${ title ? `
      cursor: help;
    ` : '' }
    ${ isLeft ? `
      justify-content: flex-end;
    ` : '' }
  ` }
`

export const IconWrap = styled.div`
  font-size: .8em;
  color: #7e7e95;
  position: relative;
  ${ ({ hasIcon }) => hasIcon ? `
  top: 5px;
  ` : '' }
  ${ ({ positive }) => positive ? `
  top: -1px;
  ` : '' }
`
