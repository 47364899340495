import { combineReducers } from 'redux'
import { combineEpics, ofType } from 'redux-observable'
import { map } from 'rxjs/operators'
import { createAction, handleActions } from 'redux-actions'
import { createSelector } from 'reselect'
import moment from 'moment'
import isElectron from 'is-electron'

const INITAL_STATE = {
  language: 'en',
  currentModal: null,
  currentCover: null,
}

const APP_FOCUS       = 'remente/app/APP_FOCUS'
const APP_BLUR        = 'remente/app/APP_BLUR'
const LANGUAGE_SET    = 'remente/app/LANGUAGE_SET'
const LANGUAGE_CHANGE = 'remente/app/LANGUAGE_CHANGE'
const MODAL_SET       = 'remente/app/MODAL_SET'
const MODAL_CLOSE     = 'remente/app/MODAL_CLOSE'
const COVER_SET       = 'remente/app/COVER_SET'
const COVER_CLOSE     = 'remente/app/COVER_CLOSE'
const PAYWALL_SHOW    = 'remente/app/PAYWALL_SHOW'

export const focusApp        = createAction(APP_FOCUS)
export const blurApp         = createAction(APP_BLUR)
export const setLanguage     = createAction(LANGUAGE_SET)
export const setCurrentModal = createAction(MODAL_SET)
export const closeModal      = createAction(MODAL_CLOSE)
export const setCurrentCover = createAction(COVER_SET)
export const closeCover      = createAction(COVER_CLOSE)
export const changeLanguage  = createAction(LANGUAGE_CHANGE)
export const showPaywall     = createAction(PAYWALL_SHOW)

/**
 * Reducers
 */

const languageReducer = handleActions({
  [setLanguage]: (state, { payload }) => payload,
}, INITAL_STATE.language)

const currentModalReducer = handleActions({
  [setCurrentModal]: (state, { payload }) => payload.currentModal,
  [showPaywall]: () => 'paywall',
  [closeModal]: () => null,
  'remente/auth/USER_PROFILE_CHANGED': (state, { payload }) => {
    if (localStorage.authUserDidSignUp) {
      delete localStorage.authUserDidSignUp
      return 'welcome'
    }
    if (state !== 'paywall' || !payload) return state ? state : null
    if (payload.isPremium) return null
    return state
  },
}, INITAL_STATE.currentModal)

const currentCoverReducer = handleActions({
  [setCurrentCover]: (state, { payload }) => payload,
  [closeCover]: () => null,
}, INITAL_STATE.currentCover)

export default combineReducers({
  language: languageReducer,
  currentModal: currentModalReducer,
  currentCover: currentCoverReducer,
})

/**
 * Selectors
 */

const languageSelector = ({ app }) => app.language
export const getLanguage = createSelector(languageSelector, value => value)
export const getAppVersionCore = () => process.env.REACT_APP_version
export const getAppVersion = () => {
  if (!isElectron()) return 'Web'
  return window.require('electron').remote.app.getVersion()
}

/**
 * Epics
 */

const emitLanguageChangedEpic = action$ => action$.pipe(
  ofType(setLanguage().type),
  map(({ payload }) => payload),
  map(language => {
    moment.locale(language)
    return changeLanguage({ language })
  }),
)

export const appEpics = combineEpics(
  emitLanguageChangedEpic,
)
