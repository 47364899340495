import React, { Component } from 'react'

import {
  AspectRatio,
} from '../'

import {
  SvgContainer,
  InnerCircle,
} from './styles'

const getCoordinatesForPercent = percent => ([
  Math.cos(2 * Math.PI * percent),
  Math.sin(2 * Math.PI * percent),
])

class PieChart extends Component {
  render() {

    const {
      data,
      strokeColor = '#242527',
      strokeWidth = 1,
      innerFill = 'transparent',
      trackWidth = '20%',
      imageUrl,
    } = this.props

    let cumulativePercent = 0

    const Slices = data.map((slice, i) => {
      const [startX, startY] = getCoordinatesForPercent(cumulativePercent)
      const percent = slice.percent || 0
      cumulativePercent += percent
      const [endX, endY] = getCoordinatesForPercent(cumulativePercent)
      const largeArcFlag = percent > .5 ? 1 : 0
      const pathData = `
        M ${ startX } ${ startY }
        A 1 1 0 ${ largeArcFlag } 1 ${ endX } ${ endY }
        L 0 0
      `
      return (
        <path
          key={ i }
          d={ pathData }
          fill={ slice.color }
          transform="scale(.95)"
        />
      )
    })

    const Defs = imageUrl && (
      <defs>
        <pattern
          id={ imageUrl }
          x="0"
          y="0"
          width="1"
          height="1"
          patternContentUnits="objectBoundingBox"
        >
          <image
            width="1"
            height="1"
            xlinkHref={ imageUrl }
          />
        </pattern>
      </defs>
    )

    const outerStrokeColor = this.props.outerStrokeColor || strokeColor

    return (
      <AspectRatio>

        <InnerCircle
          fill={ innerFill }
          stroke={ strokeColor }
          image={ imageUrl }
          strokeWidth={ strokeWidth }
          radius={ trackWidth }
        />

        <SvgContainer
          viewBox="-1 -1 2 2"
        >

          <circle
            cx="0"
            cy="0"
            r="1"
            fill={ outerStrokeColor }
          />

          { Slices }
        </SvgContainer>

        <SvgContainer
          viewBox="-1 -1 2 2"
        >

          { Defs }

        </SvgContainer>

      </AspectRatio>
    )
  }
}

export { PieChart }
