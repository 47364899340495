import React, { Component } from 'react'
import IconButton from 'material-ui/IconButton'
import RaisedButton from 'material-ui/RaisedButton'
import FontIcon from 'material-ui/FontIcon'
import TextField from 'material-ui/TextField'

import config from '../config'

import {
  Fields,
  LoginProviders,
  Subtle,
  Buttons,
  Terms,
} from './AuthScene.styles.js'

class SignUp extends Component {

  state = {
    email: '',
    password: '',
    hasAcceptedTerms: false,
  }

  render() {
    const { authProviders } = config.firebase
    const { email, password, hasAcceptedTerms } = this.state

    return (
      <form onSubmit={ this.handleFormSubmit }>

        <Terms>
          <input type="checkbox" id="terms" checked={ hasAcceptedTerms } onChange={ this.handleToggleTerms } />
          <label htmlFor="terms">I accept the <a href="https://member.remente.com/terms-of-usage" target="_blank" rel="noopener noreferrer">Privacy Policy & Terms</a></label>
        </Terms>

        <Fields>
          <TextField
            hintText="E-mail"
            autoFocus
            type="email"
            value={ email }
            onChange={ this.handleInputChange('email').bind(this) }
          />
          <TextField
            hintText="Password"
            type="password"
            value={ password }
            onChange={ this.handleInputChange('password').bind(this) }
          />

        </Fields>

        <Buttons>
          <RaisedButton label="Create Account" primary={ true } type="submit" />
        </Buttons>

        <Subtle>Create account using:</Subtle>

        <LoginProviders>
          { authProviders.map(this.renderAuthProvider.bind(this)) }
        </LoginProviders>

      </form>
    )
  }

  renderAuthProvider(provider) {
    return (
      <li key={ provider.name }>
        <IconButton
          onClick={ () => this.handleAuthProviderClick(provider) }
          tooltip={ 'Create account using ' + provider.name }
        >
          <FontIcon color="#515151" className={ provider.icon } />
        </IconButton>
      </li>
    )
  }

  handleInputChange = prop => e => {
    this.setState({
      [prop]: e.target.value,
    })
  }

  handleToggleTerms = () => {
    const { hasAcceptedTerms } = this.state
    this.setState({
      ...this.state,
      hasAcceptedTerms: !hasAcceptedTerms,
    })
  }

  handleAlreadyMemberClick = e => {
    e.preventDefault()
    const { history } = this.props
    history.push('/login')
  }

  handleFormSubmit = e => {
    e.preventDefault()
    this.registerUser('EmailAuthProvider')
  }

  handleAuthProviderClick = ({ provider }) => this.registerUser(provider)

  registerUser = provider => {
    const { email, password, hasAcceptedTerms } = this.state

    if (!hasAcceptedTerms) return alert('Please read and accept the Privacy Policy & Terms.')

    const { registerUser } = this.props
    registerUser({ provider, email, password })
  }

}

export default SignUp

