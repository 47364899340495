import React, { Component } from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'

import { GoalTaskContainer as GoalTask } from '../'

import { dndShouldCancelStart } from '../../utils/dnd'

import { AddGoalTaskInputContainer as AddGoalTaskInput } from '../'

import {
  GoalPlanContainer,
  NoPlanPlaceholderText,
  AddGoalTaskInputWrap,
} from './styles'

const SortableGoalTask = SortableElement(task =>
  <GoalTask { ...task } />,
)

const SortableList = SortableContainer(({ children }) => (
  <div>
    { children }
  </div>),
)

class GoalPlan extends Component {

  state = {
    isDragging: false,
  }

  handleSortEnd(sortEvent) {
    const { onTaskOrderChange } = this.props
    const { oldIndex, newIndex } = sortEvent
    if (newIndex !== oldIndex)
      onTaskOrderChange({
        oldIndex,
        newIndex,
      })
    this.setState({ isDragging: false })
  }

  renderTask = ({ id, ...rest }, index) => {
    const { isGoalCompleted, isTaskDescriptionsVisible, isCompletedTasksVisible, onTaskClick, onTaskCheckboxClick } = this.props
    return (
      <SortableGoalTask
        key={ id }
        id={ id }
        index={ index }
        onClick={ () => onTaskClick(id) }
        onCheckboxClick={ () => onTaskCheckboxClick(id) }
        isGoalCompleted={ isGoalCompleted }
        isTaskDescriptionVisible={ isTaskDescriptionsVisible }
        disabled={ isGoalCompleted }
        { ...rest }
      />
    )
  }

  render() {
    const { isDragging } = this.state
    let { tasks, isGoalCompleted, isCompletedTasksVisible } = this.props

    const hasNoTasks = !tasks || tasks.length === 0

    const NoPlanPlaceholder = hasNoTasks && (
      <NoPlanPlaceholderText>
        Break down your goal into smaller, manageable steps.
      </NoPlanPlaceholderText>
    )

    const AddStepInput = !isGoalCompleted && (
      <AddGoalTaskInputWrap>
        <AddGoalTaskInput />
      </AddGoalTaskInputWrap>
    )

    if (!isCompletedTasksVisible)
      tasks = tasks.filter(({ completedAt }) => !completedAt)

    return (
      <GoalPlanContainer>

        { NoPlanPlaceholder }

        <SortableList
          helperClass="dnd-dragging"
          isDragging={ isDragging }
          lockAxis="y"
          lockToContainerEdges={ true }
          useDragHandle={ false }
          shouldCancelStart={ e => dndShouldCancelStart(e) || !isCompletedTasksVisible }
          pressDelay={ 200 }
          onSortStart={ () => this.setState({ isDragging: true }) }
          onSortEnd={ this.handleSortEnd.bind(this) }
        >
          { tasks.map(this.renderTask) }
        </SortableList>

        { AddStepInput }

      </GoalPlanContainer>
    )
  }

}

export { GoalPlan }
