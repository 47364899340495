import styled from 'styled-components'

const ChartDescription = styled.div`
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  z-index: 3;
  text-align: center;
  font-size: .8em;
  color: #aaa;
`

export { ChartDescription }

