import React, { Component } from 'react'

import {
  GoalListContainer as GoalList,
} from '../'

import { Wrap } from './styles.js'

class UserGoalNav extends Component {

  render() {
    const { goalLists } = this.props
    return (
      <Wrap>
        { goalLists.map(this.renderGoalList) }
      </Wrap>
    )
  }

  renderGoalList = ({ organizationId, goals }) => {
    return (
      <GoalList
        key={ organizationId || 'personalGoalList' }
        organizationId={ organizationId }
        goals={ goals }
      />
    )
  }

}

export { UserGoalNav }
