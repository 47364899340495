import { ofType } from 'redux-observable'
import { of, combineLatest } from 'rxjs'
import { map, switchMap, takeUntil, catchError } from 'rxjs/operators'
import { USER_AUTHENTICATED, USER_UNAUTHENTICATED } from '../../ducks/auth'

const createEnsureAuthenticatedEpic = ({
  createObservables,
  actionFulfilled,
  actionRejected,
}) => (action$, store) => action$.pipe(
  ofType(USER_AUTHENTICATED),
  map(({ payload }) => payload),
  switchMap(authPayload => {
    const observables = createObservables(authPayload, store)
    return combineLatest(Object.values(observables))
      .pipe(
        takeUntil(action$.pipe(ofType(USER_UNAUTHENTICATED))),
        map(res => {
          const keys = Object.keys(observables)
          const payload = {}
          keys.forEach((key, i) => payload[key] = res[i])
          return actionFulfilled(payload)
        }),
        catchError(err => of(actionRejected(err))),
      )
  }),
)

export { createEnsureAuthenticatedEpic }
