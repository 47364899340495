import styled from 'styled-components'

export const Overlay = styled.div`
  position: absolute;
  ${ ({ inset = 0 }) => `
    top: ${inset}px; right: ${inset}px;
    bottom: ${inset}px; left: ${inset}px;
  ` }
  overflow: hidden;
  background: rgba(0,0,0,.6);
  transition: background 150ms ease-out;
`

export const Wrap = styled.div`
  position: relative;
  width: 100%;

  @media only screen and (min-width: 800px) {
    width: calc(50% - 4px);
  }

  @media only screen and (min-width: 950px) {
    width: calc(33.3333% - 4px);
  }

  @media only screen and (min-width: 1190px) {
    width: calc(25% - 4px);
  }

  @media only screen and (min-width: 1500px) {
    width: calc(20% - 4px);
  }

  margin: 2px;
  background: #000;
  background-size: cover;
  background-position: center;
  background-image: url(${ ({ src }) => src });
  cursor: pointer;
  transition: all 150ms ease-out;
  -webkit-font-smoothing: subpixel-antialiased;
  transform: scale(1) translateZ(0px);
  will-change: transform;
  &:hover,
  &:focus {
    transform: scale(1.1);
    z-index: 3;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, .6);
    ${ Overlay } {
      background: rgba(0,0,0,.3);
    }
  }
`

export const Content = styled.h5`
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 3;
  font-size: .7em;
  font-weight: 600;
`

export const AspectRatio = styled.div`
  padding-bottom: 45%;
`
