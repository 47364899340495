import React from 'react'

import Markdown from '../../components/Markdown.loadable.js'

import {
  VideoEmbed,
  TextContent,
  ExerciseContainer as Exercise,
} from '../../components'

class ContentApi {

  static renderContentSegment(contentSegment, i) {
    const { content, contentId, type, contentType } = contentSegment
    if (!content) return <div key={ contentId || i } />

    switch(contentType || type) {

    case 'vimeo-public':
    case 'vimeo-private':
      return <VideoEmbed vimeo={ content } key={ contentId || i } />

    case 'youtube-public':
      return <VideoEmbed youtube={ content } key={ contentId || i } />

    case 'exercise':
      return <Exercise key={ contentId || i } id={ content } />

    case 'html': {
      return (
        <TextContent key={ contentId || i }>
          <Markdown>
            { content }
          </Markdown>
        </TextContent>
      )
    }

    default:
      return <div key={ contentId || i } />

    }
  }

  static renderContentSegments(contentSegments) {
    if (!contentSegments) return
    return contentSegments.map(this.renderContentSegment)
  }

}

export { ContentApi }
