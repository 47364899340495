import React, { Component } from 'react'
import { SortableElement } from 'react-sortable-hoc'
import { UserGoalNavItem } from '../'

class GoalListItem extends Component {
  render() {
    const { goal, isPlanMode, onAddToPlanClick } = this.props
    if (!goal) return null
    return (
      <UserGoalNavItem
        goal={ goal }
        isPlanMode={ isPlanMode }
        onAddToPlanClick={ onAddToPlanClick }
      />
    )
  }
}

const SortableGoalListItem = SortableElement(props => (
  <GoalListItem { ...props } />
))

export { SortableGoalListItem as GoalListItem }
