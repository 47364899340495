import React, { Component } from 'react'
import firstBy from 'thenby'
import ColorHash from 'color-hash'
import { PieChart } from '../'
import {
  Wrap,
  ProgressWrap,
  Initials,
} from './styles.js'

const saturation = [0.6]
const colorHashFg = new ColorHash({ lightness: 0.3, saturation })
const colorHashBg = new ColorHash({ lightness: 0.9, saturation })
const colorHashProgress = new ColorHash({ lightness: 0.5, saturation })

class AssignedTaskProgress extends Component {

  render() {
    const { userProgress } = this.props
    return (
      <Wrap>
        {
          userProgress
            .sort(firstBy('initials')
              .thenBy('displayName'))
            .map(this.renderUserProgress)
        }
      </Wrap>
    )
  }

  renderUserProgress = ({
    userId, initials, displayName, percentCompleted,
  }, i) => {
    const title = `${ displayName } - ${ Math.round(percentCompleted * 100) }%`

    const colorInitials = colorHashFg.hex(displayName)
    const colorBadge = colorHashBg.hex(displayName)
    const colorProgress = colorHashProgress.hex(displayName)

    const slices = [
      { percent: percentCompleted, color: colorProgress },
      { percent: 1 - percentCompleted, color: '#f4f4f6' },
    ]

    return (
      <ProgressWrap key={ userId } title={ title }>
        <Initials color={ colorInitials }>
          { initials }
        </Initials>
        <PieChart
          data={ slices }
          strokeColor={ '#fff' }
          innerFill={ colorBadge }
        />
      </ProgressWrap>
    )
  }

}

export { AssignedTaskProgress }
