import { connect } from 'react-redux'
import WelcomeModal from './WelcomeModal.js'
import { closeModal } from '../ducks/app'
import { getIsElectron } from '../ducks/electron'

const mapStateToProps = state => ({
  isElectron: getIsElectron(state),
})

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(closeModal()),
})

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeModal)
