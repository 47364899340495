import styled from 'styled-components'
import RaisedButton from 'material-ui/RaisedButton'

export const Wrap = styled.div`
  margin: 2em 0;
  border-radius: 8px;
  border: 4px solid #f4f4f6;
`

export const Header = styled.div`
  position: relative;
  padding: 1em;
  h4 {
    margin: 0;
    text-transform: uppercase;
    color: #e0e0e4;
  }
  h5 {
    margin-top: .5em;
    margin-bottom: 0;
    padding-top: 0;
    color: #4bd2ac;
  }
  border-bottom: 2px solid #f4f4f6;
`

export const Content = styled.div`
  padding: 1em;
`

export const PlanOutline = styled.div`
  margin: 1em 0;
`

export const AcceptButton = styled(RaisedButton)`
  position: absolute;
  right: 1em;
  top: 1em;
`
