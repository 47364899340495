import { connect } from 'react-redux'
import { GoalTemplateList } from './'
import { getGoalTemplateCategoriesPopulated } from '../../ducks/goalTemplate'
import {
  getTitleInputValue,
  getGoalTemplatesFilteredByGoalTitle,
} from '../../ducks/createGoal'
import { showModal } from '../../ducks/goalTemplateDetailsModal'

const mapStateToProps = state => ({
  categories: getGoalTemplateCategoriesPopulated(state),
  filterText: getTitleInputValue(state),
  filteredTemplates: getGoalTemplatesFilteredByGoalTitle(state),
})

const mapDispatchToProps = dispatch => ({
  onGoalTemplateClick: id => dispatch(showModal(id)),
})

class GoalTemplateListContainerClass extends GoalTemplateList {}

const GoalTemplateListContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(GoalTemplateListContainerClass)

export { GoalTemplateListContainer }
