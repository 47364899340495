import styled from 'styled-components'
import { createScrollbar } from '../../utils/css'

export const FilterWrap = styled.div`
  ${ createScrollbar({ theme: 'light' }) }
`

export const Filter = styled.div`
  border-top: 2px solid #f4f4f6;
  padding: .5em 16px;
  font-size: .8em;
  > * {
    margin: 0 auto;
    max-width: 200px;
  }
`
