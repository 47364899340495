import Loadable from 'react-loadable'
import LoadingIndicatorFull from './LoadingIndicatorFull.js'

const LoadableSettingsScene = Loadable({
  loader: () => import('./SettingsSceneContainer.js'),
  loading: LoadingIndicatorFull,
})

export default LoadableSettingsScene

