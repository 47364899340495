import React, { Component } from 'react'
import Popover from 'material-ui/Popover'
import IconOutline from 'material-ui/svg-icons/navigation/menu'
import IconClose from 'material-ui/svg-icons/navigation/close'

import CourseOutline from './CourseOutline.js'
import { FloatingCircleButton } from './'

import {
  Container,
  CourseOutlineWrap,
} from './CourseOutlinePopover.styles.js'

class CourseOutlinePopover extends Component {

  state = {
    showPopover: false,
  }

  openPopover = event => {
    event.preventDefault()
    this.setState({
      showPopover: true,
      anchorEl: event.currentTarget,
    })
  }

  closePopover = () => {
    this.setState({
      showPopover: false,
    })
  }

  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
  }

  anchorTarget = {
    vertical: 'top',
    horizontal: 'right',
  }

  render() {

    const iconStyle = {
      position: 'absolute',
      top: 0,
      right: 0,
      transform: 'translate3d(0,0,0)',
    }

    const { showPopover, anchorEl } = this.state
    const { loading } = this.props

    if (loading) return <div />

    return (
      <Container>
        <FloatingCircleButton style={ iconStyle } onClick={ this.openPopover }>
          { showPopover ? <IconClose /> : <IconOutline /> }
        </FloatingCircleButton>
        <Popover
          open={ showPopover }
          anchorEl={ anchorEl }
          onRequestClose={ this.closePopover }
          anchorOrigin={ this.anchorOrigin }
          targetOrigin={ this.anchorTarget }
          userLayerForClickAway={ false }
        >
          <CourseOutlineWrap>
            <CourseOutline replace { ...this.props } />
          </CourseOutlineWrap>
        </Popover>
      </Container>
    )
  }
}

export default CourseOutlinePopover
