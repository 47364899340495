import React, { Component } from 'react'
import styled from 'styled-components'
import { GoalTemplateCategory } from '../'

const Wrap = styled.div`
  text-align: left;
  margin-top: 3em;
  h4 {
    margin: 2.5em 0 1em 1.5em;
  }
`

class GoalTemplateList extends Component {
  render() {
    const { categories, filterText, filteredTemplates, onGoalTemplateClick } = this.props

    if (filteredTemplates.length === 0)
      return null

    const renderCategory = ({ id, ...rest }) => (
      <GoalTemplateCategory
        id={ id }
        key={ id }
        onGoalTemplateClick={ onGoalTemplateClick }
        { ...rest }
      />
    )

    const cats = filterText.length ? [{
      id: 0,
      title: `"${ filterText }"...`,
      goalTemplates: filteredTemplates,
    }] : categories

    return (
      <Wrap>
        { cats.map(renderCategory) }
      </Wrap>
    )
  }
}

export { GoalTemplateList }
