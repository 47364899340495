import { createAction } from 'redux-actions'
import { combineEpics, ofType } from 'redux-observable'
import { map, mergeMap } from 'rxjs/operators'
import { FirebaseApi } from '../../api'
import config from '../../config.js'
import { showPaywall } from '../app'
import { getIsAuthenticatedUserAllowedPremiumContent } from '../auth'

const ROOT_EXPORT_URL = config.CLOUD_FUNCTIONS_ROOT + 'exportUser'

const DATA_EXPORT = 'remente/dataExport/DATA_EXPORT'
export const exportData = createAction(DATA_EXPORT)

/**
 * Epics
 */

const dataExportEpic = (action$, state$) => action$.pipe(
  ofType(DATA_EXPORT),
  map(({ payload }) => payload),
  mergeMap(({ type, format }) => FirebaseApi
    .getIdTokenObservable()
    .pipe(map(idToken => {
      const isAuthenticatedUserAllowedPremiumContent = getIsAuthenticatedUserAllowedPremiumContent(state$.value)
      if (!isAuthenticatedUserAllowedPremiumContent) return showPaywall()
      const url = `${ ROOT_EXPORT_URL }${ type }?idToken=${ idToken }&format=${ format }`
      window.open(url, '_blank')
      return { type: 'NOOP' }
    })),
  ),
)

export const dataExportEpics = combineEpics(
  dataExportEpic,
)
